import {
  Box,
  Button,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
  useTheme,
} from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import useMediaQuery from '@mui/material/useMediaQuery'

import { logout } from '../actions/adminActions'
import { downloadPasses, getPasses } from '../actions/passActions'
import {
  DOWNLOAD_PASSES_RESET,
  GET_PASSES_RESET,
} from '../constants/passConstants'
import Header from '../components/Header'
import Table from '../components/Table'
import Loader from '../components/Loader'
import { VEHICLES } from '../constants'
import writeXlsxFile from 'write-excel-file'
import { tokens } from '../theme'

const Passes = () => {
  const isNonMobile = useMediaQuery('(min-width:600px)')
  const theme = useTheme()
  const colors = tokens(theme.palette.mode)

  const [data, setData] = useState([])
  const [amount, setAmount] = useState(0)
  const [total, setTotal] = useState(0)
  const [page, setPage] = useState(1)
  const [mobile, setMobile] = useState('')
  const [vehicleNumber, setVehicleNumber] = useState('')
  const [vehicleType, setVehicleType] = useState('')
  const [from, setFrom] = useState()
  const [to, setTo] = useState()
  const [isExpired, setIsExpired] = useState('')
  const [loading, setLoading] = useState(true)
  const [showFiltersModal, setShowFiltersModal] = useState(false)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const adminLogin = useSelector((state) => state.adminLogin)
  const { adminInfo } = adminLogin

  useEffect(() => {
    if (!adminInfo) {
      dispatch(logout(navigate))
    }
  }, [adminInfo])

  useEffect(() => {
    dispatch(
      getPasses(
        page,
        mobile,
        vehicleNumber,
        vehicleType,
        isExpired,
        from,
        to
      )
    )
    setLoading(true)
  }, [page])

  const getPassesInfo = useSelector((state) => state.getPassesInfo)
  const { loadingGetPasses, getPassesData, errorGetPasses } = getPassesInfo

  useEffect(() => {
    dispatch({ type: GET_PASSES_RESET })
    if (getPassesData) {
      setData(getPassesData.data)
      setAmount(getPassesData.amount)
      setTotal(getPassesData.total)
      // setPage(getPassesData.page)
      setLoading(false)
      setShowFiltersModal(false)
    } else if (errorGetPasses) {
      toast.error(errorGetPasses)
      setLoading(false)
    }
  }, [getPassesData, errorGetPasses])

  const columns = [
    {
      field: 'mobile',
      headerName: 'Mobile',
      valueGetter: ({ value }) => (value ? value : 'N/A'),
      minWidth: 200,
    },
    {
      field: 'location',
      headerName: 'Location',
      valueGetter: ({ value }) =>
        value ? `${value.name} - ${value.businessType}` : 'N/A',
      minWidth: 250,
    },
    {
      field: 'parking',
      headerName: 'Parking',
      valueGetter: ({ value }) => (value ? value.name : 'N/A'),
      minWidth: 250,
    },
    {
      field: 'createdBy',
      headerName: 'Created By',
      valueGetter: ({ value }) => (value ? value.username : 'N/A'),
      minWidth: 200,
    },
    {
      field: 'startTime',
      headerName: 'Start Time',
      valueGetter: ({ value }) =>
        value && moment(value).format('DD-MM-YYYY hh:mm A'),
      minWidth: 200,
    },
    {
      field: 'endTime',
      headerName: 'End Time',
      valueGetter: ({ value }) =>
        value && moment(value).format('DD-MM-YYYY hh:mm A'),
      minWidth: 200,
    },
    {
      field: 'vehicleNumber',
      headerName: 'Vehicle Number',
      valueGetter: ({ value }) => (value ? value : 'N/A'),
      minWidth: 200,
    },
    {
      field: 'vehicleType',
      headerName: 'Vehicle Type',
      valueGetter: ({ value }) => (value ? value : 'N/A'),
      minWidth: 200,
    },
    {
      field: 'amount',
      headerName: 'Amount',
      valueGetter: ({ value }) => (value ? value : 'N/A'),
      minWidth: 200,
    },
    {
      field: 'isExpired',
      headerName: 'Is Expired',
      valueGetter: ({ value }) => (value === true ? 'Yes' : 'No'),
      minWidth: 200,
    },
    {
      field: 'deletedBy',
      headerName: 'Deleted By',
      valueGetter: ({ value }) => (value ? value.username : 'N/A'),
      minWidth: 200,
    },
    {
      field: 'deletionTime',
      headerName: 'Deletion Time',
      valueGetter: ({ value }) =>
        value && moment(value).format('DD-MM-YYYY HH:mm:ss'),
      minWidth: 200,
    },
  ]

  const downloadReport = () => {
    dispatch(
      downloadPasses(
        mobile,
        vehicleNumber,
        vehicleType,
        isExpired,
        moment(from).toISOString(),
        moment(to).toISOString()
      )
    )
  }

  const downloadPassesInfo = useSelector((state) => state.downloadPassesInfo)
  const { loadingDownloadPasses, downloadPassesData, errorDownloadPasses } =
    downloadPassesInfo

  useEffect(() => {
    dispatch({ type: DOWNLOAD_PASSES_RESET })
    if (downloadPassesData) {
      toast.success('Passes downloaded successfully')
      downloadExcel(downloadPassesData)
    } else if (errorDownloadPasses) {
      toast.error(errorDownloadPasses)
    }
  }, [downloadPassesData, errorDownloadPasses])

  const downloadExcel = async (downloadData) => {
    await writeXlsxFile(downloadData, {
      fileName: 'Passes.xlsx',
    })
  }

  return (
    <Box m='20px'>
      <Box display='flex' justifyContent='space-between' alignItems='center'>
        <Header title='PASSES' subtitle='List of passes' />
        <Box>
        <Button
            type='submit'
            color='secondary'
            variant='contained'
            sx={{ fontSize: 14, marginRight: 4 }}
            disabled={loadingGetPasses || loadingDownloadPasses}
            onClick={()=>navigate('/passes/add')}
          >
            Add Pass
          </Button>
          <Button
            type='submit'
            color='secondary'
            variant='contained'
            sx={{ fontSize: 14, marginRight: 4 }}
            disabled={loadingGetPasses || loadingDownloadPasses}
            onClick={downloadReport}
          >
            Download
          </Button>
          <Button
            type='submit'
            color='warning'
            variant='contained'
            sx={{ fontSize: 14 }}
            disabled={loadingGetPasses || loadingDownloadPasses}
            onClick={() => setShowFiltersModal(true)}
          >
            Filters
          </Button>
        </Box>
      </Box>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Box
            display='flex'
            flexDirection='row'
            gap={2}
            sx={{ width: '100%' }}
          >
            <Box
              display='flex'
              flexDirection='column'
              justifyContent='space-between'
              alignItems='center'
              gap={1}
              marginBottom={-2}
              sx={{
                backgroundColor: colors.primary[400],
                padding: 2,
                borderRadius: 2,
                flex: 1,
              }}
            >
              <Box
                display='flex'
                flexDirection='row'
                justifyContent='space-between'
                alignItems='center'
                sx={{ width: '100%' }}
              >
                <Typography
                  id='keep-mounted-modal-title'
                  variant='h3'
                  component='h2'
                  sx={{
                    alignSelf: 'flex-start',
                    fontSize: 18,
                  }}
                >
                  Passes
                </Typography>
                <Typography
                  id='keep-mounted-modal-title'
                  variant='h3'
                  component='h2'
                  sx={{
                    alignSelf: 'flex-start',
                    fontSize: 18,
                  }}
                >
                  {total}
                </Typography>
              </Box>
            </Box>
            <Box
              display='flex'
              flexDirection='column'
              justifyContent='space-between'
              alignItems='center'
              gap={2}
              marginBottom={-2}
              sx={{
                backgroundColor: colors.primary[400],
                padding: 2,
                borderRadius: 2,
                flex: 1,
              }}
            >
              <Box
                display='flex'
                flexDirection='row'
                justifyContent='space-between'
                alignItems='center'
                sx={{ width: '100%' }}
              >
                <Typography
                  id='keep-mounted-modal-title'
                  variant='h3'
                  component='h2'
                  sx={{
                    alignSelf: 'flex-start',
                    fontSize: 18,
                  }}
                >
                  Amount
                </Typography>
                <Typography
                  id='keep-mounted-modal-title'
                  variant='h3'
                  component='h2'
                  sx={{
                    alignSelf: 'flex-start',
                    fontSize: 18,
                  }}
                >
                  Rs. {amount}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Table
            onPageChange={(newPage) => {
              setPage(newPage + 1)
            }}
            total={total}
            rows={data}
            columns={columns}
            page={page}
            getRowId={(row) => row._id}
          />
        </>
      )}
      {showFiltersModal && (
        <Modal
          keepMounted
          open
          onClose={() => {
            setShowFiltersModal(false)
          }}
          aria-labelledby='keep-mounted-modal-title'
          aria-describedby='keep-mounted-modal-description'
        >
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: isNonMobile ? 800 : 350,
              bgcolor: 'background.paper',
              border: '2px solid #000',
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography
              id='keep-mounted-modal-title'
              variant='h4'
              component='h2'
            >
              Filters
            </Typography>
            <div>
              <Box
                display='grid'
                gap='30px'
                gridTemplateColumns='repeat(4, minmax(0, 1fr))'
                sx={{
                  '& > div': {
                    gridColumn: isNonMobile ? undefined : 'span 4',
                  },
                }}
              >
                <Box
                  display='flex'
                  flexDirection={isNonMobile ? 'row' : 'column'}
                  gridColumn='span 4'
                  marginTop={2}
                  gap={isNonMobile ? 4 : 2}
                >
                  <Box
                    width='100%'
                    display='flex'
                    flexDirection='column'
                    gap={1}
                  >
                    <Typography variant='h6' component='h4'>
                      Mobile
                    </Typography>
                    <TextField
                      fullWidth
                      variant='filled'
                      type='number'
                      label='Mobile'
                      onChange={(e) => setMobile(e.target.value)}
                      value={mobile}
                      name='mobile'
                      disabled={loadingGetPasses}
                    />
                  </Box>
                  <Box
                    width='100%'
                    display='flex'
                    flexDirection='column'
                    gap={1}
                  >
                    <Typography variant='h6' component='h4'>
                      Vehicle Number
                    </Typography>
                    <TextField
                      fullWidth
                      variant='filled'
                      type='text'
                      label='Vehicle Number'
                      onChange={(e) => setVehicleNumber(e.target.value)}
                      value={vehicleNumber}
                      name='vehicleNumber'
                      disabled={loadingGetPasses}
                    />
                  </Box>
                </Box>
                <Box
                  display='flex'
                  flexDirection={isNonMobile ? 'row' : 'column'}
                  gridColumn='span 4'
                  marginTop={-2}
                  gap={isNonMobile ? 4 : 2}
                >
                  <Box
                    width='100%'
                    display='flex'
                    flexDirection='column'
                    gap={1}
                  >
                    <Typography variant='h6' component='h4'>
                      Vehicle Type
                    </Typography>
                    <Select
                      fullWidth
                      variant='filled'
                      labelId='demo-simple-select-label'
                      id='demo-simple-select'
                      value={vehicleType}
                      label='Vehicle Type'
                      onChange={(e) => setVehicleType(e.target.value)}
                      name='vehicleType'
                      disabled={loadingGetPasses}
                    >
                      {VEHICLES.map((el) => (
                        <MenuItem value={el.toUpperCase()}>
                          {el.toUpperCase()}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                  <Box
                    width='100%'
                    display='flex'
                    flexDirection='column'
                    gap={1}
                  >
                    <Typography variant='h6' component='h4'>
                      Is Expired
                    </Typography>
                    <Select
                      fullWidth
                      variant='filled'
                      labelId='demo-simple-select-label'
                      id='demo-simple-select'
                      value={isExpired}
                      label='Is Expired'
                      onChange={(e) => setIsExpired(e.target.value)}
                      name='isExpired'
                      disabled={loadingGetPasses}
                    >
                      <MenuItem value={true}>Yes</MenuItem>
                      <MenuItem value={false}>No</MenuItem>
                    </Select>
                  </Box>
                </Box>
                <Box
                  display='flex'
                  flexDirection={isNonMobile ? 'row' : 'column'}
                  gridColumn='span 4'
                  marginTop={-2}
                  gap={isNonMobile ? 4 : 2}
                >
                  <Box
                    width='100%'
                    display='flex'
                    flexDirection='column'
                    gap={1}
                  >
                    <Typography variant='h6' component='h4'>
                      From
                    </Typography>
                    <TextField
                      fullWidth
                      variant='filled'
                      type='datetime-local'
                      label='From'
                      onChange={(e) => setFrom(e.target.value)}
                      value={from}
                      name='from'
                      disabled={loadingGetPasses}
                    />
                  </Box>
                  <Box
                    width='100%'
                    display='flex'
                    flexDirection='column'
                    gap={1}
                  >
                    <Typography variant='h6' component='h4'>
                      To
                    </Typography>
                    <TextField
                      fullWidth
                      variant='filled'
                      type='datetime-local'
                      label='To'
                      onChange={(e) => setTo(e.target.value)}
                      value={to}
                      name='to'
                      disabled={loadingGetPasses}
                    />
                  </Box>
                </Box>
                <Button
                  type='submit'
                  color='secondary'
                  variant='contained'
                  sx={{ fontSize: 14 }}
                  onClick={() => {
                    setLoading(true)
                    dispatch(
                      getPasses(
                        page,
                        mobile,
                        vehicleNumber,
                        vehicleType,
                        isExpired,
                        moment(from).toISOString(),
                        moment(to).toISOString()
                      )
                    )
                  }}
                  disabled={loadingGetPasses}
                >
                  Apply
                </Button>
              </Box>
            </div>
          </Box>
        </Modal>
      )}
    </Box>
  )
}

export default Passes
