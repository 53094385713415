import {
  Box,
  Button,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import Header from "../components/Header";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useNavigate } from "react-router-dom";
import EventBus from "../lib/EventBus";
import { logout } from "../actions/adminActions";
import { globalEvents } from "../constants/index";
import {
  addCustomerParking,
  addCustomerVehicle,
  addEmployee,
  editCustomerParking,
  editCustomerDetails,
  // editCustomerVehical,
  searchCustomers,
  deleteCustomer,
  getCustomers,
} from "../actions/customerActions";
import {
  ADD_CUSTOMER_DETAILS_RESET,
  ADD_CUSTOMER_PARKING_RESET,
  ADD_CUSTOMER_VEHICLE_RESET,
  ADD_EMPLOYEE_RESET,
  // ADD_CUSTOMER_DETAILS_RESET,
  EDIT_CUSTOMER_PARKING_RESET,
  GET_CUSTOMERS_RESET,
  SEARCH_CUSTOMERS_RESET,
} from "../constants/customerConstants";
import { getParkingsForAdmins } from "../actions/parkingActions";
import { GET_PARKINGS_FOR_ADMINS_RESET } from "../constants/parkingConstants";
import Table from "../components/Table";
import Loader from "../components/Loader";

const Customers = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [searchText, setSearch] = useState("");
  const [customerToEdit, setCustomerToEdit] = useState(null);
  const [customerToEditModal, setCustomerToEditModal] = useState(false);
  const [customerVehiclesModal, setCustomerVehiclesModal] = useState(false);
  // const [customerDetailsModal, setCustomerDetailsModal] = useState(false)
  const [addVehicleModal, setAddVehicleModal] = useState(false);
  const [vehicleNumber, setVehicleNumber] = useState("");
  const [vehicleType, setVehicleType] = useState("");
  const [customerParkingsModal, setCustomerParkingsModal] = useState(false);
  const [allParkings, setAllParkings] = useState([]);
  const [customerAddParkingModal, setCustomerAddParkingModal] = useState(false);
  const [name, setName] = useState("");
  const [employeeCode, setEmployeeCode] = useState("");
  const [email, setEmail] = useState("");
  const [department, setDepartment] = useState("");
  const [position, setPosition] = useState("");
  const [parkingType, setParkingType] = useState("");
  const [parkingInvite, setParkingInvite] = useState(false);
  const [parking, setParking] = useState("");
  const [customerParkingEdit, setCustomerParkingEdit] = useState(null);
  const [customerVehicleEdit, setCustomerVehicleEdit] = useState(null);
  const [customerDetailsEdit, setCustomerDetailsEdit] = useState(null);
  const [vehicleRowId, setVehicleRowClicked] = useState(null);
  const [status, setStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [addEmployeeModal, setAddEmployeeModal] = useState(false);
  const [mobile, setMobile] = useState("");
  const [parkingSlot, setParkingSlot] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const adminLogin = useSelector((state) => state.adminLogin);
  const { adminInfo } = adminLogin;
  const refreshData = () => {
    setLoading(true);
    dispatch(getCustomers(page));
  };
  const updateLoading = () => {
    setLoading(false);
  };
  useEffect(() => {
    EventBus.getInstance().addListener(globalEvents.refreshData, refreshData);
    return () => {
      EventBus.getInstance().removeListener(logout);
    };
  }, []);

  useEffect(() => {
    EventBus.getInstance().addListener(
      globalEvents.updateLoading,
      updateLoading
    );
    return () => {
      EventBus.getInstance().removeListener(logout);
    };
  }, []);

  useEffect(() => {
    if (!adminInfo) {
      dispatch(logout(navigate));
    }
  }, [adminInfo]);

  useEffect(() => {
    if (!searchText) {
      dispatch(getCustomers(page));
      setLoading(true);
    }
  }, [page]);

  // const searchCustomersInfo = useSelector((state) => state.searchCustomersInfo);
  // // console.log(searchCustomersInfo);
  // const { loadingSearchCustomers, searchCustomersData, errorSearchCustomers } =
  //   searchCustomersInfo;

  const getCustomersInfo = useSelector((state) => state.getCustomersInfo);
  // console.log(getCustomersInfo);
  const { loadingGetCustomers, getCustomersData, errorGetCustomers } =
    getCustomersInfo;

  useEffect(() => {
    dispatch({ type: GET_CUSTOMERS_RESET });
    if (getCustomersData) {
      setData(getCustomersData.data);
      setTotal(getCustomersData.total);
      setPage(+getCustomersData.page);
      setLoading(false);
      // setSearchStatus(false);
    } else if (errorGetCustomers) {
      toast.error(errorGetCustomers);
      setLoading(false);
      // setSearchStatus(false);
    }
  }, [getCustomersData, errorGetCustomers]);

  // useEffect(() => {
  //   dispatch({ type: SEARCH_CUSTOMERS_RESET });
  //   if (searchCustomersData) {
  //     // setData(searchCustomersData.data);
  //     // setTotal(searchCustomersData.total);
  //     // setPage(searchCustomersData.page);
  //     setLoading(false);
  //     // setSearchStatus(false);

  //   } else if (errorSearchCustomers) {
  //     toast.error(errorSearchCustomers);
  //     setLoading(false);
  //     // setSearchStatus(false);
  //   }
  // }, [searchCustomersData, errorSearchCustomers]);

  useEffect(() => {
    dispatch(getParkingsForAdmins());
    setLoading(true);
  }, []);

  const getParkingsForAdminsInfo = useSelector(
    (state) => state.getParkingsForAdminsInfo
  );
  const {
    loadingGetParkingsForAdmins,
    getParkingsForAdminsData,
    errorGetParkingsForAdmins,
  } = getParkingsForAdminsInfo;

  useEffect(() => {
    dispatch({ type: GET_PARKINGS_FOR_ADMINS_RESET });
    if (getParkingsForAdminsData) {
      setAllParkings(getParkingsForAdminsData);
      setLoading(false);
    } else if (errorGetParkingsForAdmins) {
      toast.error(errorGetParkingsForAdmins);
      setLoading(false);
    }
  }, [getParkingsForAdminsData, errorGetParkingsForAdmins]);

  const columns = [
    {
      field: "name",
      headerName: "Name",
      valueGetter: ({ value }) => (value ? value : "N/A"),
      minWidth: 200,
    },
    {
      field: "mobile",
      headerName: "Mobile",
      valueGetter: ({ value }) => (value ? value : "N/A"),
      minWidth: 200,
    },
    {
      field: "vehicles",
      headerName: "Vehicles",
      valueGetter: ({ value }) => (value ? value.length : "0"),
      minWidth: 200,
    },
    {
      field: "locations",
      headerName: "Location",
      valueGetter: ({ value }) => (value ? value.length : "0"),
      minWidth: 200,
    },
    {
      field: "parkings",
      headerName: "Parkings",
      valueGetter: ({ value }) => (value ? value.length : "0"),
      minWidth: 200,
    },
    // { field: 'actions', headerName: 'Actions', width: 400, renderCell: (params) => {
    //   return (
    //     <Button
    //       onClick={(e) => console.log(e, params.row)}
    //       variant="contained"
    //     >
    //       Delete
    //     </Button>
    //   );
    // } }
  ];

  const vehicleColumns = [
    { field: "vehicleNumber", headerName: "Vehicle Number" },
    { field: "vehicleType", headerName: "Vehicle Type" },
  ];

  const addVehicleHandler = () => {
    if (!vehicleNumber || !vehicleType) {
      toast.error("All the fields are mandatory");
    } else {
      dispatch(
        addCustomerVehicle(customerToEdit._id, vehicleNumber, vehicleType)
      );
    }
  };

  const addCustomerVehicleInfo = useSelector(
    (state) => state.addCustomerVehicleInfo
  );
  const {
    loadingAddCustomerVehicle,
    addCustomerVehicleData,
    errorAddCustomerVehicle,
  } = addCustomerVehicleInfo;

  useEffect(() => {
    dispatch({ type: ADD_CUSTOMER_VEHICLE_RESET });
    if (addCustomerVehicleData) {
      toast.success("Vehicle added successfully");
      setCustomerToEdit(addCustomerVehicleData);
      setAddVehicleModal(false);
      setVehicleNumber("");
      setVehicleType("");
      dispatch(getCustomers(page));
    } else if (errorAddCustomerVehicle) {
      toast.error(errorAddCustomerVehicle);
    }
  }, [addCustomerVehicleData, errorAddCustomerVehicle]);

  const parkingColumns = [
    {
      field: "parking",
      headerName: "Parking",
      valueGetter: ({ value }) => (value ? value.name : ""),
    },
    { field: "employeeCode", headerName: "Employee Code" },
    { field: "name", headerName: "Name" },
    { field: "email", headerName: "Email" },
    { field: "position", headerName: "Position" },
    { field: "department", headerName: "Department" },
    { field: "parkingType", headerName: "Parking Type" },
    { field: "parkingSlot", headerName: "Parking Slot" },
    {
      field: "parkingInvite",
      headerName: "Parking Invite",
      valueGetter: ({ value }) => (value && value === true ? "Yes" : "No"),
    },
    {
      field: "status",
      headerName: "Status",
      valueGetter: ({ value }) => (value && value === true ? "Yes" : "No"),
    },
  ];
  // const addCustomerHandler = () => {
  //   if (mobile.length !== 10 || !name) {
  //     toast.error("Name and Mobile Number are mandatory");
  //   } else {
  //     dispatch(
  //       addEmployee(
  //         mobile,
  //         parking,
  //         name,
  //         employeeCode,
  //         email,
  //         department,
  //         position,
  //         parkingType,
  //         parkingInvite,
  //         parkingSlot
  //       )
  //     );
  //   }
  // };

  const addParkingHandler = () => {
    if (!parking || !parkingType || !name) {
      toast.error("Name, parking and parking type are mandatory");
    } else {
      dispatch(
        addCustomerParking(
          customerToEdit._id,
          parking,
          name,
          employeeCode,
          email,
          department,
          position,
          parkingType,
          parkingInvite,
          parkingSlot
        )
      );
    }
  };

  const addCustomerParkingInfo = useSelector(
    (state) => state.addCustomerParkingInfo
  );
  // console.log(addCustomerParkingInfo);
  const {
    loadingAddCustomerParking,
    addCustomerParkingData,
    errorAddCustomerParking,
  } = addCustomerParkingInfo;

  useEffect(() => {
    dispatch({ type: ADD_CUSTOMER_PARKING_RESET });
    if (addCustomerParkingData) {
      toast.success("Parking added successfully");
      setCustomerToEdit(addCustomerParkingData);
      setCustomerAddParkingModal(false);
      setName("");
      setEmail("");
      setParking("");
      setPosition("");
      setDepartment("");
      setEmployeeCode("");
      setParkingType("");
      setParkingInvite(false);
      setParkingSlot("");
      dispatch(getCustomers(page));
    } else if (errorAddCustomerParking) {
      toast.error(errorAddCustomerParking);
    }
  }, [addCustomerParkingData, errorAddCustomerParking]);

  // const addCustomerDetailsInfo = useSelector(
  //   (state) => state.addCustomerDetailsInfo
  // );
  // console.log(addCustomerDetailsInfo);

  // const {
  //   loadingAddCustomerDetails,
  //   addCustomerDetailsData,
  //   errorAddCustomerDetails,
  // } = addCustomerDetailsInfo;

  // useEffect(() => {
  //   // console.log("out" + customerDetailsEdit);
  //   dispatch({ type: ADD_CUSTOMER_DETAILS_RESET });
  //   if (addCustomerDetailsData) {
  //     toast.success("Details added successfully");
  //     setName(customerDetailsEdit.name);
  //     setMobile(customerDetailsEdit.mobile);
  //     dispatch(getCustomers(page));

  //   } else if (errorAddCustomerDetails) {
  //     toast.error("error");
  //   }

  // }, [addCustomerDetailsData, errorAddCustomerDetails]);

  useEffect(() => {
    if (customerVehicleEdit) {
      setVehicleNumber(customerVehicleEdit.vehicleNumber);
      setVehicleType(customerVehicleEdit.vehicleType);
    } else {
      setVehicleNumber("");
      setVehicleType("");
    }
  }, [customerVehicleEdit]);

  useEffect(() => {
    if (customerParkingEdit) {
      setName(customerParkingEdit.name);
      setEmail(customerParkingEdit.email);
      setParking(customerParkingEdit.parking._id);
      setPosition(customerParkingEdit.position);
      setDepartment(customerParkingEdit.department);
      setEmployeeCode(customerParkingEdit.employeeCode);
      setParkingType(customerParkingEdit.parkingType);
      setParkingInvite(customerParkingEdit.parkingInvite);
      setStatus(customerParkingEdit.status);
      setParkingSlot(customerParkingEdit.parkingSlot);
    } else {
      setName("");
      setEmail("");
      setParking("");
      setPosition("");
      setDepartment("");
      setEmployeeCode("");
      setParkingType("");
      setParkingInvite(false);
      setStatus(false);
      setParkingSlot("");
    }
  }, [customerParkingEdit]);

  const updateCustomerHandler = () => {
    const payload = {
      name: name ? name : customerToEdit.name,
      mobile: mobile ? JSON.stringify(mobile) : customerToEdit.mobile,
      vehicles: customerToEdit.vehicles,
      id: customerToEdit._id,
    };

    console.log(mobile.length);
    console.log(name);
    console.log(mobile);
    if (!name || !mobile) {
      toast.error("Name and Mobile are mandatory");
    }
    // else if (mobile.length != 10) {
    //   toast.error("Enter Only 10 digit Mobile Number");
    // }
    else {
      // console.log(customerToEdit);
      dispatch(editCustomerDetails(payload));
      setLoading(true);
    }
  };

  const deleteCustomerHandler = () => {
    // console.log(customerToEdit);
    dispatch(deleteCustomer(customerToEdit._id));
    setLoading(true);
  };

  const updateVehicleHandler = () => {
    if (!vehicleNumber || !vehicleType) {
      toast.error("Vehicle and Vehicle type are mandatory");
    } else {
      console.log(customerVehicleEdit, vehicleRowId);
      let vehiclesToEdit = [...customerToEdit.vehicles];
      const index = vehiclesToEdit.findIndex(
        (item, _idx) => vehicleRowId === item.vehicleNumber
      );
      console.log(vehiclesToEdit, index);
      vehiclesToEdit[index] = {
        vehicleNumber: vehicleNumber
          ? vehicleNumber
          : vehiclesToEdit[index].vehicleNumber,
        vehicleType: vehicleType
          ? vehicleType
          : vehiclesToEdit[index].vehicleType,
      };
      const payload = {
        name: customerToEdit.name,
        mobile: JSON.stringify(customerToEdit.mobile),
        vehicles: vehiclesToEdit,
        id: customerToEdit._id,
      };
      dispatch(editCustomerDetails(payload));
    }
  };
  const updateParkingHandler = () => {
    if (!parking || !parkingType || !name) {
      toast.error("Name, parking and parking type are mandatory");
    } else {
      dispatch(
        editCustomerParking(
          customerToEdit._id,
          parking,
          name,
          employeeCode,
          email,
          department,
          position,
          parkingType,
          parkingInvite,
          parkingSlot,
          status
        )
      );
    }
  };

  const editCustomerParkingInfo = useSelector(
    (state) => state.editCustomerParkingInfo
  );
  const {
    loadingEditCustomerParking,
    editCustomerParkingData,
    errorEditCustomerParking,
  } = editCustomerParkingInfo;

  useEffect(() => {
    dispatch({ type: EDIT_CUSTOMER_PARKING_RESET });
    if (editCustomerParkingData) {
      toast.success("Parking updated successfully");
      setCustomerToEdit(editCustomerParkingData);
      setCustomerParkingEdit(null);
      setName("");
      setEmail("");
      setParking("");
      setPosition("");
      setDepartment("");
      setEmployeeCode("");
      setParkingType("");
      setParkingInvite(false);
      setStatus(false);
      setParkingSlot("");
      dispatch(getCustomers(page));
    } else if (errorEditCustomerParking) {
      toast.error(errorEditCustomerParking);
    }
  }, [editCustomerParkingData, errorEditCustomerParking]);

  const addEmployeeHandler = () => {
    if (
      mobile.length !== 10 ||
      !parking ||
      !parkingType ||
      !name ||
      !employeeCode
    ) {
      toast.error(
        "Mobile, Name, employee code, parking and parking type are mandatory"
      );
    } else {
      dispatch(
        addEmployee(
          mobile,
          parking,
          name,
          employeeCode,
          email,
          department,
          position,
          parkingType,
          parkingInvite,
          parkingSlot
        )
      );
    }
  };

  const addEmployeeInfo = useSelector((state) => state.addEmployeeInfo);
  const { loadingAddEmployee, addEmployeeData, errorAddEmployee } =
    addEmployeeInfo;

  useEffect(() => {
    dispatch({ type: ADD_EMPLOYEE_RESET });
    if (addEmployeeData) {
      toast.success(addEmployeeData.msg);
      setAddEmployeeModal(false);
      setMobile("");
      setName("");
      setEmail("");
      setParking("");
      setPosition("");
      setDepartment("");
      setEmployeeCode("");
      setParkingType("");
      setParkingInvite(false);
      setParkingSlot("");
      dispatch(getCustomers(page));
    } else if (errorAddEmployee) {
      toast.error(errorAddEmployee);
    }
  }, [addEmployeeData, errorAddEmployee]);

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="CUSTOMERS" subtitle="List of customers" />
        <div
          style={{
            display: "flex",
            justifyItems: "center",
          }}
        >
          <TextField
            variant="outlined"
            type="text"
            label="Search here mobile"
            color="secondary"
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            name="search mobile"
          />

          <Button
            type="submit"
            color="secondary"
            variant="contained"
            sx={{
              fontSize: 14,
            }}
            disabled={null}
            onClick={() => {
              dispatch(searchCustomers(searchText));
              setLoading(true);
            }}
          >
            Search
          </Button>
        </div>
        <Button
          type="submit"
          color="warning"
          variant="contained"
          sx={{
            fontSize: 14,
          }}
          disabled={null}
          onClick={() => setAddEmployeeModal(true)}
        >
          Add Employee
        </Button>
      </Box>
      {loading ? (
        <Loader />
      ) : (
        <Table
          onPageChange={(newPage) => {
            setPage(newPage + 1);
          }}
          total={total}
          rows={data}
          columns={columns}
          page={page}
          getRowId={(row) => row._id}
          onRowClick={(row) => {
            setCustomerToEdit(row.row);
            setName(row.row?.name);
            setMobile(row.row?.mobile);
            setCustomerToEditModal(true);
            setCustomerDetailsEdit(row.row);
          }}
        />
      )}
      {customerDetailsEdit && customerToEditModal && (
        <Modal
          keepMounted
          open
          onClose={() => {
            setCustomerToEditModal(false);
            setCustomerToEdit(null);
            setCustomerDetailsEdit(null);
            setName("");
            setMobile("");
          }}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography
              id="keep-mounted-modal-title"
              variant="h6"
              component="h2"
            >
              Customer Details
            </Typography>
            <div>
              <Box
                display="grid"
                gap="30px"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                sx={{
                  "& > div": {
                    gridColumn: isNonMobile ? undefined : "span 4",
                  },
                }}
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  gridColumn="span 4"
                  gap={1}
                  marginTop={2}
                >
                  <Typography variant="p" component="p">
                    Name
                  </Typography>
                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Name"
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                    name="name"
                    sx={{ gridColumn: "span 4" }}
                    disabled={loadingAddEmployee}
                  />
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  gridColumn="span 4"
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant="p" component="p">
                    Mobile
                  </Typography>
                  <TextField
                    fullWidth
                    variant="filled"
                    type="number"
                    onInput={(e) => {
                      e.target.value = Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, 10);
                    }}
                    label="Mobile"
                    onChange={(e) => setMobile(+e.target.value)}
                    value={mobile}
                    name="mobile"
                    sx={{ gridColumn: "span 4" }}
                    disabled={loadingAddEmployee}
                  />
                </Box>
              </Box>
              <Box
                mt="20px"
                display="flex"
                justifyContent="space-between"
                sx={{ height: 42 }}
              >
                <Button
                  type="submit"
                  color="secondary"
                  variant="contained"
                  sx={{ fontSize: 14, marginRight: 2 }}
                  onClick={() => {
                    setCustomerVehiclesModal(true);
                    setCustomerToEditModal(false);
                    setCustomerDetailsEdit(false);
                  }}
                >
                  Vehicles
                </Button>
                <Button
                  type="submit"
                  color="warning"
                  variant="contained"
                  sx={{ fontSize: 14, marginRight: 2 }}
                  onClick={() => {
                    setCustomerToEditModal(null);
                    if (customerDetailsEdit) {
                      updateCustomerHandler();
                    }
                  }}
                >
                  Submit
                </Button>

                <Button
                  type="submit"
                  color="warning"
                  variant="contained"
                  sx={{ fontSize: 14, marginRight: 2 }}
                  onClick={() => {
                    setCustomerToEditModal(null);
                    if (customerDetailsEdit) {
                      deleteCustomerHandler();
                    }
                  }}
                  disable={null}
                >
                  Delete
                </Button>
                <Button
                  type="submit"
                  color="secondary"
                  variant="contained"
                  sx={{ fontSize: 14 }}
                  onClick={() => {
                    setCustomerParkingsModal(true);
                    setCustomerToEditModal(false);
                    setCustomerDetailsEdit(false);
                  }}
                >
                  Parkings
                </Button>
              </Box>
            </div>
          </Box>
        </Modal>
      )}
      {customerVehiclesModal && (
        <Modal
          keepMounted
          open
          onClose={() => {
            setCustomerVehiclesModal(false);
            setCustomerToEdit(null);
          }}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                id="keep-mounted-modal-title"
                variant="h6"
                component="h2"
              >
                Customer Vehicles
              </Typography>
              <Button
                type="submit"
                color="warning"
                variant="contained"
                sx={{ fontSize: 14 }}
                disabled={null}
                onClick={() => setAddVehicleModal(true)}
              >
                Add Vehicle
              </Button>
            </Box>
            <Table
              total={customerToEdit?.vehicles.length}
              rows={customerToEdit?.vehicles}
              page={1}
              columns={vehicleColumns}
              getRowId={(row) => row.vehicleNumber}
              onRowClick={(row, _idx) => {
                console.log(row, _idx);
                setVehicleRowClicked(row.id);
                setCustomerVehicleEdit(row.row);
                // console.log(customerToEdit?.vehicles);
              }}
            />
          </Box>
        </Modal>
      )}
      {(addVehicleModal || customerVehicleEdit) && (
        <Modal
          keepMounted
          open
          onClose={() => {
            setAddVehicleModal(false);
            setCustomerVehicleEdit(null);
            setVehicleNumber("");
            setVehicleType("");
          }}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography
              id="keep-mounted-modal-title"
              variant="h6"
              component="h2"
            >
              {addVehicleModal ? "Add Vehicle" : "Edit Vehicle"}
            </Typography>
            <div>
              <Box
                display="grid"
                gap="30px"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                sx={{
                  "& > div": {
                    gridColumn: isNonMobile ? undefined : "span 4",
                  },
                }}
              >
                {(addVehicleModal || customerVehicleEdit) && (
                  <Box
                    display="flex"
                    flexDirection="column"
                    gridColumn="span 4"
                    gap={1}
                    marginTop={2}
                  >
                    <Typography variant="p" component="p">
                      Vehicle Number
                    </Typography>
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Vehicle Number"
                      onChange={(e) => setVehicleNumber(e.target.value)}
                      value={vehicleNumber}
                      name="vehicle number"
                      sx={{ gridColumn: "span 4" }}
                      disabled={loadingAddCustomerVehicle}
                    />
                  </Box>
                )}
                <Box
                  display="flex"
                  flexDirection="column"
                  gridColumn="span 4"
                  gap={1}
                  marginTop={2}
                >
                  <Typography variant="p" component="p">
                    Vehicle Type
                  </Typography>
                  <Select
                    variant="filled"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={vehicleType}
                    label="Vehicle Type"
                    onChange={(e) => setVehicleType(e.target.value)}
                    sx={{ gridColumn: "span 4" }}
                    disabled={loadingAddCustomerVehicle}
                  >
                    <MenuItem value="car">Car</MenuItem>
                    <MenuItem value="bike">Bike</MenuItem>
                    <MenuItem value="tenant car">Tenant Car</MenuItem>
                    <MenuItem value="tenant bike">Tenant Bike</MenuItem>
                  </Select>
                </Box>
              </Box>
              <Box
                mt="20px"
                display="flex"
                justifyContent="space-between"
                sx={{ height: 42 }}
              >
                <Button
                  type="submit"
                  color="secondary"
                  variant="contained"
                  sx={{ fontSize: 14 }}
                  onClick={() => {
                    if (customerVehicleEdit) {
                      updateVehicleHandler();
                      setCustomerVehicleEdit(null);
                      setCustomerVehiclesModal(false);
                    } else {
                      addVehicleHandler();
                    }
                  }}
                  disabled={
                    loadingAddCustomerVehicle || loadingEditCustomerParking
                  }
                >
                  Submit
                </Button>
              </Box>
            </div>
          </Box>
        </Modal>
      )}
      {customerParkingsModal && (
        <Modal
          keepMounted
          open
          onClose={() => {
            setCustomerParkingsModal(false);
            setCustomerToEdit(null);
          }}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 800,
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                id="keep-mounted-modal-title"
                variant="h6"
                component="h2"
              >
                Customer Parkings
              </Typography>
              <Button
                type="submit"
                color="warning"
                variant="contained"
                sx={{ fontSize: 14 }}
                disabled={null}
                onClick={() => setCustomerAddParkingModal(true)}
              >
                Add Parking
              </Button>
            </Box>
            <Table
              rows={customerToEdit?.parkings}
              columns={parkingColumns}
              getRowId={(row) => row.parking}
              onRowClick={(row) => {
                setCustomerParkingEdit(row.row);
              }}
            />
          </Box>
        </Modal>
      )}
      {(customerAddParkingModal || customerParkingEdit || addEmployeeModal) && (
        <Modal
          keepMounted
          open
          onClose={() => {
            setCustomerAddParkingModal(false);
            setCustomerParkingEdit(null);
            setAddEmployeeModal(false);
            setMobile("");
            setName("");
            setEmail("");
            setParking("");
            setPosition("");
            setDepartment("");
            setEmployeeCode("");
            setParkingType("");
            setParkingInvite(false);
            setStatus(false);
            setParkingSlot("");
          }}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: isNonMobile ? 800 : 350,
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography
              id="keep-mounted-modal-title"
              variant="h6"
              component="h2"
            >
              {customerAddParkingModal
                ? "Add Parking"
                : customerParkingEdit
                ? `${customerParkingEdit.parking.name}`
                : "Add Employee"}
            </Typography>
            <div>
              <Box
                display="grid"
                gap="30px"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                sx={{
                  "& > div": {
                    gridColumn: isNonMobile ? undefined : "span 4",
                  },
                }}
              >
                {addEmployeeModal && (
                  <Box
                    display="flex"
                    flexDirection="column"
                    gridColumn="span 4"
                    gap={1}
                    marginTop={2}
                  >
                    <Typography variant="p" component="p">
                      Mobile
                    </Typography>
                    <TextField
                      fullWidth
                      variant="filled"
                      type="number"
                      onInput={(e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 10);
                      }}
                      label="Mobile"
                      onChange={(e) => setMobile(e.target.value)}
                      value={mobile}
                      name="mobile"
                      sx={{ gridColumn: "span 4" }}
                      disabled={loadingAddEmployee}
                    />
                  </Box>
                )}
                <Box
                  display="flex"
                  flexDirection={isNonMobile ? "row" : "column"}
                  gridColumn="span 4"
                  gap={isNonMobile ? 4 : 2}
                  marginTop={addEmployeeModal ? -2 : 2}
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    gap={1}
                    marginTop={0}
                    flex={1}
                  >
                    <Typography variant="p" component="p">
                      Name
                    </Typography>
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Name"
                      onChange={(e) => setName(e.target.value)}
                      value={name}
                      name="name"
                      disabled={
                        loadingAddCustomerParking ||
                        loadingEditCustomerParking ||
                        loadingAddEmployee
                      }
                    />
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="column"
                    gap={1}
                    marginTop={0}
                    flex={1}
                  >
                    <Typography variant="p" component="p">
                      Employee Code
                    </Typography>
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Employee Code"
                      onChange={(e) => setEmployeeCode(e.target.value)}
                      value={employeeCode}
                      name="employee code"
                      disabled={
                        loadingAddCustomerParking ||
                        loadingEditCustomerParking ||
                        loadingAddEmployee
                      }
                    />
                  </Box>
                </Box>
                <Box
                  display="flex"
                  flexDirection={isNonMobile ? "row" : "column"}
                  gridColumn="span 4"
                  gap={isNonMobile ? 4 : 2}
                  marginTop={-2}
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    gap={1}
                    marginTop={0}
                    flex={1}
                  >
                    <Typography variant="p" component="p">
                      Email
                    </Typography>
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Email"
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                      name="email"
                      disabled={
                        loadingAddCustomerParking ||
                        loadingEditCustomerParking ||
                        loadingAddEmployee
                      }
                    />
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="column"
                    gap={1}
                    marginTop={0}
                    flex={1}
                  >
                    <Typography variant="p" component="p">
                      Department
                    </Typography>
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Department"
                      onChange={(e) => setDepartment(e.target.value)}
                      value={department}
                      name="department"
                      disabled={
                        loadingAddCustomerParking ||
                        loadingEditCustomerParking ||
                        loadingAddEmployee
                      }
                    />
                  </Box>
                </Box>
                <Box
                  display="flex"
                  flexDirection={isNonMobile ? "row" : "column"}
                  gridColumn="span 4"
                  gap={isNonMobile ? 4 : 2}
                  marginTop={-2}
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    gap={1}
                    marginTop={0}
                    flex={1}
                  >
                    <Typography variant="p" component="p">
                      Position
                    </Typography>
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Position"
                      onChange={(e) => setPosition(e.target.value)}
                      value={position}
                      name="position"
                      disabled={
                        loadingAddCustomerParking ||
                        loadingEditCustomerParking ||
                        loadingAddEmployee
                      }
                    />
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="column"
                    gap={1}
                    marginTop={0}
                    flex={1}
                  >
                    <Typography variant="p" component="p">
                      Parking Type
                    </Typography>
                    <Select
                      fullWidth
                      variant="filled"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={parkingType}
                      label="Parking Type"
                      onChange={(e) => setParkingType(e.target.value)}
                      disabled={
                        loadingAddCustomerParking ||
                        loadingEditCustomerParking ||
                        loadingAddEmployee
                      }
                    >
                      <MenuItem value="elite">Elite</MenuItem>
                      <MenuItem value="general">General</MenuItem>
                    </Select>
                  </Box>
                </Box>
                <Box
                  display="flex"
                  flexDirection={isNonMobile ? "row" : "column"}
                  gridColumn="span 4"
                  gap={isNonMobile ? 4 : 2}
                  marginTop={-2}
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    gap={1}
                    marginTop={0}
                    flex={1}
                  >
                    <Typography variant="p" component="p">
                      Parking Invite
                    </Typography>
                    <Select
                      fullWidth
                      variant="filled"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={parkingInvite}
                      label="Parking Invite"
                      onChange={(e) => setParkingInvite(e.target.value)}
                      disabled={
                        loadingAddCustomerParking ||
                        loadingEditCustomerParking ||
                        loadingAddEmployee
                      }
                    >
                      <MenuItem value={true}>Yes</MenuItem>
                      <MenuItem value={false}>No</MenuItem>
                    </Select>
                  </Box>
                  {(customerAddParkingModal || addEmployeeModal) && (
                    <Box
                      display="flex"
                      flexDirection="column"
                      gap={1}
                      marginTop={0}
                      flex={1}
                    >
                      <Typography variant="p" component="p">
                        Parking
                      </Typography>
                      <Select
                        fullWidth
                        variant="filled"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={parking}
                        label="Parking"
                        onChange={(e) => setParking(e.target.value)}
                        disabled={
                          loadingAddCustomerParking || loadingAddEmployee
                        }
                      >
                        {allParkings.map((item) => (
                          <MenuItem key={item._id} value={item._id}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </Box>
                  )}
                  {customerParkingEdit && (
                    <Box
                      display="flex"
                      flexDirection="column"
                      gap={1}
                      marginTop={0}
                      flex={1}
                    >
                      <Typography variant="p" component="p">
                        Status
                      </Typography>
                      <Select
                        fullWidth
                        variant="filled"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={status}
                        label="Status"
                        onChange={(e) => setStatus(e.target.value)}
                        disabled={loadingEditCustomerParking}
                      >
                        <MenuItem value={true}>Yes</MenuItem>
                        <MenuItem value={false}>No</MenuItem>
                      </Select>
                    </Box>
                  )}
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  gridColumn="span 4"
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant="p" component="p">
                    Parking Slot
                  </Typography>
                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Parking Slot"
                    onChange={(e) => setParkingSlot(e.target.value)}
                    value={parkingSlot}
                    name="parkingSlot"
                    disabled={
                      loadingAddCustomerParking ||
                      loadingEditCustomerParking ||
                      loadingAddEmployee
                    }
                  />
                </Box>
              </Box>
              <Box
                mt="20px"
                display="flex"
                justifyContent="space-between"
                sx={{ height: 42 }}
              >
                <Button
                  type="submit"
                  color="secondary"
                  variant="contained"
                  sx={{ fontSize: 14 }}
                  onClick={
                    customerParkingEdit
                      ? updateParkingHandler
                      : customerAddParkingModal
                      ? addParkingHandler
                      : addEmployeeHandler
                  }
                  disabled={
                    loadingAddCustomerParking ||
                    loadingEditCustomerParking ||
                    loadingAddEmployee
                  }
                >
                  Submit
                </Button>
              </Box>
            </div>
          </Box>
        </Modal>
      )}
    </Box>
  );
};

export default Customers;
