import axios from 'axios'
import {
  ADD_PARKING_REQUEST,
  ADD_PARKING_RESET,
  ADD_PARKING_SUCCESS,
  GET_PARKINGS_FAIL,
  GET_PARKINGS_FOR_ADMINS_FAIL,
  GET_PARKINGS_FOR_ADMINS_REQUEST,
  GET_PARKINGS_FOR_ADMINS_SUCCESS,
  GET_PARKINGS_REQUEST,
  GET_PARKINGS_SUCCESS,
  GET_PARKING_BY_ID_FAIL,
  GET_PARKING_BY_ID_REQUEST,
  GET_PARKING_BY_ID_SUCCESS,
  UPDATE_PARKING_REQUEST,
  UPDATE_PARKING_RESET,
  UPDATE_PARKING_SUCCESS,
} from '../constants/parkingConstants'

export const getParkings = (page, name) => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_PARKINGS_REQUEST,
    })

    const {
      adminLogin: { adminInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${adminInfo.token}`,
      },
    }

    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/parkings?page=${page}&name=${name}`,
      config
    )

    dispatch({
      type: GET_PARKINGS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: GET_PARKINGS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getParkingsForAdmins = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_PARKINGS_FOR_ADMINS_REQUEST,
    })

    const {
      adminLogin: { adminInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${adminInfo.token}`,
      },
    }

    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/parkings/admins`,
      config
    )

    dispatch({
      type: GET_PARKINGS_FOR_ADMINS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: GET_PARKINGS_FOR_ADMINS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getParkingById = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_PARKING_BY_ID_REQUEST,
    })

    const {
      adminLogin: { adminInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${adminInfo.token}`,
      },
    }

    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/parkings?id=${id}`,
      config
    )

    dispatch({
      type: GET_PARKING_BY_ID_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: GET_PARKING_BY_ID_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const updateParking =
  (
    id,
    name,
    foc,
    whiteListing,
    visitorManagement,
    inventoryEliteTotal,
    inventoryEliteFreeTime,
    inventoryGeneralTotal,
    inventoryGeneralFreeTime,
    vehicles,
    receiptDetails,
    attendantAppSettings,
    rates,
    pass,
    nightCharge,
    prepaid,
    reportAdjustment,
    resetCheckins,
    holidayMode,
    ocr,
    onlinePayments,
    advance
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: UPDATE_PARKING_REQUEST,
      })

      const {
        adminLogin: { adminInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${adminInfo.token}`,
        },
      }

      const { data } = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/parkings/${id}`,
        {
          name,
          foc,
          whiteListing,
          visitorManagement,
          inventoryEliteTotal,
          inventoryEliteFreeTime,
          inventoryGeneralTotal,
          inventoryGeneralFreeTime,
          vehicles,
          receiptDetails,
          attendantAppSettings,
          rates,
          pass,
          nightCharge,
          prepaid,
          reportAdjustment,
          resetCheckins,
          holidayMode,
          ocr,
          onlinePayments,
          advance,
        },
        config
      )

      dispatch({
        type: UPDATE_PARKING_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: UPDATE_PARKING_RESET,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const addParking =
  (
    name,
    location,
    foc,
    whiteListing,
    visitorManagement,
    inventoryEliteTotal,
    inventoryEliteFreeTime,
    inventoryGeneralTotal,
    inventoryGeneralFreeTime,
    vehicles,
    receiptDetails,
    attendantAppSettings,
    rates,
    pass,
    nightCharge,
    prepaid,
    reportAdjustment,
    resetCheckins,
    holidayMode,
    ocr,
    onlinePayments,
    advance
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: ADD_PARKING_REQUEST,
      })

      const {
        adminLogin: { adminInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${adminInfo.token}`,
        },
      }

      const { data } = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/parkings`,
        {
          name,
          location,
          foc,
          whiteListing,
          visitorManagement,
          inventoryEliteTotal,
          inventoryEliteFreeTime,
          inventoryGeneralTotal,
          inventoryGeneralFreeTime,
          vehicles,
          receiptDetails,
          attendantAppSettings,
          rates,
          pass,
          nightCharge,
          prepaid,
          reportAdjustment,
          resetCheckins,
          holidayMode,
          ocr,
          onlinePayments,
          advance,
        },
        config
      )

      dispatch({
        type: ADD_PARKING_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: ADD_PARKING_RESET,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }
