export const GET_PASSES_REQUEST = 'GET_PASSES_REQUEST'
export const GET_PASSES_SUCCESS = 'GET_PASSES_SUCCESS'
export const GET_PASSES_FAIL = 'GET_PASSES_FAIL'
export const GET_PASSES_RESET = 'GET_PASSES_RESET'
export const CREATE_PASS_REQUEST = 'CREATE_PASS_REQUEST'
export const CREATE_PASS_SUCCESS = 'CREATE_PASS_SUCCESS'
export const CREATE_PASS_FAIL = 'CREATE_PASS_FAIL'
export const CREATE_PASS_RESET = 'CREATE_PASS_RESET'

export const DOWNLOAD_PASSES_REQUEST = 'DOWNLOAD_PASSES_REQUEST'
export const DOWNLOAD_PASSES_SUCCESS = 'DOWNLOAD_PASSES_SUCCESS'
export const DOWNLOAD_PASSES_FAIL = 'DOWNLOAD_PASSES_FAIL'
export const DOWNLOAD_PASSES_RESET = 'DOWNLOAD_PASSES_RESET'
