import {
  ADD_CUSTOMER_PARKING_FAIL,
  ADD_CUSTOMER_PARKING_REQUEST,
  ADD_CUSTOMER_PARKING_RESET,
  ADD_CUSTOMER_PARKING_SUCCESS,
  ADD_CUSTOMER_VEHICLE_FAIL,
  ADD_CUSTOMER_VEHICLE_REQUEST,
  ADD_CUSTOMER_VEHICLE_RESET,
  ADD_CUSTOMER_VEHICLE_SUCCESS,
  ADD_EMPLOYEE_FAIL,
  ADD_EMPLOYEE_REQUEST,
  ADD_EMPLOYEE_RESET,
  ADD_EMPLOYEE_SUCCESS,
  EDIT_CUSTOMER_PARKING_FAIL,
  EDIT_CUSTOMER_PARKING_REQUEST,
  EDIT_CUSTOMER_PARKING_RESET,
  EDIT_CUSTOMER_PARKING_SUCCESS,
  GET_CUSTOMERS_FAIL,
  GET_CUSTOMERS_REQUEST,
  GET_CUSTOMERS_RESET,
  GET_CUSTOMERS_SUCCESS,
  SEARCH_CUSTOMERS_FAIL,
  SEARCH_CUSTOMERS_REQUEST,
  SEARCH_CUSTOMERS_RESET,
  SEARCH_CUSTOMERS_SUCCESS,
  ADD_CUSTOMER_DETAILS_FAIL,
  ADD_CUSTOMER_DETAILS_REQUEST,
  ADD_CUSTOMER_DETAILS_RESET,
  ADD_CUSTOMER_DETAILS_SUCCESS
} from '../constants/customerConstants'

export const getCustomersReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_CUSTOMERS_REQUEST:
      return { loadingGetCustomers: true }
    case GET_CUSTOMERS_SUCCESS:
      return { loadingGetCustomers: false, getCustomersData: action.payload }
    case GET_CUSTOMERS_FAIL:
      return { loadingGetCustomers: false, errorGetCustomers: action.payload }
    case GET_CUSTOMERS_RESET:
      return {}
    default:
      return state
  }
}

export const searchCustomersReducer = (state = {}, action) => {
  switch (action.type) {
    case SEARCH_CUSTOMERS_REQUEST:
      return { loadingSearchCustomers: true }
    case SEARCH_CUSTOMERS_SUCCESS:
      return { loadingSearchCustomers: false, searchCustomersData: action.payload }
    case SEARCH_CUSTOMERS_FAIL:
      return { loadingSearchCustomers: false, errorSearchCustomers: action.payload }
    case SEARCH_CUSTOMERS_RESET:
      return {}
    default:
      return state
  }
}

export const addCustomerVehicleReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_CUSTOMER_VEHICLE_REQUEST:
      return { loadingAddCustomerVehicle: true }
    case ADD_CUSTOMER_VEHICLE_SUCCESS:
      return {
        loadingAddCustomerVehicle: false,
        addCustomerVehicleData: action.payload,
      }
    case ADD_CUSTOMER_VEHICLE_FAIL:
      return {
        loadingAddCustomerVehicle: false,
        errorAddCustomerVehicle: action.payload,
      }
    case ADD_CUSTOMER_VEHICLE_RESET:
      return {}
    default:
      return state
  }
}


export const addCustomerDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_CUSTOMER_DETAILS_REQUEST:
      return { loadingAddCustomerDetails: true }
    case ADD_CUSTOMER_DETAILS_SUCCESS:
      return {
        loadingAddCustomerDetails: false,
        addCustomerDetailsData: action.payload,
      }
    case ADD_CUSTOMER_DETAILS_FAIL:
      return {
        loadingAddCustomerDetails: false,
        errorAddCustomerDetails: action.payload,
      }
    case ADD_CUSTOMER_DETAILS_RESET:
      return {}
    default:
      return state
  }
}

export const addCustomerParkingReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_CUSTOMER_PARKING_REQUEST:
      return { loadingAddCustomerParking: true }
    case ADD_CUSTOMER_PARKING_SUCCESS:
      return {
        loadingAddCustomerParking: false,
        addCustomerParkingData: action.payload,
      }
    case ADD_CUSTOMER_PARKING_FAIL:
      return {
        loadingAddCustomerParking: false,
        errorAddCustomerParking: action.payload,
      }
    case ADD_CUSTOMER_PARKING_RESET:
      return {}
    default:
      return state
  }
}

export const editCustomerParkingReducer = (state = {}, action) => {
  switch (action.type) {
    case EDIT_CUSTOMER_PARKING_REQUEST:
      return { loadingEditCustomerParking: true }
    case EDIT_CUSTOMER_PARKING_SUCCESS:
      return {
        loadingEditCustomerParking: false,
        editCustomerParkingData: action.payload,
      }
    case EDIT_CUSTOMER_PARKING_FAIL:
      return {
        loadingEditCustomerParking: false,
        errorEditCustomerParking: action.payload,
      }
    case EDIT_CUSTOMER_PARKING_RESET:
      return {}
    default:
      return state
  }
}

export const addEmployeeReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_EMPLOYEE_REQUEST:
      return { loadingAddEmployee: true }
    case ADD_EMPLOYEE_SUCCESS:
      return {
        loadingAddEmployee: false,
        addEmployeeData: action.payload,
      }
    case ADD_EMPLOYEE_FAIL:
      return {
        loadingAddEmployee: false,
        errorAddEmployee: action.payload,
      }
    case ADD_EMPLOYEE_RESET:
      return {}
    default:
      return state
  }
}
