import {
  ADD_USER_FAIL,
  ADD_USER_REQUEST,
  ADD_USER_RESET,
  ADD_USER_SUCCESS,
  EDIT_USER_FAIL,
  EDIT_USER_REQUEST,
  EDIT_USER_RESET,
  EDIT_USER_SUCCESS,
  GENERATE_PASSWORD_USER_FAIL,
  GENERATE_PASSWORD_USER_REQUEST,
  GENERATE_PASSWORD_USER_RESET,
  GENERATE_PASSWORD_USER_SUCCESS,
  GET_USERS_FAIL,
  GET_USERS_REQUEST,
  GET_USERS_RESET,
  GET_USERS_SUCCESS,
  RESET_DEVICE_ID_USER_FAIL,
  RESET_DEVICE_ID_USER_REQUEST,
  RESET_DEVICE_ID_USER_RESET,
  RESET_DEVICE_ID_USER_SUCCESS,
} from '../constants/userConstants'

export const getUsersReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_USERS_REQUEST:
      return { loadingGetUsers: true }
    case GET_USERS_SUCCESS:
      return { loadingGetUsers: false, getUsersData: action.payload }
    case GET_USERS_FAIL:
      return { loadingGetUsers: false, errorGetUsers: action.payload }
    case GET_USERS_RESET:
      return {}
    default:
      return state
  }
}

export const editUserReducer = (state = {}, action) => {
  switch (action.type) {
    case EDIT_USER_REQUEST:
      return { loadingEditUser: true }
    case EDIT_USER_SUCCESS:
      return { loadingEditUser: false, editUserData: action.payload }
    case EDIT_USER_FAIL:
      return { loadingEditUser: false, errorEditUser: action.payload }
    case EDIT_USER_RESET:
      return {}
    default:
      return state
  }
}

export const generatePasswordUserReducer = (state = {}, action) => {
  switch (action.type) {
    case GENERATE_PASSWORD_USER_REQUEST:
      return { loadingGeneratePasswordUser: true }
    case GENERATE_PASSWORD_USER_SUCCESS:
      return {
        loadingGeneratePasswordUser: false,
        generatePasswordUserData: action.payload,
      }
    case GENERATE_PASSWORD_USER_FAIL:
      return {
        loadingGeneratePasswordUser: false,
        errorGeneratePasswordUser: action.payload,
      }
    case GENERATE_PASSWORD_USER_RESET:
      return {}
    default:
      return state
  }
}

export const resetDeviceIdUserReducer = (state = {}, action) => {
  switch (action.type) {
    case RESET_DEVICE_ID_USER_REQUEST:
      return { loadingResetDeviceIdUser: true }
    case RESET_DEVICE_ID_USER_SUCCESS:
      return {
        loadingResetDeviceIdUser: false,
        resetDeviceIdUserData: action.payload,
      }
    case RESET_DEVICE_ID_USER_FAIL:
      return {
        loadingResetDeviceIdUser: false,
        errorResetDeviceIdUser: action.payload,
      }
    case RESET_DEVICE_ID_USER_RESET:
      return {}
    default:
      return state
  }
}

export const addUserReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_USER_REQUEST:
      return { loadingAddUser: true }
    case ADD_USER_SUCCESS:
      return {
        loadingAddUser: false,
        addUserData: action.payload,
      }
    case ADD_USER_FAIL:
      return {
        loadingAddUser: false,
        errorAddUser: action.payload,
      }
    case ADD_USER_RESET:
      return {}
    default:
      return state
  }
}
