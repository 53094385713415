export const GET_CHECKINS_REQUEST = 'GET_CHECKINS_REQUEST'
export const GET_CHECKINS_SUCCESS = 'GET_CHECKINS_SUCCESS'
export const GET_CHECKINS_FAIL = 'GET_CHECKINS_FAIL'
export const GET_CHECKINS_RESET = 'GET_CHECKINS_RESET'

export const FOC_CHECKIN_REQUEST = 'FOC_CHECKIN_REQUEST'
export const FOC_CHECKIN_SUCCESS = 'FOC_CHECKIN_SUCCESS'
export const FOC_CHECKIN_FAIL = 'FOC_CHECKIN_FAIL'
export const FOC_CHECKIN_RESET = 'FOC_CHECKIN_RESET'

export const DELETE_CHECKIN_REQUEST = 'DELETE_CHECKIN_REQUEST'
export const DELETE_CHECKIN_SUCCESS = 'DELETE_CHECKIN_SUCCESS'
export const DELETE_CHECKIN_FAIL = 'DELETE_CHECKIN_FAIL'
export const DELETE_CHECKIN_RESET = 'DELETE_CHECKIN_RESET'

export const DOWNLOAD_CHECKINS_REQUEST = 'DOWNLOAD_CHECKINS_REQUEST'
export const DOWNLOAD_CHECKINS_SUCCESS = 'DOWNLOAD_CHECKINS_SUCCESS'
export const DOWNLOAD_CHECKINS_FAIL = 'DOWNLOAD_CHECKINS_FAIL'
export const DOWNLOAD_CHECKINS_RESET = 'DOWNLOAD_CHECKINS_RESET'

export const DELETE_CHECKINS_REQUEST = 'DELETE_CHECKINS_REQUEST'
export const DELETE_CHECKINS_SUCCESS = 'DELETE_CHECKINS_SUCCESS'
export const DELETE_CHECKINS_FAIL = 'DELETE_CHECKINS_FAIL'
export const DELETE_CHECKINS_RESET = 'DELETE_CHECKINS_RESET'
