import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import useMediaQuery from "@mui/material/useMediaQuery";
import { GET_LOCATIONS_FOR_ADMINS_RESET } from "../constants/locationConstants";
import { getLocationsForAdmins } from "../actions/locationActions";

import { logout } from "../actions/adminActions";
import { useNavigate, useParams } from "react-router-dom";
import {
  addLocation,
  getLocationById,
  updateLocation,
} from "../actions/locationActions";
import { createPass } from "../actions/passActions";
import {
  ADD_LOCATION_RESET,
  GET_LOCATION_BY_ID_RESET,
  UPDATE_LOCATION_RESET,
} from "../constants/locationConstants";
import {CREATE_PASS_RESET} from '../constants/passConstants'
import { VEHICLES } from "../constants";
import Header from "../components/Header";
import Table from "../components/Table";
import Loader from "../components/Loader";
import { getRatesForAdmins } from "../actions/rateActions";
import { GET_RATES_FOR_ADMINS_RESET } from "../constants/rateConstants";
import moment from "moment";

const PassesById = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");

  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [location, setLocation] = useState("");
  const [allLocations, setAllLocations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [parking, setParking] = useState(true);
  const [vehicleNumber, setVehicleNumber] = useState("");
  const [amount, setAmount] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startDate, setStartDate] = useState("");
  const [vehicleType, setVehicleType] = useState("");
  const [mobile, setMobile] = useState("");

  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [contractStartedOn, setContractStartedOn] = useState(new Date());
  const [contractEndedOn, setContractEndedOn] = useState(new Date());
  const [modules, setModules] = useState({
    whiteListing: false,
    visitorManagement: false,
    delivery: false,
    normalCheckin: false,
    pass: false,
  });
  const [inventoryCompanies, setInventoryCompanies] = useState(0);
  const [inventoryGeneral, setInventoryGeneral] = useState(0);
  const [parkings, setParkings] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [gst, setGst] = useState("");
  const [addVehicleModal, setAddVehicleModal] = useState(false);
  const [addVehicleValue, setAddVehicleValue] = useState("");
  const [businessType, setBusinessType] = useState("");
  const [rateToDisplay, setRateToDisplay] = useState("");
  const [note, setNote] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [showOnApp, setShowOnApp] = useState(true);
  const [rate, setRate] = useState(null);
  const [allRates, setAllRates] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const adminLogin = useSelector((state) => state.adminLogin);
  const { adminInfo } = adminLogin;

  useEffect(() => {
    if (!adminInfo) {
      dispatch(logout(navigate));
    }
  }, [adminInfo]);

  useEffect(() => {
    if (params?.id && params.id !== "add") {
      dispatch(getLocationById(params.id));
    }
  }, [params?.id]);
  useEffect(() => {
    if (location) {
      dispatch(getLocationById(location));
    }
    // console.log(location);
  }, [location]);

  const getLocationByIdInfo = useSelector((state) => state.getLocationByIdInfo);
  const { loadingGetLocationById, getLocationByIdData, errorGetLocationById } =
    getLocationByIdInfo;

  useEffect(() => {
    dispatch({ type: GET_LOCATION_BY_ID_RESET });
    if (getLocationByIdData) {
      const location = getLocationByIdData.data[0];

      setName(location?.name);
      setAddress(location?.address);
      setLatitude(location?.latitude);
      setLongitude(location?.longitude);
      setContractStartedOn(location?.contractStartedOn);
      setContractEndedOn(location?.contractEndedOn);
      setModules(location?.modules);
      setInventoryCompanies(location?.inventory?.companies.total);
      setInventoryGeneral(location?.inventory?.general.total);
      setParkings(location?.parkings);
      setBusinessType(location?.businessType);
      setDisplayName(location?.userAppDetails?.displayName);
      setRateToDisplay(location?.userAppDetails?.rateToDisplay);
      setNote(location?.userAppDetails?.note);
      setShowOnApp(location?.userAppDetails?.showOnApp);
      setRate(location?.rate);
      setVehicles(location?.vehicles.map((el) => el.toUpperCase()));
      setGst(location?.gst);
    } else if (errorGetLocationById) {
      toast.error(errorGetLocationById);
      navigate("/vendors");
    }
  }, [getLocationByIdData, errorGetLocationById]);

  const columnsParking = [
    {
      field: "name",
      headerName: "Name",
      width: 250,
    },
    businessType === "Unorganised" && {
      field: "receiptDetails",
      headerName: "Name on receipt",
      valueGetter: ({ value }) => value && value.name,
      width: 250,
    },
    businessType === "Unorganised" && {
      field: "vehicles",
      headerName: "Vehicles",
      valueGetter: ({ value }) => value && value.length,
      width: 200,
    },
    businessType === "Unorganised" && {
      field: "pass",
      headerName: "Pass",
      valueGetter: ({ value }) =>
        value && value.status === true ? "Yes" : "No",
      width: 200,
    },
    businessType === "Unorganised" && {
      field: "ocr",
      headerName: "OCR",
      valueGetter: ({ value }) => (value && value === true ? "Yes" : "No"),
      width: 200,
    },
  ];

  const columnsVehicles = [
    {
      field: "name",
      headerName: "Name",
    },
  ];

  const updateLocationHandler = () => {
    if (
      !params.id ||
      !name ||
      !address ||
      !latitude ||
      !longitude ||
      !contractStartedOn ||
      !contractEndedOn ||
      !modules ||
      !businessType ||
      !displayName ||
      !rateToDisplay
    ) {
      toast.error("All the fields are mandatory");
      return;
    }

    if (businessType === "Corporate") {
      if (
        !inventoryCompanies ||
        !inventoryGeneral ||
        !rate ||
        vehicles.length === 0
      ) {
        toast.error("All the fields are mandatory");
        return;
      }
    }

    dispatch(
      updateLocation(
        params.id,
        name,
        address,
        latitude,
        longitude,
        contractStartedOn,
        contractEndedOn,
        gst,
        modules,
        vehicles,
        inventoryCompanies,
        inventoryGeneral,
        businessType,
        displayName,
        rateToDisplay,
        note,
        showOnApp,
        rate
      )
    );
  };

  const updateLocationInfo = useSelector((state) => state.updateLocationInfo);
  const { loadingUpdateLocation, updateLocationData, errorUpdateLocation } =
    updateLocationInfo;

  useEffect(() => {
    dispatch({ type: UPDATE_LOCATION_RESET });
    if (updateLocationData) {
      toast.success("Vendor updated successfully");
      setName(updateLocationData?.name);
      setAddress(updateLocationData?.address);
      setLatitude(updateLocationData?.latitude);
      setLongitude(updateLocationData?.longitude);
      setContractStartedOn(updateLocationData?.contractStartedOn);
      setContractEndedOn(updateLocationData?.contractEndedOn);
      setModules(updateLocationData?.modules);
      setInventoryCompanies(updateLocationData?.inventory?.companies.total);
      setInventoryGeneral(updateLocationData?.inventory?.general.total);
      setParkings(updateLocationData?.parkings);
      setBusinessType(updateLocationData?.businessType);
      setDisplayName(updateLocationData?.userAppDetails?.displayName);
      setRateToDisplay(updateLocationData?.userAppDetails?.rateToDisplay);
      setNote(updateLocationData?.userAppDetails?.note);
      setShowOnApp(updateLocationData?.userAppDetails?.showOnApp);
      setRate(updateLocationData?.rate);
      setVehicles(updateLocationData?.vehicles.map((el) => el.toUpperCase()));

      // setVehicles(
      //   updateLocationData?.vehicles?.map((el) => {
      //     return { name: el }
      //   })
      // )
      setGst(updateLocationData?.gst);
    } else if (errorUpdateLocation) {
      toast.error(errorUpdateLocation);
    }
  }, [updateLocationData, errorUpdateLocation]);


  const addLocationHandler = () => {
    if (
      !parking ||
      !vehicleNumber ||
      !amount ||
      !endDate ||
      !vehicleType ||
      !mobile
    ) {
      toast.error("All the fields are mandatory");
      return;
    }

    dispatch(
      createPass(vehicleNumber,vehicleType,mobile ,startDate,endDate, amount,  parking, location  )
    );
  };

  const addPassInfo = useSelector((state) => state.addPassInfo);
  const { loadingAddPass, addPassData, errorAddPass } = addPassInfo;

  useEffect(() => {
    dispatch({ type: CREATE_PASS_RESET });
    if (addPassData) {
      toast.success("Pass added successfully");
      navigate("/passes");
    } else if (errorAddPass) {
      toast.error(errorAddPass);
    }
  }, [addPassData, errorAddPass]);

  useEffect(() => {
    dispatch(getRatesForAdmins());
  }, []);

  const getRatesForAdminsInfo = useSelector(
    (state) => state.getRatesForAdminsInfo
  );
  const {
    loadingGetRatesForAdmins,
    getRatesForAdminsData,
    errorGetRatesForAdmins,
  } = getRatesForAdminsInfo;

  useEffect(() => {
    dispatch({ type: GET_RATES_FOR_ADMINS_RESET });
    if (getRatesForAdminsData) {
      setAllRates(getRatesForAdminsData);
    } else if (errorGetRatesForAdmins) {
      toast.error(errorGetRatesForAdmins);
    }
  }, [getRatesForAdminsData, errorGetRatesForAdmins]);

  useEffect(() => {
    dispatch(getLocationsForAdmins());
    setLoading(true);
  }, []);

  const getLocationsForAdminsInfo = useSelector(
    (state) => state.getLocationsForAdminsInfo
  );
  const {
    loadingGetLocationsForAdmins,
    getLocationsForAdminsData,
    errorGetLocationsForAdmins,
  } = getLocationsForAdminsInfo;

  useEffect(() => {
    dispatch({ type: GET_LOCATIONS_FOR_ADMINS_RESET });
    if (getLocationsForAdminsData) {
      setAllLocations(getLocationsForAdminsData);
      setLoading(false);
    } else if (errorGetLocationsForAdmins) {
      toast.error(errorGetLocationsForAdmins);
      setLoading(false);
    }
  }, [getLocationsForAdminsData, errorGetLocationsForAdmins]);
  return (
    <Box m="20px">
      <Header
        title={params?.id === "add" ? "Add Pass" : name}
        subtitle="Details of the Pass"
      />
      {loadingAddPass ? (
        <Loader />
      ) : (
        <Box width="100%" m="40px 0 0 0">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{ width: "100%" }}
          >
            <Typography
              id="keep-mounted-modal-title"
              variant="h3"
              component="h2"
            >
              Basic Details
            </Typography>
            <Button
              type="submit"
              color="secondary"
              variant="contained"
              sx={{ fontSize: 14 }}
              onClick={
                params?.id === "add"
                  ? addLocationHandler
                  : updateLocationHandler
              }
              disabled={loadingAddPass}
            >
              {params?.id === "add" ? "Add" : "Update"}
            </Button>
          </Box>
          <Box
            display="grid"
            gap={isNonMobile ? 4 : 2}
            gridTemplateColumns="repeat(4, minmax(0,
          1fr))"
            sx={{
              "& > div": {
                gridColumn: isNonMobile ? undefined : "span 4",
              },
              marginBottom: 6,
              marginTop: "20px",
            }}
          >
            <Box
              display="flex"
              flexDirection="column"
              gridColumn="span 4"
              gap={1}
            >
              <Typography variant="p" component="p">
                Location
              </Typography>
              <Select
                fullWidth
                variant="filled"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={location}
                label="Business Type"
                onChange={(e) => setLocation(e.target.value)}
                disabled={loading || params.id !== "add"}
              >
                {allLocations.map((item) => (
                  <MenuItem
                    value={item._id}
                    key={item._id}
                    selected={location === item._id}
                  >
                    {item.name} - ({item.businessType})
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              gridColumn="span 4"
              gap={1}
            >
              <Typography variant="p" component="p">
                Parking/Company
              </Typography>
              <Select
                fullWidth
                variant="filled"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={parking}
                label="Business Type"
                onChange={(e) => setParking(e.target.value)}
                disabled={loading || params.id !== "add"}
              >
                {parkings.map((item) => (
                  <MenuItem
                    value={item._id}
                    key={item._id}
                    selected={parking === item._id}
                  >
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </Box>

            <Box width="100%" display="flex" flexDirection="column" gap={1}>
              <Typography variant="h6" component="h4">
                Vehicle Type
              </Typography>
              <Select
                fullWidth
                variant="filled"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={vehicleType}
                label="Vehicle Type"
                onChange={(e) => setVehicleType(e.target.value)}
                name="vehicleType"
              >
                {VEHICLES.map((el) => (
                  <MenuItem value={el.toUpperCase()}>
                    {el.toUpperCase()}
                  </MenuItem>
                ))}
              </Select>
            </Box>

            <Box width="100%" display="flex" flexDirection="column" gap={1}>
              <Typography variant="h6" component="h4">
                Vehicle Number
              </Typography>
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Vehicle Number"
                onChange={(e) => setVehicleNumber(e.target.value)}
                value={vehicleNumber}
                name="vehicleNumber"
              />
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              gridColumn="span 1"
              gap={1}
            >
              <Typography variant="p" component="p">
                Mobile
              </Typography>
              <TextField
                fullWidth
                variant="filled"
                type="number"
                onInput={(e) => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 10);
                }}
                label="Mobile"
                onChange={(e) => setMobile(e.target.value)}
                value={mobile}
                name="mobile"
                sx={{ gridColumn: "span 1" }}
              />
            </Box>

            <Box width="100%" display="flex" flexDirection="column" gap={1}>
              <Typography variant="h6" component="h4">
                Amount
              </Typography>
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="amount"
                onChange={(e) => setAmount(e.target.value)}
                value={amount}
                name="amount"
              />
            </Box>
            <Box
              sx={{ gridColumn: "span 1" }}
              display="flex"
              flexDirection={isNonMobile ? "row" : "column"}
              justifyContent="space-between"
              gap={isNonMobile ? 4 : 2}
            >
              <TextField
                fullWidth
                variant="filled"
                type="date"
                label="Start Date"
                onChange={(e) => setStartDate(e.target.value)}
                value={moment(startDate).format("YYYY-MM-DD")}
                name="contractStartedOn"
                disabled={loadingAddPass}
              />
            </Box>
            <Box
              sx={{ gridColumn: "span 1" }}
              display="flex"
              flexDirection={isNonMobile ? "row" : "column"}
              justifyContent="space-between"
              gap={isNonMobile ? 4 : 2}
            >
              <TextField
                fullWidth
                variant="filled"
                type="date"
                label="End Date"
                onChange={(e) => setEndDate(e.target.value)}
                value={moment(endDate).format("YYYY-MM-DD")}
                name="contractStartedOn"
                disabled={loadingAddPass}
              />
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default PassesById;
