import { Box, Button, useTheme } from '@mui/material'
import { tokens } from '../theme'
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined'
import Header from '../components/Header'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'

import { logout } from '../actions/adminActions'
import { useNavigate } from 'react-router-dom'

const Dashboard = () => {
  const theme = useTheme()
  const colors = tokens(theme.palette.mode)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const adminLogin = useSelector((state) => state.adminLogin)
  const { adminInfo } = adminLogin

  useEffect(() => {
    if (!adminInfo) {
      dispatch(logout(navigate))
    }
  }, [adminInfo])

  return (
    <Box m='20px'>
      {/* HEADER */}
      <Box display='flex' justifyContent='space-between' alignItems='center'>
        <Header title='DASHBOARD' subtitle='Welcome to your dashboard' />
      </Box>
    </Box>
  )
}

export default Dashboard
