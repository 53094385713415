import {
  GET_INVITES_REQUEST,
  GET_INVITES_SUCCESS,
  GET_INVITES_FAIL,
  GET_INVITES_RESET,
} from '../constants/inviteConstants'

export const getInvitesReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_INVITES_REQUEST:
      return { loadingGetInvites: true }
    case GET_INVITES_SUCCESS:
      return { loadingGetInvites: false, getInvitesData: action.payload }
    case GET_INVITES_FAIL:
      return { loadingGetInvites: false, errorGetInvites: action.payload }
    case GET_INVITES_RESET:
      return {}
    default:
      return state
  }
}
