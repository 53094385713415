import { Box, CircularProgress } from '@mui/material'
import React from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'

const Loader = () => {
  const isNonMobile = useMediaQuery('(min-width:600px)')

  return (
    <Box
      width='100%'
      height={isNonMobile ? '65vh' : '60vh'}
      display='flex'
      flexDirection='column'
      alignItems='center'
      justifyContent='center'
    >
      <CircularProgress color='success' />
    </Box>
  )
}

export default Loader
