import { useState } from 'react'
import { ProSidebar, Menu, MenuItem } from 'react-pro-sidebar'
import { Box, IconButton, Typography, useTheme } from '@mui/material'
import { Link } from 'react-router-dom'
import 'react-pro-sidebar/dist/css/styles.css'
import { tokens } from '../../theme'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined'
import ContactsOutlinedIcon from '@mui/icons-material/ContactsOutlined'
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined'
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined'
import { useSelector } from 'react-redux'

const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme()
  const colors = tokens(theme.palette.mode)
  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.grey[100],
      }}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  )
}

const Sidebar = () => {
  const theme = useTheme()
  const colors = tokens(theme.palette.mode)
  const [isCollapsed, setIsCollapsed] = useState(false)
  const [selected, setSelected] = useState('Dashboard')

  const adminLogin = useSelector((state) => state.adminLogin)
  const { adminInfo } = adminLogin

  return (
    <Box
      sx={{
        '& .pro-sidebar-inner': {
          background: `${colors.primary[400]} !important`,
        },
        '& .pro-icon-wrapper': {
          backgroundColor: 'transparent !important',
        },
        '& .pro-inner-item': {
          padding: '5px 35px 5px 20px !important',
        },
        '& .pro-inner-item:hover': {
          color: '#868dfb !important',
        },
        '& .pro-menu-item.active': {
          color: '#6870fa !important',
        },
      }}
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape='square'>
          {/* LOGO AND MENU ICON */}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: '10px 0 20px 0',
              color: colors.grey[100],
            }}
          >
            {!isCollapsed && (
              <Box
                display='flex'
                justifyContent='space-between'
                alignItems='center'
                ml='15px'
              >
                <Typography variant='h3' color={colors.grey[100]}>
                  PARKOYE
                </Typography>
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>

          {!isCollapsed && (
            <Box mb='25px'>
              <Box display='flex' justifyContent='center' alignItems='center'>
                <div
                  style={{
                    cursor: 'pointer',
                    borderRadius: '50%',
                    backgroundColor: colors.greenAccent[500],
                    width: 70,
                    height: 70,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <h1 style={{ color: '#000', fontSize: 24 }}>
                    {adminInfo.name[0]}
                  </h1>
                </div>
              </Box>
              <Box textAlign='center'>
                <Typography
                  variant='h2'
                  color={colors.grey[100]}
                  fontWeight='bold'
                  sx={{ m: '10px 0 0 0' }}
                >
                  {adminInfo.name}
                </Typography>
                <Typography variant='h5' color={colors.greenAccent[500]}>
                  {adminInfo.role}
                </Typography>
              </Box>
            </Box>
          )}
          <Box paddingLeft={isCollapsed ? undefined : '10%'}>
            <Item
              title='Dashboard'
              to='/'
              icon={<HomeOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            <Typography
              variant='h6'
              color={colors.grey[300]}
              sx={{ m: '15px 0 5px 20px' }}
            >
              {isCollapsed ? 'Corp' : 'Corporate'}
            </Typography>
            {adminInfo.type === 'Admin' && (
              <>
                <Item
                  title='Admins'
                  to='/admins'
                  icon={<PeopleOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  title={
                    adminInfo.type === 'Unorganised' ? 'Locations' : 'Vendors'
                  }
                  to='/vendors'
                  icon={<ContactsOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
              </>
            )}
            {adminInfo.type === 'Admin' && (
              <Item
                title='Rates'
                to='/rates'
                icon={<PeopleOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              />
            )}
            {(adminInfo.type === 'Admin' ||
              (adminInfo.type === 'Corporate' &&
                adminInfo.role === 'Location')) && (
              <Item
                title={
                  adminInfo.type === 'Unorganised' ? 'Parkings' : 'Companies'
                }
                to='/companies'
                icon={<ReceiptOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              />
            )}
            <Item
              title='Users'
              to='/users'
              icon={<PeopleOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            {(adminInfo.type === 'Admin' || adminInfo.type === 'Corporate') && (
              <Item
                title={
                  adminInfo.role === 'Super Admin' || adminInfo.role === 'Admin'
                    ? 'Customers'
                    : 'Employees'
                }
                to={
                  adminInfo.role === 'Super Admin' || adminInfo.role === 'Admin'
                    ? '/customers'
                    : '/employees'
                }
                icon={<PeopleOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              />
            )}
            {(adminInfo.type === 'Admin' ||
              (adminInfo.type === 'Corporate' &&
                adminInfo.role === 'Location')) && (
              <Item
                title='Invites'
                to='/invites'
                icon={<ReceiptOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              />
            )}
            <Item
              title='Checkins'
              to='/checkins'
              icon={<ReceiptOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title='Passes'
              to='/passes'
              icon={<ReceiptOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            {(adminInfo.type === 'Admin' ||
              (adminInfo.type === 'Corporate' &&
                adminInfo.role === 'Location')) && (
              <Item
                title='Entries'
                to='/entries'
                icon={<PeopleOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              />
            )}
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  )
}

export default Sidebar
