import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import useMediaQuery from '@mui/material/useMediaQuery'

import { logout } from '../actions/adminActions'
import { useNavigate, useParams } from 'react-router-dom'
import {
  addLocation,
  getLocationById,
  updateLocation,
} from '../actions/locationActions'
import {
  ADD_LOCATION_RESET,
  GET_LOCATION_BY_ID_RESET,
  UPDATE_LOCATION_RESET,
} from '../constants/locationConstants'
import { VEHICLES } from '../constants'
import Header from '../components/Header'
import Table from '../components/Table'
import Loader from '../components/Loader'
import { getRatesForAdmins } from '../actions/rateActions'
import { GET_RATES_FOR_ADMINS_RESET } from '../constants/rateConstants'
import moment from 'moment'

const LocationById = () => {
  const isNonMobile = useMediaQuery('(min-width:600px)')

  const [name, setName] = useState('')
  const [address, setAddress] = useState('')
  const [latitude, setLatitude] = useState('')
  const [longitude, setLongitude] = useState('')
  const [contractStartedOn, setContractStartedOn] = useState(new Date())
  const [contractEndedOn, setContractEndedOn] = useState(new Date())
  const [modules, setModules] = useState({
    whiteListing: false,
    visitorManagement: false,
    delivery: false,
    foc:false,
    normalCheckin: false,
    pass: false,
  })
  const [inventoryCompanies, setInventoryCompanies] = useState(0)
  const [inventoryGeneral, setInventoryGeneral] = useState(0)
  const [parkings, setParkings] = useState([])
  const [vehicles, setVehicles] = useState([])
  const [gst, setGst] = useState('')
  const [addVehicleModal, setAddVehicleModal] = useState(false)
  const [addVehicleValue, setAddVehicleValue] = useState('')
  const [businessType, setBusinessType] = useState('')
  const [rateToDisplay, setRateToDisplay] = useState('')
  const [note, setNote] = useState('')
  const [displayName, setDisplayName] = useState('')
  const [showOnApp, setShowOnApp] = useState(true)
  const [rate, setRate] = useState(null)
  const [allRates, setAllRates] = useState([])

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const params = useParams()

  const adminLogin = useSelector((state) => state.adminLogin)
  const { adminInfo } = adminLogin

  useEffect(() => {
    if (!adminInfo) {
      dispatch(logout(navigate))
    }
  }, [adminInfo])

  useEffect(() => {
    if (params?.id && params.id !== 'add') {
      dispatch(getLocationById(params.id))
    }
  }, [params?.id])

  const getLocationByIdInfo = useSelector((state) => state.getLocationByIdInfo)
  const { loadingGetLocationById, getLocationByIdData, errorGetLocationById } =
    getLocationByIdInfo

  useEffect(() => {
    dispatch({ type: GET_LOCATION_BY_ID_RESET })
    if (getLocationByIdData) {
      const location = getLocationByIdData.data[0]

      setName(location?.name)
      setAddress(location?.address)
      setLatitude(location?.latitude)
      setLongitude(location?.longitude)
      setContractStartedOn(location?.contractStartedOn)
      setContractEndedOn(location?.contractEndedOn)
      setModules(location?.modules)
      setInventoryCompanies(location?.inventory?.companies.total)
      setInventoryGeneral(location?.inventory?.general.total)
      setParkings(location?.parkings)
      setBusinessType(location?.businessType)
      setDisplayName(location?.userAppDetails?.displayName)
      setRateToDisplay(location?.userAppDetails?.rateToDisplay)
      setNote(location?.userAppDetails?.note)
      setShowOnApp(location?.userAppDetails?.showOnApp)
      setRate(location?.rate)
      setVehicles(
        location?.vehicles.map((el) => el.toUpperCase())
      )
      setGst(location?.gst)
    } else if (errorGetLocationById) {
      toast.error(errorGetLocationById)
      navigate('/vendors')
    }
  }, [getLocationByIdData, errorGetLocationById])

  const columnsParking = [
    {
      field: 'name',
      headerName: 'Name',
      width: 250,
    },
    businessType === 'Unorganised' && {
      field: 'receiptDetails',
      headerName: 'Name on receipt',
      valueGetter: ({ value }) => value && value.name,
      width: 250,
    },
    businessType === 'Unorganised' && {
      field: 'vehicles',
      headerName: 'Vehicles',
      valueGetter: ({ value }) => value && value.length,
      width: 200,
    },
    businessType === 'Unorganised' && {
      field: 'pass',
      headerName: 'Pass',
      valueGetter: ({ value }) =>
        value && value.status === true ? 'Yes' : 'No',
      width: 200,
    },
    businessType === 'Unorganised' && {
      field: 'ocr',
      headerName: 'OCR',
      valueGetter: ({ value }) => (value && value === true ? 'Yes' : 'No'),
      width: 200,
    },
  ]

  const columnsVehicles = [
    {
      field: 'name',
      headerName: 'Name',
    },
  ]

  const updateLocationHandler = () => {
    if (
      !params.id ||
      !name ||
      !address ||
      !latitude ||
      !longitude ||
      !contractStartedOn ||
      !contractEndedOn ||
      !modules ||
      !businessType ||
      !displayName ||
      !rateToDisplay
    ) {
      toast.error('All the fields are mandatory')
      return
    }

    if (businessType === 'Corporate') {
      if (
        !inventoryCompanies ||
        !inventoryGeneral ||
        !rate ||
        vehicles.length === 0
      ) {
        toast.error('All the fields are mandatory')
        return
      }
    }

    dispatch(
      updateLocation(
        params.id,
        name,
        address,
        latitude,
        longitude,
        contractStartedOn,
        contractEndedOn,
        gst,
        modules,
        vehicles,
        inventoryCompanies,
        inventoryGeneral,
        businessType,
        displayName,
        rateToDisplay,
        note,
        showOnApp,
        rate
      )
    )
  }

  const updateLocationInfo = useSelector((state) => state.updateLocationInfo)
  const { loadingUpdateLocation, updateLocationData, errorUpdateLocation } =
    updateLocationInfo

  useEffect(() => {
    dispatch({ type: UPDATE_LOCATION_RESET })
    if (updateLocationData) {
      toast.success('Vendor updated successfully')
      setName(updateLocationData?.name)
      setAddress(updateLocationData?.address)
      setLatitude(updateLocationData?.latitude)
      setLongitude(updateLocationData?.longitude)
      setContractStartedOn(updateLocationData?.contractStartedOn)
      setContractEndedOn(updateLocationData?.contractEndedOn)
      setModules(updateLocationData?.modules)
      setInventoryCompanies(updateLocationData?.inventory?.companies.total)
      setInventoryGeneral(updateLocationData?.inventory?.general.total)
      setParkings(updateLocationData?.parkings)
      setBusinessType(updateLocationData?.businessType)
      setDisplayName(updateLocationData?.userAppDetails?.displayName)
      setRateToDisplay(updateLocationData?.userAppDetails?.rateToDisplay)
      setNote(updateLocationData?.userAppDetails?.note)
      setShowOnApp(updateLocationData?.userAppDetails?.showOnApp)
      setRate(updateLocationData?.rate)
      setVehicles(updateLocationData?.vehicles.map((el) => el.toUpperCase()))

      // setVehicles(
      //   updateLocationData?.vehicles?.map((el) => {
      //     return { name: el }
      //   })
      // )
      setGst(updateLocationData?.gst)
    } else if (errorUpdateLocation) {
      toast.error(errorUpdateLocation)
    }
  }, [updateLocationData, errorUpdateLocation])

  const addVehicleHandler = () => {
    console.log('addVehicleValue',addVehicleValue);
    if (!addVehicleValue) {
      toast.error('Select a value from the dropdown')
    } else {
      if (vehicles.includes(addVehicleValue)) {
        toast.error('This vehicle already exists')
        return
      }

      setVehicles([...vehicles, { name: addVehicleValue }])
      toast.success('Please click on final update after this change')
      setAddVehicleModal(false)
      setAddVehicleValue('')
    }
  }

  const addLocationHandler = () => {
    if (
      !name ||
      !address ||
      !latitude ||
      !longitude ||
      !contractStartedOn ||
      !contractEndedOn ||
      !modules ||
      !businessType ||
      !displayName ||
      !rateToDisplay
    ) {
      toast.error('All the fields are mandatory')
      return
    }

    if (businessType === 'Corporate') {
      if (
        !inventoryCompanies ||
        !inventoryGeneral ||
        vehicles.length === 0 ||
        !rate
      ) {
        toast.error('All the fields are mandatory')
        return
      }
    }

    dispatch(
      addLocation(
        name,
        address,
        latitude,
        longitude,
        contractStartedOn,
        contractEndedOn,
        gst,
        modules,
        vehicles,
        inventoryCompanies,
        inventoryGeneral,
        businessType,
        displayName,
        rateToDisplay,
        note,
        showOnApp,
        rate
      )
    )
  }

  const addLocationInfo = useSelector((state) => state.addLocationInfo)
  const { loadingAddLocation, addLocationData, errorAddLocation } =
    addLocationInfo

  useEffect(() => {
    dispatch({ type: ADD_LOCATION_RESET })
    if (addLocationData) {
      toast.success('Vendor added successfully')
      navigate('/vendors')
    } else if (errorAddLocation) {
      toast.error(errorAddLocation)
    }
  }, [addLocationData, errorAddLocation])

  useEffect(() => {
    dispatch(getRatesForAdmins())
  }, [])

  const getRatesForAdminsInfo = useSelector(
    (state) => state.getRatesForAdminsInfo
  )
  const {
    loadingGetRatesForAdmins,
    getRatesForAdminsData,
    errorGetRatesForAdmins,
  } = getRatesForAdminsInfo

  useEffect(() => {
    dispatch({ type: GET_RATES_FOR_ADMINS_RESET })
    if (getRatesForAdminsData) {
      setAllRates(getRatesForAdminsData)
    } else if (errorGetRatesForAdmins) {
      toast.error(errorGetRatesForAdmins)
    }
  }, [getRatesForAdminsData, errorGetRatesForAdmins])

  return (
    <Box m='20px'>
      <Header
        title={params?.id === 'add' ? 'Add Vendor' : name}
        subtitle='Details of the vendor'
      />
      {loadingAddLocation || loadingGetLocationById || loadingUpdateLocation ? (
        <Loader />
      ) : (
        <Box width='100%' m='40px 0 0 0'>
          <Box
            display='flex'
            justifyContent='space-between'
            alignItems='center'
            sx={{ width: '100%' }}
          >
            <Typography
              id='keep-mounted-modal-title'
              variant='h3'
              component='h2'
            >
              Basic Details
            </Typography>
            <Button
              type='submit'
              color='secondary'
              variant='contained'
              sx={{ fontSize: 14 }}
              onClick={
                params?.id === 'add'
                  ? addLocationHandler
                  : updateLocationHandler
              }
              disabled={
                loadingGetLocationById ||
                loadingUpdateLocation ||
                loadingAddLocation
              }
            >
              {params?.id === 'add' ? 'Add' : 'Update'}
            </Button>
          </Box>
          <Box
            display='grid'
            gap={isNonMobile ? 4 : 2}
            gridTemplateColumns='repeat(4, minmax(0,
        1fr))'
            sx={{
              '& > div': {
                gridColumn: isNonMobile ? undefined : 'span 4',
              },
              marginBottom: 6,
              marginTop: '20px',
            }}
          >
            <Box
              sx={{ gridColumn: 'span 4', marginTop: 2 }}
              display='flex'
              flexDirection={isNonMobile ? 'row' : 'column'}
              justifyContent='space-between'
              gap={isNonMobile ? 4 : 2}
            >
              <TextField
                fullWidth
                variant='filled'
                type='text'
                label='Name'
                onChange={(e) => setName(e.target.value)}
                value={name}
                name='name'
                disabled={
                  loadingGetLocationById ||
                  loadingUpdateLocation ||
                  loadingAddLocation
                }
              />
              <TextField
                fullWidth
                variant='filled'
                type='text'
                label='Address'
                onChange={(e) => setAddress(e.target.value)}
                value={address}
                name='address'
                disabled={
                  loadingGetLocationById ||
                  loadingUpdateLocation ||
                  loadingAddLocation
                }
              />
            </Box>
            <Box
              sx={{ gridColumn: 'span 4' }}
              display='flex'
              flexDirection={isNonMobile ? 'row' : 'column'}
              justifyContent='space-between'
              gap={isNonMobile ? 4 : 2}
            >
              <TextField
                fullWidth
                variant='filled'
                type='number'
                label='Latitude'
                onChange={(e) => setLatitude(e.target.value)}
                value={latitude}
                name='latitude'
                disabled={
                  loadingGetLocationById ||
                  loadingUpdateLocation ||
                  loadingAddLocation
                }
              />
              <TextField
                fullWidth
                variant='filled'
                type='text'
                label='Longitude'
                onChange={(e) => setLongitude(e.target.value)}
                value={longitude}
                name='longitude'
                disabled={
                  loadingGetLocationById ||
                  loadingUpdateLocation ||
                  loadingAddLocation
                }
              />
            </Box>
            <Box
              sx={{ gridColumn: 'span 4' }}
              display='flex'
              flexDirection={isNonMobile ? 'row' : 'column'}
              justifyContent='space-between'
              gap={isNonMobile ? 4 : 2}
            >
              <TextField
                fullWidth
                variant='filled'
                type='date'
                label='Contract Started On'
                onChange={(e) => setContractStartedOn(e.target.value)}
                value={moment(contractStartedOn).format('YYYY-MM-DD')}
                name='contractStartedOn'
                disabled={
                  loadingGetLocationById ||
                  loadingUpdateLocation ||
                  loadingAddLocation
                }
              />
              <TextField
                fullWidth
                variant='filled'
                type='date'
                label='Contract Ended On'
                onChange={(e) => setContractEndedOn(e.target.value)}
                value={moment(contractEndedOn).format('YYYY-MM-DD')}
                name='contractEndedOn'
                disabled={
                  loadingGetLocationById ||
                  loadingUpdateLocation ||
                  loadingAddLocation
                }
              />
            </Box>
            <Box
              sx={{ gridColumn: 'span 4' }}
              display='flex'
              flexDirection={isNonMobile ? 'row' : 'column'}
              justifyContent='space-between'
              gap={isNonMobile ? 4 : 2}
            >
              <TextField
                fullWidth
                variant='filled'
                type='text'
                label='GST'
                onChange={(e) => setGst(e.target.value)}
                value={gst}
                name='gst'
                disabled={
                  loadingGetLocationById ||
                  loadingUpdateLocation ||
                  loadingAddLocation
                }
              />
              <Select
                fullWidth
                variant='filled'
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                value={businessType}
                label='Business Type'
                onChange={(e) => setBusinessType(e.target.value)}
                disabled={
                  loadingAddLocation ||
                  loadingGetLocationById ||
                  loadingUpdateLocation ||
                  params.id !== 'add'
                }
              >
                <MenuItem value={'Unorganised'}>Unorganised</MenuItem>
                <MenuItem value={'Corporate'}>Corporate</MenuItem>
              </Select>
            </Box>
            {businessType === 'Corporate' && (
              <Box
                sx={{ gridColumn: 'span 4' }}
                display='flex'
                flexDirection={isNonMobile ? 'row' : 'column'}
                justifyContent='space-between'
                gap={isNonMobile ? 4 : 2}
              >
                <Select
                  fullWidth
                  variant='filled'
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  value={rate}
                  label='Rate'
                  onChange={(e) => setRate(e.target.value)}
                  disabled={
                    loadingAddLocation ||
                    loadingGetLocationById ||
                    loadingUpdateLocation
                  }
                >
                  {allRates.map((item) => (
                    <MenuItem
                      key={item._id}
                      value={item._id}
                      selected={rate === item._id.toString()}
                    >
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            )}
            {businessType === 'Corporate' && (
              <Box
                sx={{ gridColumn: 'span 4' }}
                display='flex'
                flexDirection={isNonMobile ? 'row' : 'column'}
                justifyContent='space-between'
                gap={isNonMobile ? 4 : 2}
              >
                <TextField
                  fullWidth
                  variant='filled'
                  type='number'
                  label='Companies Inventory'
                  onChange={(e) => setInventoryCompanies(e.target.value)}
                  value={inventoryCompanies}
                  name='companiesInventory'
                  disabled={
                    loadingGetLocationById ||
                    loadingUpdateLocation ||
                    loadingAddLocation
                  }
                />
                <TextField
                  fullWidth
                  variant='filled'
                  type='number'
                  label='General Inventory (for visitors)'
                  onChange={(e) => setInventoryGeneral(e.target.value)}
                  value={inventoryGeneral}
                  name='generalInventory'
                  disabled={
                    loadingGetLocationById ||
                    loadingUpdateLocation ||
                    loadingAddLocation
                  }
                />
              </Box>
            )}
          </Box>
          {businessType === 'Corporate' && (
            <>
              <Typography
                id='keep-mounted-modal-title'
                variant='h3'
                component='h2'
              >
                Module Details
              </Typography>
              <Box
                display='grid'
                gridTemplateColumns='repeat(4, minmax(0,
        1fr))'
                sx={{
                  '& > div': {
                    gridColumn: isNonMobile ? undefined : 'span 4',
                  },
                  marginBottom: 6,
                }}
              >
                <Box
                  sx={{ gridColumn: 'span 4', marginTop: 1 }}
                  display='flex'
                  flexDirection={isNonMobile ? 'row' : 'column'}
                  justifyContent='space-between'
                >
                  <FormControlLabel
                    label='Whitelisting'
                    control={
                      <Checkbox
                        checked={modules.whiteListing}
                        onChange={(e) =>
                          setModules({
                            ...modules,
                            whiteListing: e.target.checked,
                          })
                        }
                        color='success'
                        disabled={
                          loadingGetLocationById ||
                          loadingUpdateLocation ||
                          loadingAddLocation
                        }
                      />
                    }
                  />
                  <FormControlLabel
                    label='Visitor Management'
                    control={
                      <Checkbox
                        checked={modules.visitorManagement}
                        onChange={(e) =>
                          setModules({
                            ...modules,
                            visitorManagement: e.target.checked,
                          })
                        }
                        color='success'
                        disabled={
                          loadingGetLocationById ||
                          loadingUpdateLocation ||
                          loadingAddLocation
                        }
                      />
                    }
                  />
                   <FormControlLabel
                    label='FOC'
                    control={
                      <Checkbox
                        checked={modules.foc}
                        onChange={(e) =>
                          setModules({
                            ...modules,
                            foc: e.target.checked,
                          })
                        }
                        color='success'
                        disabled={
                          loadingGetLocationById ||
                          loadingUpdateLocation ||
                          loadingAddLocation
                        }
                      />
                    }
                  />
                  <FormControlLabel
                    label='Delivery'
                    control={
                      <Checkbox
                        checked={modules.delivery}
                        onChange={(e) =>
                          setModules({
                            ...modules,
                            delivery: e.target.checked,
                          })
                        }
                        color='success'
                        disabled={
                          loadingGetLocationById ||
                          loadingUpdateLocation ||
                          loadingAddLocation
                        }
                      />
                    }
                  />
                  <FormControlLabel
                    label='Normal Checkin'
                    control={
                      <Checkbox
                        checked={modules.normalCheckin}
                        onChange={(e) =>
                          setModules({
                            ...modules,
                            normalCheckin: e.target.checked,
                          })
                        }
                        color='success'
                        disabled={
                          loadingGetLocationById ||
                          loadingUpdateLocation ||
                          loadingAddLocation
                        }
                      />
                    }
                  />
                  <FormControlLabel
                    label='Pass'
                    control={
                      <Checkbox
                        checked={modules.pass}
                        onChange={(e) =>
                          setModules({
                            ...modules,
                            pass: e.target.checked,
                          })
                        }
                        color='success'
                        disabled={
                          loadingGetLocationById ||
                          loadingUpdateLocation ||
                          loadingAddLocation
                        }
                      />
                    }
                  />
                </Box>
              </Box>
            </>
          )}
          <Box
            display='flex'
            justifyContent='space-between'
            alignItems='center'
            sx={{ width: '100%' }}
          >
            <Typography
              id='keep-mounted-modal-title'
              variant='h3'
              component='h2'
            >
              User Application Details
            </Typography>
          </Box>
          <Box
            display='grid'
            gap={isNonMobile ? 4 : 2}
            gridTemplateColumns='repeat(4, minmax(0,
        1fr))'
            sx={{
              '& > div': {
                gridColumn: isNonMobile ? undefined : 'span 4',
              },
              marginBottom: 6,
              marginTop: '20px',
            }}
          >
            <Box
              sx={{ gridColumn: 'span 4', marginTop: 2 }}
              display='flex'
              flexDirection={isNonMobile ? 'row' : 'column'}
              justifyContent='space-between'
              gap={isNonMobile ? 4 : 2}
            >
              <TextField
                fullWidth
                variant='filled'
                type='text'
                label='Display Name'
                onChange={(e) => setDisplayName(e.target.value)}
                value={displayName}
                name='displayName'
                disabled={
                  loadingGetLocationById ||
                  loadingUpdateLocation ||
                  loadingAddLocation
                }
              />
              <TextField
                fullWidth
                variant='filled'
                type='text'
                label='Rate to Display'
                onChange={(e) => setRateToDisplay(e.target.value)}
                value={rateToDisplay}
                name='rateToDisplay'
                disabled={
                  loadingGetLocationById ||
                  loadingUpdateLocation ||
                  loadingAddLocation
                }
              />
            </Box>
            <Box
              sx={{ gridColumn: 'span 4' }}
              display='flex'
              flexDirection={isNonMobile ? 'row' : 'column'}
              justifyContent='space-between'
              gap={isNonMobile ? 4 : 2}
            >
              <TextField
                fullWidth
                variant='filled'
                type='text'
                label='Note'
                onChange={(e) => setNote(e.target.value)}
                value={note}
                name='note'
                disabled={
                  loadingGetLocationById ||
                  loadingUpdateLocation ||
                  loadingAddLocation
                }
              />
              <Select
                fullWidth
                variant='filled'
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                value={showOnApp}
                label='Show on app'
                onChange={(e) => setShowOnApp(e.target.value)}
                disabled={
                  loadingAddLocation ||
                  loadingGetLocationById ||
                  loadingUpdateLocation
                }
              >
                <MenuItem value={true} selected={showOnApp}>
                  Yes
                </MenuItem>
                <MenuItem value={false} selected={showOnApp}>
                  No
                </MenuItem>
              </Select>
            </Box>
          </Box>
          {params.id !== 'add' && (
            <>
              <Box
                display='flex'
                justifyContent='space-between'
                alignItems='center'
                sx={{ width: '100%' }}
              >
                <Typography
                  id='keep-mounted-modal-title'
                  variant='h3'
                  component='h2'
                >
                  {businessType === 'Corporate' ? 'Companies' : 'Parkings'}
                </Typography>
              </Box>
              <Table
                rows={parkings}
                total={parkings.length}
                page={1}
                columns={columnsParking}
                getRowId={(row) => row._id}
                marginBottom={6}
              />
            </>
          )}
          {businessType === 'Corporate' && (
            <>
              <Box
                display='flex'
                justifyContent='space-between'
                alignItems='center'
                sx={{ width: '100%' }}
              >
                <Typography
                  id='keep-mounted-modal-title'
                  variant='h3'
                  component='h2'
                >
                  Vehicles
                </Typography>
                {/* <Button
                  type='submit'
                  color='warning'
                  variant='contained'
                  sx={{ fontSize: 14 }}
                  disabled={
                    loadingGetLocationById ||
                    loadingUpdateLocation ||
                    loadingAddLocation
                  }
                  onClick={() => setAddVehicleModal(true)}
                >
                  Add Vehicle
                </Button> */}
              </Box>
              <Box
                display='flex'
                flexDirection='column'
                gridColumn='span 4'
                gap={1}
                marginTop={2}
              >
                <Typography variant='p' component='p'>
                  Vehicles
                </Typography>
                <Select
                  variant='filled'
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  value={vehicles}
                  label='Vehicles'
                  multiple
                  onChange={(e) => setVehicles(e.target.value)}
                  sx={{ gridColumn: 'span 4' }}
                  // disabled={loading}
                >
                  {VEHICLES.map((item) => (
                    <MenuItem
                      key={item}
                      value={item.toUpperCase()}
                      selected={vehicles.includes(item.toString())}
                    >
                      {item.toUpperCase()}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
              {/* <Table
                rows={vehicles}
                total={vehicles.length}
                page={1}
                columns={columnsVehicles}
                getRowId={(row) => row.name}
              /> */}
            </>
          )}
        </Box>
      )}
      {addVehicleModal && (
        <Modal
          keepMounted
          open
          onClose={() => {
            setAddVehicleModal(false)
            setAddVehicleValue('')
          }}
          aria-labelledby='keep-mounted-modal-title'
          aria-describedby='keep-mounted-modal-description'
        >
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 400,
              bgcolor: 'background.paper',
              border: '2px solid #000',
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography
              id='keep-mounted-modal-title'
              variant='h6'
              component='h2'
            >
              Add Vehicle
            </Typography>
            <div>
              <Box
                mt='20px'
                display='grid'
                gridTemplateColumns='repeat(4, minmax(0, 1fr))'
                sx={{
                  '& > div': {
                    gridColumn: isNonMobile ? undefined : 'span 4',
                  },
                }}
              >
                <Select
                  variant='filled'
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  value={addVehicleValue}
                  label='Vehicle'
                  onChange={(e) => setAddVehicleValue(e.target.value)}
                  sx={{ gridColumn: 'span 4' }}
                  disabled={
                    loadingGetLocationById ||
                    loadingUpdateLocation ||
                    loadingAddLocation
                  }
                >
                  {VEHICLES.map((item) => (
                    <MenuItem key={item} value={item}>
                      {item.toUpperCase()}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
              <Box
                mt='20px'
                display='flex'
                justifyContent='space-between'
                sx={{ height: 42 }}
              >
                <Button
                  type='submit'
                  color='secondary'
                  variant='contained'
                  sx={{ fontSize: 14 }}
                  onClick={addVehicleHandler}
                  disabled={
                    loadingGetLocationById ||
                    loadingUpdateLocation ||
                    loadingAddLocation
                  }
                >
                  Add
                </Button>
              </Box>
            </div>
          </Box>
        </Modal>
      )}
    </Box>
  )
}

export default LocationById
