import {
  ADMIN_LOGIN_REQUEST,
  ADMIN_LOGIN_SUCCESS,
  ADMIN_LOGIN_FAIL,
  ADMIN_LOGOUT,
  GET_ADMINS_REQUEST,
  GET_ADMINS_SUCCESS,
  GET_ADMINS_FAIL,
  GET_ADMINS_RESET,
  EDIT_ADMIN_REQUEST,
  EDIT_ADMIN_SUCCESS,
  EDIT_ADMIN_FAIL,
  EDIT_ADMIN_RESET,
  GENERATE_PASSWORD_ADMIN_REQUEST,
  GENERATE_PASSWORD_ADMIN_SUCCESS,
  GENERATE_PASSWORD_ADMIN_FAIL,
  GENERATE_PASSWORD_ADMIN_RESET,
  ADD_ADMIN_REQUEST,
  ADD_ADMIN_SUCCESS,
  ADD_ADMIN_FAIL,
  ADD_ADMIN_RESET,
} from '../constants/adminConstants'

export const adminLoginReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_LOGIN_REQUEST:
      return { loadingAdminInfo: true }
    case ADMIN_LOGIN_SUCCESS:
      return { loadingAdminInfo: false, adminInfo: action.payload }
    case ADMIN_LOGIN_FAIL:
      return { loadingAdminInfo: false, errorAdminInfo: action.payload }
    case ADMIN_LOGOUT:
      return {}
    default:
      return state
  }
}

export const getAdminsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ADMINS_REQUEST:
      return { loadingGetAdmins: true }
    case GET_ADMINS_SUCCESS:
      return { loadingGetAdmins: false, getAdminsData: action.payload }
    case GET_ADMINS_FAIL:
      return { loadingGetAdmins: false, errorGetAdmins: action.payload }
    case GET_ADMINS_RESET:
      return {}
    default:
      return state
  }
}

export const editAdminReducer = (state = {}, action) => {
  switch (action.type) {
    case EDIT_ADMIN_REQUEST:
      return { loadingEditAdmin: true }
    case EDIT_ADMIN_SUCCESS:
      return { loadingEditAdmin: false, editAdminData: action.payload }
    case EDIT_ADMIN_FAIL:
      return { loadingEditAdmin: false, errorEditAdmin: action.payload }
    case EDIT_ADMIN_RESET:
      return {}
    default:
      return state
  }
}

export const generatePasswordAdminReducer = (state = {}, action) => {
  switch (action.type) {
    case GENERATE_PASSWORD_ADMIN_REQUEST:
      return { loadingGeneratePasswordAdmin: true }
    case GENERATE_PASSWORD_ADMIN_SUCCESS:
      return {
        loadingGeneratePasswordAdmin: false,
        generatePasswordAdminData: action.payload,
      }
    case GENERATE_PASSWORD_ADMIN_FAIL:
      return {
        loadingGeneratePasswordAdmin: false,
        errorGeneratePasswordAdmin: action.payload,
      }
    case GENERATE_PASSWORD_ADMIN_RESET:
      return {}
    default:
      return state
  }
}

export const addAdminReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_ADMIN_REQUEST:
      return { loadingAddAdmin: true }
    case ADD_ADMIN_SUCCESS:
      return { loadingAddAdmin: false, addAdminData: action.payload }
    case ADD_ADMIN_FAIL:
      return { loadingAddAdmin: false, errorAddAdmin: action.payload }
    case ADD_ADMIN_RESET:
      return {}
    default:
      return state
  }
}
