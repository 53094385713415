import {
  GET_ENTRIES_FAIL,
  GET_ENTRIES_REQUEST,
  GET_ENTRIES_RESET,
  GET_ENTRIES_SUCCESS,
} from '../constants/entryConstants'

export const getEntriesReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ENTRIES_REQUEST:
      return { loadingGetEntries: true }
    case GET_ENTRIES_SUCCESS:
      return { loadingGetEntries: false, getEntriesData: action.payload }
    case GET_ENTRIES_FAIL:
      return { loadingGetEntries: false, errorGetEntries: action.payload }
    case GET_ENTRIES_RESET:
      return {}
    default:
      return state
  }
}
