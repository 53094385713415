import { Box, Button, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Modal from "@mui/material/Modal";
import useMediaQuery from "@mui/material/useMediaQuery";
import Typography from "@mui/material/Typography";

import { logout } from "../actions/adminActions";
import { getParkings } from "../actions/parkingActions";
import { GET_PARKINGS_RESET } from "../constants/parkingConstants";
import Header from "../components/Header";
import Table from "../components/Table";
import Loader from "../components/Loader";

const Parkings = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");

  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [showFiltersModal, setShowFiltersModal] = useState(false);
  const [filterName, setFilterName] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const adminLogin = useSelector((state) => state.adminLogin);
  const { adminInfo } = adminLogin;

  useEffect(() => {
    if (!adminInfo) {
      dispatch(logout(navigate));
    }
  }, [adminInfo]);

  useMemo(() => {
    dispatch(getParkings(page, filterName));
    setLoading(true);
  }, [page]);

  const getParkingsInfo = useSelector((state) => state.getParkingsInfo);
  const { loadingGetParkings, getParkingsData, errorGetParkings } =
    getParkingsInfo;

  useEffect(() => {
    dispatch({ type: GET_PARKINGS_RESET });
    if (getParkingsData) {
      setData(getParkingsData.data);
      setTotal(getParkingsData.total);
      setPage(+getParkingsData.page);
      setLoading(false);
    } else if (errorGetParkings) {
      toast.error(errorGetParkings);
      setLoading(false);
    }
  }, [getParkingsData, errorGetParkings]);

  const columns = [
    { field: "name", headerName: "Name", flex: 1 },
    {
      field: "location",
      headerName: "Vendor",
      valueGetter: ({ value }) => (value ? value.name : ""),
      flex: 1,
    },
    {
      field: "vehicles",
      headerName: "Vehicles",
      valueGetter: ({ value }) => value && value.length,
      flex: 1,
    },
    {
      field: "pass",
      headerName: "Pass",
      valueGetter: ({ value }) =>
        value && value.status === true ? "Yes" : "No",
      flex: 1,
    },
    {
      field: "ocr",
      headerName: "OCR",
      valueGetter: ({ value }) => (value && value === true ? "Yes" : "No"),
      flex: 1,
    },
  ];

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="COMPANIES" subtitle="List of companies" />
        <Box>
          <Button
            type="submit"
            color="secondary"
            variant="contained"
            sx={{ fontSize: 14, marginRight: 4 }}
            onClick={() => navigate("/companies/add")}
            disabled={loading}
          >
            Add Company
          </Button>
          <Button
            type="submit"
            color="warning"
            variant="contained"
            sx={{ fontSize: 14 }}
            disabled={loadingGetParkings}
            onClick={() => setShowFiltersModal(true)}
          >
            Filters
          </Button>
        </Box>
      </Box>
      {loading ? (
        <Loader />
      ) : (
        <Table
          onPageChange={(newPage) => {
            setPage(newPage + 1);
          }}
          total={total}
          rows={data}
          columns={columns}
          page={page}
          onRowClick={(row) => navigate("/companies/" + row.row._id)}
          getRowId={(row) => row._id}
        />
      )}
      {showFiltersModal && (
        <Modal
          keepMounted
          open
          onClose={() => {
            setShowFiltersModal(false);
          }}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: isNonMobile ? 800 : 350,
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography
              id="keep-mounted-modal-title"
              variant="h4"
              component="h2"
            >
              Filters
            </Typography>
            <div>
              <Box
                display="grid"
                gap="30px"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                sx={{
                  "& > div": {
                    gridColumn: isNonMobile ? undefined : "span 4",
                  },
                }}
              >
                <Box
                  display="flex"
                  flexDirection={isNonMobile ? "row" : "column"}
                  gridColumn="span 4"
                  marginTop={2}
                  gap={isNonMobile ? 4 : 2}
                >
                  <Box
                    width="100%"
                    display="flex"
                    flexDirection="column"
                    gap={1}
                  >
                    <Typography variant="h6" component="h4">
                      Name
                    </Typography>
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Name"
                      onChange={(e) => setFilterName(e.target.value)}
                      value={filterName}
                      name="name"
                      disabled={loadingGetParkings}
                    />
                  </Box>
                </Box>
                <Button
                  type="submit"
                  color="secondary"
                  variant="contained"
                  sx={{ fontSize: 14 }}
                  onClick={() => {
                    setLoading(true);
                    setShowFiltersModal(false);
                    dispatch(getParkings(page, filterName));
                  }}
                  disabled={loadingGetParkings}
                >
                  Apply
                </Button>
              </Box>
            </div>
          </Box>
        </Modal>
      )}
    </Box>
  );
};

export default Parkings;
