import { Box, Button, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import Modal from "@mui/material/Modal";
import useMediaQuery from "@mui/material/useMediaQuery";
import Typography from "@mui/material/Typography";

import { logout } from "../actions/adminActions";
import { getLocations } from "../actions/locationActions";
import { GET_LOCATIONS_RESET } from "../constants/locationConstants";
import Header from "../components/Header";
import Table from "../components/Table";
import Loader from "../components/Loader";

const Locations = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");

  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [showFiltersModal, setShowFiltersModal] = useState(false);
  const [filterName, setFilterName] = useState("");
  const [filterGst, setFilterGst] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const adminLogin = useSelector((state) => state.adminLogin);
  const { adminInfo } = adminLogin;

  useEffect(() => {
    if (!adminInfo) {
      dispatch(logout(navigate));
    }
  }, [adminInfo]);

  useEffect(() => {
    dispatch(getLocations(page, filterName, filterGst));
    setLoading(true);
  }, [page]);

  const getLocationsInfo = useSelector((state) => state.getLocationsInfo);
  const { loadingGetLocations, getLocationsData, errorGetLocations } =
    getLocationsInfo;

  useEffect(() => {
    dispatch({ type: GET_LOCATIONS_RESET });
    if (getLocationsData) {
      setData(getLocationsData.data);
      setTotal(getLocationsData.total);
      setPage(+getLocationsData.page);
      setLoading(false);
    } else if (errorGetLocations) {
      toast.error(errorGetLocations);
      setLoading(false);
    }
  }, [getLocationsData, errorGetLocations]);

  const columns = [
    { field: "name", headerName: "Name", width: 250 },
    { field: "address", headerName: "Address", width: 350 },
    {
      field: "businessType",
      headerName: "Business Type",
      minWidth: 200,
    },
    {
      field: "latitude",
      headerName: "Longitude",
      minWidth: 200,
    },
    {
      field: "longitude",
      headerName: "Longitude",
      minWidth: 200,
    },
    {
      field: "gst",
      headerName: "GST",
      minWidth: 200,
    },
    {
      field: "contractStartedOn",
      headerName: "Contract Started On",
      valueGetter: ({ value }) => value && moment(value).format("DD-MM-YYYY"),
      minWidth: 200,
    },
    {
      field: "contractEndedOn",
      headerName: "Contract Ended On",
      valueGetter: ({ value }) => value && moment(value).format("DD-MM-YYYY"),
      minWidth: 200,
    },
  ];

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="VENDORS" subtitle="List of vendors or locations" />
        <Box>
          <Button
            type="submit"
            color="secondary"
            variant="contained"
            sx={{ fontSize: 14, marginRight: 4 }}
            onClick={() => navigate("/vendors/add")}
          >
            Add Vendor
          </Button>
          <Button
            type="submit"
            color="warning"
            variant="contained"
            sx={{ fontSize: 14 }}
            disabled={loadingGetLocations}
            onClick={() => setShowFiltersModal(true)}
          >
            Filters
          </Button>
        </Box>
      </Box>
      {loading ? (
        <Loader />
      ) : (
        <Table
          onPageChange={(newPage) => {
            setPage(newPage + 1);
          }}
          total={total}
          rows={data}
          columns={columns}
          page={page}
          onRowClick={(row) => navigate("/vendors/" + row.row._id)}
          getRowId={(row) => row._id}
        />
      )}
      {showFiltersModal && (
        <Modal
          keepMounted
          open
          onClose={() => {
            setShowFiltersModal(false);
          }}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: isNonMobile ? 800 : 350,
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography
              id="keep-mounted-modal-title"
              variant="h4"
              component="h2"
            >
              Filters
            </Typography>
            <div>
              <Box
                display="grid"
                gap="30px"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                sx={{
                  "& > div": {
                    gridColumn: isNonMobile ? undefined : "span 4",
                  },
                }}
              >
                <Box
                  display="flex"
                  flexDirection={isNonMobile ? "row" : "column"}
                  gridColumn="span 4"
                  marginTop={2}
                  gap={isNonMobile ? 4 : 2}
                >
                  <Box
                    width="100%"
                    display="flex"
                    flexDirection="column"
                    gap={1}
                  >
                    <Typography variant="h6" component="h4">
                      Name
                    </Typography>
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Name"
                      onChange={(e) => setFilterName(e.target.value)}
                      value={filterName}
                      name="name"
                      disabled={loadingGetLocations}
                    />
                  </Box>
                  <Box
                    width="100%"
                    display="flex"
                    flexDirection="column"
                    gap={1}
                  >
                    <Typography variant="h6" component="h4">
                      GST
                    </Typography>
                    <TextField
                      fullWidth
                      variant="filled"
                      type="name"
                      label="GST"
                      onChange={(e) => setFilterGst(e.target.value)}
                      value={filterGst}
                      name="gst"
                      disabled={loadingGetLocations}
                    />
                  </Box>
                </Box>
                <Button
                  type="submit"
                  color="secondary"
                  variant="contained"
                  sx={{ fontSize: 14 }}
                  onClick={() => {
                    setLoading(true);
                    setShowFiltersModal(false);
                    dispatch(getLocations(page, filterName, filterGst));
                  }}
                  disabled={loadingGetLocations}
                >
                  Apply
                </Button>
              </Box>
            </div>
          </Box>
        </Modal>
      )}
    </Box>
  );
};

export default Locations;
