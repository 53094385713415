import { useState } from 'react'
import { Routes, Route } from 'react-router-dom'
import Topbar from './scenes/global/Topbar'
import Sidebar from './scenes/global/Sidebar'
import Dashboard from './pages/Dashboard'
import { CssBaseline, ThemeProvider } from '@mui/material'
import { ColorModeContext, useMode } from './theme'
import Login from './pages/Login'
import { ToastContainer, toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import Admins from './pages/Admins'
import Checkins from './pages/Checkins'
import Invites from './pages/Invites'
import Customers from './pages/Customers'
import Users from './pages/Users'
import Entries from './pages/Entries'
import Locations from './pages/Locations'
import LocationById from './pages/LocationById'
import PassesById from './pages/PassesById'
import Parkings from './pages/Parkings'
import Passes from './pages/Passes'
import ParkingById from './pages/ParkingById'
import Rates from './pages/Rates'
import RateById from './pages/RateById'

function App() {
  const [theme, colorMode] = useMode()
  const [isSidebar, setIsSidebar] = useState(true)

  const adminLogin = useSelector((state) => state.adminLogin)
  const { adminInfo } = adminLogin

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className='app'>
          <ToastContainer
            limit={1}
            hideProgressBar={true}
            autoClose={2000}
            position={toast.POSITION.BOTTOM_RIGHT}
          />
          {adminInfo ? (
            <>
              <Sidebar isSidebar={isSidebar} />
              <main className='content'>
                <Topbar setIsSidebar={setIsSidebar} />
                <Routes>
                  <Route path='/' element={<Dashboard />} />
                  <Route path='/admins' element={<Admins />} />
                  <Route path='/checkins' element={<Checkins />} />
                  <Route path='/invites' element={<Invites />} />
                  <Route path='/customers' element={<Customers />} />
                  <Route path='/employees' element={<Customers />} />
                  <Route path='/users' element={<Users />} />
                  <Route path='/entries' element={<Entries />} />
                  <Route path='/vendors' element={<Locations />} />
                  <Route path='/vendors/:id' element={<LocationById />} />
                  <Route path='/companies' element={<Parkings />} />
                  <Route path='/companies/:id' element={<ParkingById />} />
                  <Route path='/passes' element={<Passes />} />
                  <Route path='/passes/:id' element={<PassesById />} />
                  <Route path='/rates' element={<Rates />} />
                  <Route path='/rates/:id' element={<RateById />} />
                </Routes>
              </main>
            </>
          ) : (
            <Routes>
              <Route path='/' element={<Login />} />
            </Routes>
          )}
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  )
}

export default App
