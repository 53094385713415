import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'

import {
  editAdminReducer,
  generatePasswordAdminReducer,
  getAdminsReducer,
  adminLoginReducer,
  addAdminReducer,
} from './reducers/adminReducers'
import {
  deleteCheckinReducer,
  deleteCheckinsReducer,
  downloadCheckinsReducer,
  focCheckinReducer,
  getCheckinsReducer,
} from './reducers/checkinReducers'
import { getInvitesReducer } from './reducers/inviteReducers'
import {
  addCustomerParkingReducer,
  addCustomerVehicleReducer,
  addEmployeeReducer,
  editCustomerParkingReducer,
  getCustomersReducer,
  addCustomerDetailsReducer,
} from './reducers/customerReducers'
import {
  addUserReducer,
  editUserReducer,
  generatePasswordUserReducer,
  getUsersReducer,
  resetDeviceIdUserReducer,
} from './reducers/userReducers'
import {
  addLocationReducer,
  getLocationByIdReducer,
  getLocationsForAdminsReducer,
  getLocationsReducer,
  updateLocationReducer,
} from './reducers/locationReducers'
import {
  addParkingReducer,
  getParkingByIdReducer,
  getParkingsForAdminsReducer,
  getParkingsReducer,
  updateParkingReducer,
} from './reducers/parkingReducers'
import { getEntriesReducer } from './reducers/entryReducers'
import {
  addPassReducer,
  downloadPassesReducer,
  getPassesReducer,
} from './reducers/passReducers'
import {
  addRateReducer,
  getRateByIdReducer,
  getRatesForAdminsReducer,
  getRatesReducer,
  updateRateReducer,
} from './reducers/rateReducers'

const reducer = combineReducers({
  adminLogin: adminLoginReducer,
  getAdminsInfo: getAdminsReducer,
  editAdminInfo: editAdminReducer,
  generatePasswordAdminInfo: generatePasswordAdminReducer,
  getCheckinsInfo: getCheckinsReducer,
  getInvitesInfo: getInvitesReducer,
  getCustomersInfo: getCustomersReducer,
  getUsersInfo: getUsersReducer,
  addAdminInfo: addAdminReducer,
  getLocationsInfo: getLocationsReducer,
  getLocationsForAdminsInfo: getLocationsForAdminsReducer,
  editUserInfo: editUserReducer,
  generatePasswordUserInfo: generatePasswordUserReducer,
  resetDeviceIdUserInfo: resetDeviceIdUserReducer,
  addUserInfo: addUserReducer,
  addCustomerVehicleInfo: addCustomerVehicleReducer,
  getParkingsInfo: getParkingsReducer,
  getParkingsForAdminsInfo: getParkingsForAdminsReducer,
  addCustomerDetailsInfo: addCustomerDetailsReducer,
  addCustomerParkingInfo: addCustomerParkingReducer,
  editCustomerParkingInfo: editCustomerParkingReducer,
  getEntriesInfo: getEntriesReducer,
  addEmployeeInfo: addEmployeeReducer,
  getLocationByIdInfo: getLocationByIdReducer,
  updateLocationInfo: updateLocationReducer,
  addLocationInfo: addLocationReducer,
  updateParkingInfo: updateParkingReducer,
  addParkingInfo: addParkingReducer,
  getPassesInfo: getPassesReducer,
  addPassInfo: addPassReducer,
  focCheckinInfo: focCheckinReducer,
  getParkingByIdInfo: getParkingByIdReducer,
  deleteCheckinInfo: deleteCheckinReducer,
  downloadCheckinsInfo: downloadCheckinsReducer,
  downloadPassesInfo: downloadPassesReducer,
  getRatesInfo: getRatesReducer,
  getRateByIdInfo: getRateByIdReducer,
  addRateInfo: addRateReducer,
  updateRateInfo: updateRateReducer,
  getRatesForAdminsInfo: getRatesForAdminsReducer,
  deleteCheckinsInfo: deleteCheckinsReducer,
})

const adminInfoFromStorage = localStorage.getItem('adminInfo')
  ? JSON.parse(localStorage.getItem('adminInfo'))
  : null

const inititalState = {
  adminLogin: { adminInfo: adminInfoFromStorage },
}

const middleware = [thunk]

const store = createStore(
  reducer,
  inititalState,
  composeWithDevTools(applyMiddleware(...middleware))
)

export default store
