import axios from 'axios'
import {
  ADD_CUSTOMER_PARKING_FAIL,
  ADD_CUSTOMER_PARKING_REQUEST,
  ADD_CUSTOMER_PARKING_SUCCESS,
  ADD_CUSTOMER_VEHICLE_FAIL,
  ADD_CUSTOMER_VEHICLE_REQUEST,
  ADD_CUSTOMER_VEHICLE_SUCCESS,
  ADD_EMPLOYEE_FAIL,
  ADD_EMPLOYEE_REQUEST,
  ADD_EMPLOYEE_SUCCESS,
  EDIT_CUSTOMER_DETAILS_FAIL,
  EDIT_CUSTOMER_DETAILS_REQUEST,
  EDIT_CUSTOMER_DETAILS_SUCCESS,
  EDIT_CUSTOMER_PARKING_FAIL,
  EDIT_CUSTOMER_PARKING_REQUEST,
  ADD_CUSTOMER_DETAILS_FAIL,
  ADD_CUSTOMER_DETAILS_SUCCESS,
  EDIT_CUSTOMER_PARKING_SUCCESS,
  ADD_CUSTOMER_DETAILS_REQUEST,
  EDIT_CUSTOMER_VEHICLE_FAIL,
  EDIT_CUSTOMER_VEHICLE_REQUEST,
  EDIT_CUSTOMER_VEHICLE_SUCCESS,
  GET_CUSTOMERS_FAIL,
  GET_CUSTOMERS_REQUEST,
  GET_CUSTOMERS_SUCCESS,
} from '../constants/customerConstants'
import EventBus from "../lib/EventBus";
import { globalEvents } from '../constants/index';
import { toast } from "react-toastify";


export const getCustomers = (page) => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_CUSTOMERS_REQUEST,
    })

    const {
      adminLogin: { adminInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${adminInfo.token}`,
      },
    }

    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/customers?page=${page}`,
      config
    )


    dispatch({
      type: GET_CUSTOMERS_SUCCESS,
      payload: data,
    })
    // console.log(data);
    EventBus.getInstance().fireEvent(globalEvents.updateLoading);

  } catch (error) {
    dispatch({
      type: GET_CUSTOMERS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
    EventBus.getInstance().fireEvent(globalEvents.updateLoading);

  }
}
export const searchCustomers = (mobile) => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_CUSTOMERS_REQUEST,
    })

    const {
      adminLogin: { adminInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${adminInfo.token}`,
      },
    }

    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/customers?mobile=${mobile}`,
      config
    )
    EventBus.getInstance().fireEvent(globalEvents.updateLoading);


    dispatch({
      type: GET_CUSTOMERS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: GET_CUSTOMERS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
    EventBus.getInstance().fireEvent(globalEvents.updateLoading);

  }
}
export const addEmployee =
  (
    mobile,
    parking,
    name,
    employeeCode,
    email,
    department,
    position,
    parkingType,
    parkingInvite,
    parkingSlot
  ) =>
    async (dispatch, getState) => {
      try {
        dispatch({
          type: ADD_EMPLOYEE_REQUEST,
        })

        const {
          adminLogin: { adminInfo },
        } = getState()

        const config = {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${adminInfo.token}`,
          },
        }

        const { data } = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/customers/employee`,
          {
            mobile,
            parking,
            name,
            employeeCode,
            email,
            department,
            position,
            parkingType,
            parkingInvite,
            parkingSlot,
          },
          config
        )

        dispatch({
          type: ADD_EMPLOYEE_SUCCESS,
          payload: data,
        })
      } catch (error) {
        dispatch({
          type: ADD_EMPLOYEE_FAIL,
          payload:
            error.response && error.response.data.message
              ? error.response.data.message
              : error.message,
        })
      }
    }
export const addCustomerVehicle =
  (id, vehicleNumber, vehicleType) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ADD_CUSTOMER_VEHICLE_REQUEST,
      })

      const {
        adminLogin: { adminInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${adminInfo.token}`,
        },
      }

      const { data } = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/customers/${id}/vehicles/add`,
        { vehicleNumber, vehicleType },
        config
      )

      dispatch({
        type: ADD_CUSTOMER_VEHICLE_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: ADD_CUSTOMER_VEHICLE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const addCustomerParking =
  (
    id,
    parking,
    name,
    employeeCode,
    email,
    department,
    position,
    parkingType,
    parkingInvite,
    parkingSlot
  ) =>
    async (dispatch, getState) => {
      try {
        dispatch({
          type: ADD_CUSTOMER_PARKING_REQUEST,
        })

        const {
          adminLogin: { adminInfo },
        } = getState()

        const config = {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${adminInfo.token}`,
          },
        }

        const { data } = await axios.put(
          `${process.env.REACT_APP_BASE_URL}/customers/${id}/parkings/add`,
          {
            parking,
            name,
            employeeCode,
            email,
            department,
            position,
            parkingType,
            parkingInvite,
            parkingSlot,
          },
          config
        )

        dispatch({
          type: ADD_CUSTOMER_PARKING_SUCCESS,
          payload: data,
        })
      } catch (error) {
        dispatch({
          type: ADD_CUSTOMER_PARKING_FAIL,
          payload:
            error.response && error.response.data.message
              ? error.response.data.message
              : error.message,
        })
      }
    }



export const editCustomerDetails =
  (
    payload
  ) =>
    async (dispatch, getState) => {
      // const myContext = useContext(AppContext);

      try {

        const {
          adminLogin: { adminInfo },
        } = getState()

        const config = {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${adminInfo.token}`,
          },
        }

        await axios.put(
          `${process.env.REACT_APP_BASE_URL}/customers/employee`,
          payload,
          config
        )

        EventBus.getInstance().fireEvent(globalEvents.refreshData);
        // toast.success("Updating Table");
        toast.success("Details added successfully");
      } catch (error) {
        toast.error(error?.response?.data?.message);
        EventBus.getInstance().fireEvent(globalEvents.updateLoading);

      }
    }

export const deleteCustomer =
  (
    id
  ) =>
    async (dispatch, getState) => {
      // const myContext = useContext(AppContext);

      try {
        const {
          adminLogin: { adminInfo },
        } = getState()

        const config = {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${adminInfo.token}`,
          },
        }

        await axios.delete(
          `${process.env.REACT_APP_BASE_URL}/customers/employee?id=${id}`,
          config
        )

        EventBus.getInstance().fireEvent(globalEvents.refreshData);
        // toast.success("Updating Table");
        toast.success("Deleted successfully");


      } catch (error) {
        // console.log(error);
        toast.error(error.message);
        EventBus.getInstance().fireEvent(globalEvents.updateLoading);

      }
    }
export const editCustomerVehical =
  (
    id,
    vehicleNumber,
    vehicleType
  ) =>
    async (dispatch, getState) => {
      try {
        dispatch({
          type: EDIT_CUSTOMER_VEHICLE_REQUEST,
        })

        const {
          adminLogin: { adminInfo },
        } = getState()

        const config = {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${adminInfo.token}`,
          },
        }

        const { data } = await axios.put(
          `${process.env.REACT_APP_BASE_URL}/customers/${id}/vehicles/edit`,
          {
            id,
            vehicleNumber,
            vehicleType,
          },
          config
        )

        dispatch({
          type: EDIT_CUSTOMER_VEHICLE_SUCCESS,
          payload: data,
        })
      } catch (error) {
        dispatch({
          type: EDIT_CUSTOMER_VEHICLE_FAIL,
          payload:
            error.response && error.response.data.message
              ? error.response.data.message
              : error.message,
        })
      }
    }

export const editCustomerParking =
  (
    id,
    parking,
    name,
    employeeCode,
    email,
    department,
    position,
    parkingType,
    parkingInvite,
    parkingSlot,
    status
  ) =>
    async (dispatch, getState) => {
      try {
        dispatch({
          type: EDIT_CUSTOMER_PARKING_REQUEST,
        })

        const {
          adminLogin: { adminInfo },
        } = getState()

        const config = {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${adminInfo.token}`,
          },
        }

        const { data } = await axios.put(
          `${process.env.REACT_APP_BASE_URL}/customers/${id}/parkings/edit`,
          {
            parking,
            name,
            employeeCode,
            email,
            department,
            position,
            parkingType,
            parkingInvite,
            parkingSlot,
            status,
          },
          config
        )

        dispatch({
          type: EDIT_CUSTOMER_PARKING_SUCCESS,
          payload: data,
        })
      } catch (error) {
        dispatch({
          type: EDIT_CUSTOMER_PARKING_FAIL,
          payload:
            error.response && error.response.data.message
              ? error.response.data.message
              : error.message,
        })
      }
    }

