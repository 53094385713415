import axios from 'axios'
import {
  GET_RATES_REQUEST,
  GET_RATES_SUCCESS,
  GET_RATES_FAIL,
  ADD_RATE_REQUEST,
  ADD_RATE_SUCCESS,
  ADD_RATE_FAIL,
  UPDATE_RATE_REQUEST,
  UPDATE_RATE_SUCCESS,
  UPDATE_RATE_FAIL,
  GET_RATE_BY_ID_REQUEST,
  GET_RATE_BY_ID_SUCCESS,
  GET_RATE_BY_ID_FAIL,
  GET_RATES_FOR_ADMINS_REQUEST,
  GET_RATES_FOR_ADMINS_SUCCESS,
  GET_RATES_FOR_ADMINS_FAIL,
} from '../constants/rateConstants'

export const getRates = (page, name) => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_RATES_REQUEST,
    })

    const {
      adminLogin: { adminInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${adminInfo.token}`,
      },
    }

    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/rates?page=${page}&name=${name}`,
      config
    )

    dispatch({
      type: GET_RATES_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: GET_RATES_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getRateById = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_RATE_BY_ID_REQUEST,
    })

    const {
      adminLogin: { adminInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${adminInfo.token}`,
      },
    }

    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/rates?id=${id}`,
      config
    )

    dispatch({
      type: GET_RATE_BY_ID_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: GET_RATE_BY_ID_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const addRate = (name, rateList) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADD_RATE_REQUEST,
    })

    const {
      adminLogin: { adminInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${adminInfo.token}`,
      },
    }

    const { data } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/rates`,
      { name, rateList },
      config
    )

    dispatch({
      type: ADD_RATE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: ADD_RATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const updateRate = (id, rateList) => async (dispatch, getState) => {
  try {
    dispatch({
      type: UPDATE_RATE_REQUEST,
    })

    const {
      adminLogin: { adminInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${adminInfo.token}`,
      },
    }

    const { data } = await axios.put(
      `${process.env.REACT_APP_BASE_URL}/rates/${id}`,
      { rateList },
      config
    )

    dispatch({
      type: UPDATE_RATE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: UPDATE_RATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getRatesForAdmins = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_RATES_FOR_ADMINS_REQUEST,
    })

    const {
      adminLogin: { adminInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${adminInfo.token}`,
      },
    }

    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/rates/admins`,
      config
    )

    dispatch({
      type: GET_RATES_FOR_ADMINS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: GET_RATES_FOR_ADMINS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
