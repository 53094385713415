import { Box, IconButton, useTheme } from '@mui/material'
import { useContext } from 'react'
import { ColorModeContext } from '../../theme'
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined'
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined'
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined'
import { useDispatch } from 'react-redux'

import { logout } from '../../actions/adminActions'
import { useNavigate } from 'react-router-dom'

const Topbar = () => {
  const theme = useTheme()
  const colorMode = useContext(ColorModeContext)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  return (
    <Box display='flex' justifyContent='flex-end' p={2}>
      {/* ICONS */}
      <Box display='flex'>
        <IconButton onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === 'dark' ? (
            <DarkModeOutlinedIcon />
          ) : (
            <LightModeOutlinedIcon />
          )}
        </IconButton>
        <IconButton onClick={() => dispatch(logout(navigate))}>
          <PersonOutlinedIcon />
        </IconButton>
      </Box>
    </Box>
  )
}

export default Topbar
