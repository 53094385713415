import axios from 'axios'
import {
  DELETE_CHECKINS_FAIL,
  DELETE_CHECKINS_REQUEST,
  DELETE_CHECKINS_SUCCESS,
  DELETE_CHECKIN_FAIL,
  DELETE_CHECKIN_REQUEST,
  DELETE_CHECKIN_SUCCESS,
  DOWNLOAD_CHECKINS_FAIL,
  DOWNLOAD_CHECKINS_REQUEST,
  DOWNLOAD_CHECKINS_SUCCESS,
  FOC_CHECKIN_FAIL,
  FOC_CHECKIN_REQUEST,
  FOC_CHECKIN_SUCCESS,
  GET_CHECKINS_FAIL,
  GET_CHECKINS_REQUEST,
  GET_CHECKINS_SUCCESS,
} from '../constants/checkinConstants'

export const getCheckins =
  (
    page,
    vehicleNumber,
    vehicleType,
    isCheckedOut,
    isWhitelisted,
    isFoc,
    isDelivery,
    from,
    to,
    isHoliday,
    parkings
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: GET_CHECKINS_REQUEST,
      })

      const {
        adminLogin: { adminInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${adminInfo.token}`,
        },
      }

      const { data } = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/checkins?page=${page}&vehicleNumber=${vehicleNumber}&vehicleType=${vehicleType}&isCheckedOut=${isCheckedOut}&isWhitelisted=${isWhitelisted}&isFoc=${isFoc}&isDelivery=${isDelivery}&from=${from}&to=${to}&isHoliday=${isHoliday}&parkings=${parkings}`,
        config
      )

      dispatch({
        type: GET_CHECKINS_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: GET_CHECKINS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const focCheckin = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: FOC_CHECKIN_REQUEST,
    })

    const {
      adminLogin: { adminInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${adminInfo.token}`,
      },
    }

    const { data } = await axios.put(
      `${process.env.REACT_APP_BASE_URL}/checkins/${id}/foc`,
      {},
      config
    )

    dispatch({
      type: FOC_CHECKIN_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: FOC_CHECKIN_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const deleteCheckin = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: DELETE_CHECKIN_REQUEST,
    })

    const {
      adminLogin: { adminInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${adminInfo.token}`,
      },
    }

    const { data } = await axios.put(
      `${process.env.REACT_APP_BASE_URL}/checkins/${id}/delete`,
      {},
      config
    )

    dispatch({
      type: DELETE_CHECKIN_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: DELETE_CHECKIN_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const downloadCheckins =
  (
    vehicleNumber,
    vehicleType,
    isCheckedOut,
    isWhitelisted,
    isFoc,
    isDelivery,
    from,
    to,
    isHoliday,
    parkings
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: DOWNLOAD_CHECKINS_REQUEST,
      })

      const {
        adminLogin: { adminInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${adminInfo.token}`,
        },
      }

      const { data } = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/checkins/download`,
        {
          vehicleNumber,
          vehicleType,
          isCheckedOut,
          isWhitelisted,
          isFoc,
          isDelivery,
          from,
          to,
          isHoliday,
          parkings,
        },
        config
      )

      dispatch({
        type: DOWNLOAD_CHECKINS_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: DOWNLOAD_CHECKINS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const deleteCheckins = (checkins) => async (dispatch, getState) => {
  try {
    dispatch({
      type: DELETE_CHECKINS_REQUEST,
    })

    const {
      adminLogin: { adminInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${adminInfo.token}`,
      },
    }

    const { data } = await axios.put(
      `${process.env.REACT_APP_BASE_URL}/checkins/delete-multiple`,
      { checkins },
      config
    )

    dispatch({
      type: DELETE_CHECKINS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: DELETE_CHECKINS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
