import axios from 'axios'
import {
  ADMIN_LOGIN_REQUEST,
  ADMIN_LOGIN_SUCCESS,
  ADMIN_LOGIN_FAIL,
  ADMIN_LOGOUT,
  GET_ADMINS_REQUEST,
  GET_ADMINS_SUCCESS,
  GET_ADMINS_FAIL,
  EDIT_ADMIN_REQUEST,
  EDIT_ADMIN_SUCCESS,
  EDIT_ADMIN_FAIL,
  GENERATE_PASSWORD_ADMIN_REQUEST,
  GENERATE_PASSWORD_ADMIN_SUCCESS,
  GENERATE_PASSWORD_ADMIN_FAIL,
  ADD_ADMIN_REQUEST,
  ADD_ADMIN_SUCCESS,
  ADD_ADMIN_FAIL,
} from '../constants/adminConstants'

export const login = (email, password) => async (dispatch) => {
  try {
    dispatch({
      type: ADMIN_LOGIN_REQUEST,
    })

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    }

    const { data } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/admins/login`,
      { email, password },
      config
    )

    dispatch({
      type: ADMIN_LOGIN_SUCCESS,
      payload: data,
    })

    localStorage.setItem('adminInfo', JSON.stringify(data))
  } catch (error) {
    dispatch({
      type: ADMIN_LOGIN_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const logout = (navigate) => async (dispatch) => {
  localStorage.removeItem('adminInfo')
  dispatch({
    type: ADMIN_LOGOUT,
  })
  navigate('/')
}

export const getAdmins =
  (page, name, email, role) => async (dispatch, getState) => {
    try {
      dispatch({
        type: GET_ADMINS_REQUEST,
      })

      const {
        adminLogin: { adminInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${adminInfo.token}`,
        },
      }

      const { data } = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/admins?page=${page}&name=${name}&email=${email}&role=${role}`,
        config
      )

      dispatch({
        type: GET_ADMINS_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: GET_ADMINS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const editAdmin =
  (id, name, email, status, locations, parking) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: EDIT_ADMIN_REQUEST,
      })

      const {
        adminLogin: { adminInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${adminInfo.token}`,
        },
      }

      const { data } = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/admins/${id}`,
        { name, email, status, locations, parking },
        config
      )

      dispatch({
        type: EDIT_ADMIN_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: EDIT_ADMIN_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const generatePasswordAdmin = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: GENERATE_PASSWORD_ADMIN_REQUEST,
    })

    const {
      adminLogin: { adminInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${adminInfo.token}`,
      },
    }

    const { data } = await axios.put(
      `${process.env.REACT_APP_BASE_URL}/admins/${id}/generate-password`,
      {},
      config
    )

    dispatch({
      type: GENERATE_PASSWORD_ADMIN_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: GENERATE_PASSWORD_ADMIN_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const addAdmin =
  (name, email, locations, role, parking, type) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: ADD_ADMIN_REQUEST,
      })

      const {
        adminLogin: { adminInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${adminInfo.token}`,
        },
      }

      const { data } = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/admins`,
        { name, email, locations, role, parking, type },
        config
      )

      dispatch({
        type: ADD_ADMIN_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: ADD_ADMIN_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }
