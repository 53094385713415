export const VEHICLES = [
  'car',
  'bike',
  'auto',
  'truck',
  'cycle',
  'tempo',
  'bus',
  'e rickshaw',
  'staff car',
  'staff bike',
  'premium car',
  'premium bike',
  'valet car',
  'challan car',
  'challan bike',
  'taxi',
  'commercial vehicle',
  'tenant car',
  'tenant bike',
]

export const globalEvents = {
  showLoader: 'showLoader',
  hideLoader: 'hideLoader',
  refreshData: 'refreshData',
  SessionExpired: 'SessionExpired',
};