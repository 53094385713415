import {
  DOWNLOAD_PASSES_FAIL,
  DOWNLOAD_PASSES_REQUEST,
  DOWNLOAD_PASSES_RESET,
  DOWNLOAD_PASSES_SUCCESS,
  GET_PASSES_FAIL,
  GET_PASSES_REQUEST,
  GET_PASSES_RESET,
  GET_PASSES_SUCCESS,
  CREATE_PASS_REQUEST,
CREATE_PASS_SUCCESS,
CREATE_PASS_FAIL,
CREATE_PASS_RESET
} from '../constants/passConstants'

export const getPassesReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_PASSES_REQUEST:
      return { loadingGetPasses: true }
    case GET_PASSES_SUCCESS:
      return { loadingGetPasses: false, getPassesData: action.payload }
    case GET_PASSES_FAIL:
      return { loadingGetPasses: false, errorGetPasses: action.payload }
    case GET_PASSES_RESET:
      return {}
    default:
      return state
  }
}
export const addPassReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_PASS_REQUEST:
      return { loadingAddPass: true }
    case CREATE_PASS_SUCCESS:
      return { loadingAddPass: false, addPassData: action.payload }
    case CREATE_PASS_FAIL:
      return { loadingAddPass: false, errorAddPass: action.payload }
    case CREATE_PASS_RESET:
      return {}
    default:
      return state
  }
}

export const downloadPassesReducer = (state = {}, action) => {
  switch (action.type) {
    case DOWNLOAD_PASSES_REQUEST:
      return { loadingDownloadPasses: true }
    case DOWNLOAD_PASSES_SUCCESS:
      return {
        loadingDownloadPasses: false,
        downloadPassesData: action.payload,
      }
    case DOWNLOAD_PASSES_FAIL:
      return {
        loadingDownloadPasses: false,
        errorDownloadPasses: action.payload,
      }
    case DOWNLOAD_PASSES_RESET:
      return {}
    default:
      return state
  }
}
