import {
  Box,
  Button,
  MenuItem,
  Select,
  TextareaAutosize,
  TextField,
  Typography,
  useTheme,
} from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import useMediaQuery from '@mui/material/useMediaQuery'

import { logout } from '../actions/adminActions'
import { useNavigate, useParams } from 'react-router-dom'
import { addRate, getRateById, updateRate } from '../actions/rateActions'
import {
  ADD_RATE_RESET,
  GET_RATE_BY_ID_RESET,
  UPDATE_RATE_RESET,
} from '../constants/rateConstants'
import Header from '../components/Header'
import Loader from '../components/Loader'
import { tokens } from '../theme'

const RateById = () => {
  const isNonMobile = useMediaQuery('(min-width:600px)')
  const theme = useTheme()
  const colors = tokens(theme.palette.mode)

  const [name, setName] = useState('')
  const [rateList, setRateList] = useState([])
  const [car, setCar] = useState({
    type: null,
    rate: null,
    info: null,
  })
  const [subcar, setSubCar] = useState({
    type: null,
    rate: null,
    info: null,
  })
  const [subbike, setSubBike] = useState({
    type: null,
    rate: null,
    info: null,
  })
  const [bike, setBike] = useState({
    type: null,
    rate: null,
    info: null,
  })
  const [cycle, setCycle] = useState({
    type: null,
    rate: null,
    info: null,
  })
  const [tempo, setTempo] = useState({
    type: null,
    rate: null,
    info: null,
  })
  const [truck, setTruck] = useState({
    type: null,
    rate: null,
    info: null,
  })
  const [bus, setBus] = useState({ type: null, rate: null, info: null })
  const [auto, setAuto] = useState({
    type: null,
    rate: null,
    info: null,
  })
  const [staffcar, setStaffCar] = useState({
    type: null,
    rate: null,
    info: null,
  })
  const [premiumcar, setPremiumcar] = useState({
    type: null,
    rate: null,
    info: null,
  })
  const [staffbike, setStaffbike] = useState({
    type: null,
    rate: null,
    info: null,
  })
  const [premiumbike, setPremiumBike] = useState({
    type: null,
    rate: null,
    info: null,
  })
  const [eRickshaw, setErickshaw] = useState({
    type: null,
    rate: null,
    info: null,
  })
  const [valetCar, setValetCar] = useState({
    type: null,
    rate: null,
    info: null,
  })
  const [challanCar, setChallanCar] = useState({
    type: null,
    rate: null,
    info: null,
  })
  const [challanBike, setChallanBike] = useState({
    type: null,
    rate: null,
    info: null,
  })
  const [taxi, setTaxi] = useState({
    type: null,
    rate: null,
    info: null,
  })
  const [commercialVehicle, setCommercialVehicle] = useState({
    type: null,
    rate: null,
    info: null,
  })
  const [tenantCar, setTenantCar] = useState({
    type: null,
    rate: null,
    info: null,
  })
  const [tenantBike, setTenantBike] = useState({
    type: null,
    rate: null,
    info: null,
  })
  const [dataFromServer, setDataFromServer] = useState(null)

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const params = useParams()

  const adminLogin = useSelector((state) => state.adminLogin)
  const { adminInfo } = adminLogin

  useEffect(() => {
    if (!adminInfo) {
      dispatch(logout(navigate))
    }
  }, [adminInfo])

  useEffect(() => {
    if (params?.id && params.id !== 'add') {
      dispatch(getRateById(params.id))
    }
  }, [params?.id])

  const getRateByIdInfo = useSelector((state) => state.getRateByIdInfo)
  const { loadingGetRateById, getRateByIdData, errorGetRateById } =
    getRateByIdInfo

  useEffect(() => {
    dispatch({ type: GET_RATE_BY_ID_RESET })
    if (getRateByIdData) {
      const rate = getRateByIdData.data[0]

      setName(rate?.name)
      setDataFromServer(rate.rateList)
    } else if (errorGetRateById) {
      toast.error(errorGetRateById)
      navigate('/rates')
    }
  }, [getRateByIdData, errorGetRateById])

  useEffect(() => {
    if (dataFromServer) {
      dataFromServer.map((item) => {
        const rates = getRateAndConvert(item)
        item['rate'] = JSON.stringify(rates)

        if (item?.vehicleType === 'car') {
          setCar(item)
        } else if (item?.vehicleType === 'subcar') {
          setSubCar(item)
        }else if (item?.vehicleType === 'subbike') {
          setSubBike(item)
        }else if (item?.vehicleType === 'bike') {
          setBike(item)
        } else if (item?.vehicleType === 'cycle') {
          setCycle(item)
        } else if (item?.vehicleType === 'tempo') {
          setTempo(item)
        } else if (item?.vehicleType === 'truck') {
          setTruck(item)
        } else if (item?.vehicleType === 'bus') {
          setBus(item)
        } else if (item?.vehicleType === 'auto') {
          setAuto(item)
        } else if (item?.vehicleType === 'staff car') {
          setStaffCar(item)
        } else if (item?.vehicleType === 'premium car') {
          setPremiumcar(item)
        } else if (item?.vehicleType === 'staff bike') {
          setStaffbike(item)
        } else if (item?.vehicleType === 'premium bike') {
          setPremiumBike(item)
        } else if (item?.vehicleType === 'e rickshaw') {
          setErickshaw(item)
        } else if (item?.vehicleType === 'valet car') {
          setValetCar(item)
        } else if (item?.vehicleType === 'challan car') {
          setChallanCar(item)
        } else if (item?.vehicleType === 'challan bike') {
          setChallanBike(item)
        } else if (item?.vehicleType === 'taxi') {
          setTaxi(item)
        } else if (item?.vehicleType === 'commercial vehicle') {
          setCommercialVehicle(item)
        } else if (item?.vehicleType === 'tenant car') {
          setTenantCar(item)
        } else if (item?.vehicleType === 'tenant bike') {
          setTenantBike(item)
        }
      })
    }
  }, [dataFromServer])

  // Get rate from server object
  const getRateAndConvert = (doc) => {
    const docRate = {}

    for (const prop in doc) {
      if (
        prop !== 'vehicleType' &&
        prop !== 'type' &&
        prop !== 'night' &&
        prop !== 'holiday' &&
        prop !== 'info' &&
        prop !== 'dailyDayPass' &&
        prop !== 'dailyNightPass' &&
        prop !== 'weeklyDayPass' &&
        prop !== 'weeklyNightPass' &&
        prop !== 'monthlyDayPass' &&
        prop !== 'monthlyNightPass' &&
        prop !== 'passInfo'
      ) {
        docRate[prop] = doc[prop]
      }
    }

    return docRate
  }

  const validate = (vehicleType) => {
    let obj = {}

    let objSelected = {}

    // Essentials check
    if (vehicleType === 'car') {
      objSelected = car
    }  else if (vehicleType === 'subcar') {
      objSelected = subcar
    }else if (vehicleType === 'subbike') {
      objSelected = subbike
    }else if (vehicleType === 'bike') {
      objSelected = bike
    } else if (vehicleType === 'cycle') {
      objSelected = cycle
    } else if (vehicleType === 'tempo') {
      objSelected = tempo
    } else if (vehicleType === 'truck') {
      objSelected = truck
    } else if (vehicleType === 'bus') {
      objSelected = bus
    } else if (vehicleType === 'auto') {
      objSelected = auto
    } else if (vehicleType === 'staff car') {
      objSelected = staffcar
    } else if (vehicleType === 'premium car') {
      objSelected = premiumcar
    } else if (vehicleType === 'staff bike') {
      objSelected = staffbike
    } else if (vehicleType === 'premium bike') {
      objSelected = premiumbike
    } else if (vehicleType === 'e rickshaw') {
      objSelected = eRickshaw
    } else if (vehicleType === 'valet car') {
      objSelected = valetCar
    } else if (vehicleType === 'challan car') {
      objSelected = challanCar
    } else if (vehicleType === 'challan bike') {
      objSelected = challanBike
    } else if (vehicleType === 'taxi') {
      objSelected = taxi
    } else if (vehicleType === 'commercial vehicle') {
      objSelected = commercialVehicle
    } else if (vehicleType === 'tenant car') {
      objSelected = tenantCar
    } else if (vehicleType === 'tenant bike') {
      objSelected = tenantBike
    } else {
      toast.error('Invalid vehicle type selected')
      return false
    }

    if (
      !objSelected ||
      !objSelected.type ||
      !objSelected.rate ||
      !objSelected.info
    ) {
      toast.error('All the fields with * are mandatory')
      return false
    }

    // * JSON validation
    const result = isJsonString(objSelected.rate)
    if (!result) {
      toast.error('Invalid rate, please try again')
      return false
    }

    obj.vehicleType = vehicleType
    obj.type = objSelected.type
    obj.info = objSelected.info
    if (typeof objSelected.night === 'number') obj.night = objSelected.night
    if (typeof objSelected.holiday === 'number')
      obj.holiday = objSelected.holiday
    if (typeof objSelected.dailyDayPass === 'number')
      obj.dailyDayPass = objSelected.dailyDayPass
    if (typeof objSelected.dailyNightPass === 'number')
      obj.dailyNightPass = objSelected.dailyNightPass
    if (typeof objSelected.weeklyDayPass === 'number')
      obj.weeklyDayPass = objSelected.weeklyDayPass
    if (typeof objSelected.weeklyNightPass === 'number')
      obj.weeklyNightPass = objSelected.weeklyNightPass
    if (typeof objSelected.monthlyDayPass === 'number')
      obj.monthlyDayPass = objSelected.monthlyDayPass
    if (typeof objSelected.monthlyNightPass === 'number')
      obj.monthlyNightPass = objSelected.monthlyNightPass
    if (objSelected.passInfo) obj.passInfo = objSelected.passInfo

    const rateToLoop = JSON.parse(objSelected.rate)

    Object.keys(rateToLoop).forEach(function (key) {
      if (typeof rateToLoop[key] === 'number') {
        obj[key] = Number(rateToLoop[key])
      }
    })

    // * Check if this vehicle already exists in rate list arr
    const vehicleFoundInRateList = rateList.filter((item) => {
      return item.vehicleType !== vehicleType
    })

    vehicleFoundInRateList.push(obj)

    setRateList(vehicleFoundInRateList)

    toast.success('Rate validation successfully')
  }

  const isJsonString = (str) => {
    try {
      JSON.parse(str)
    } catch (e) {
      return false
    }
    return true
  }

  const submitHandler = () => {
    if (!name || rateList.length === 0) {
      toast.error('All the fields are mandatory')
      return
    }

    if (params.id === 'add') {
      dispatch(addRate(name, rateList))
    } else {
      dispatch(updateRate(params.id, rateList))
    }
  }

  const addRateInfo = useSelector((state) => state.addRateInfo)
  const { loadingAddRate, addRateData, errorAddRate } = addRateInfo

  useEffect(() => {
    dispatch({ type: ADD_RATE_RESET })
    if (addRateData) {
      toast.success('Rate added successfully')
      navigate('/rates')
    } else if (errorAddRate) {
      toast.error(errorAddRate)
    }
  }, [addRateData, errorAddRate])

  const updateRateInfo = useSelector((state) => state.updateRateInfo)
  const { loadingUpdateRate, updateRateData, errorUpdateRate } = updateRateInfo

  useEffect(() => {
    dispatch({ type: UPDATE_RATE_RESET })
    if (updateRateData) {
      toast.success('Rate updated successfully')
      navigate('/rates')
    } else if (errorUpdateRate) {
      toast.error(errorUpdateRate)
    }
  }, [updateRateData, errorUpdateRate])

  return (
    <Box m='20px'>
      <Header
        title={params?.id === 'add' ? 'Add Rate' : name}
        subtitle='Details of the rate'
      />
      {loadingGetRateById ? (
        <Loader />
      ) : (
        <Box width='100%' m='40px 0 0 0'>
          <Box
            display='flex'
            justifyContent='space-between'
            alignItems='center'
            sx={{ width: '100%' }}
          >
            <Typography
              id='keep-mounted-modal-title'
              variant='h3'
              component='h2'
            >
              Basic Details
            </Typography>
            <Button
              type='submit'
              color='secondary'
              variant='contained'
              sx={{ fontSize: 14 }}
              onClick={submitHandler}
              disabled={
                loadingGetRateById || loadingAddRate || loadingUpdateRate
              }
            >
              {params?.id === 'add' ? 'Add' : 'Update'}
            </Button>
          </Box>
          <Box
            display='grid'
            gap={isNonMobile ? 4 : 2}
            gridTemplateColumns='repeat(4, minmax(0,
        1fr))'
            sx={{
              '& > div': {
                gridColumn: isNonMobile ? undefined : 'span 4',
              },
              marginBottom: 6,
              marginTop: '20px',
            }}
          >
            <Box
              sx={{ gridColumn: 'span 4', marginTop: 2 }}
              display='flex'
              flexDirection={isNonMobile ? 'row' : 'column'}
              justifyContent='space-between'
              gap={isNonMobile ? 4 : 2}
            >
              <TextField
                fullWidth
                variant='filled'
                type='text'
                label='Name'
                onChange={(e) => setName(e.target.value)}
                value={name}
                name='name'
                disabled={
                  loadingGetRateById ||
                  loadingAddRate ||
                  loadingUpdateRate ||
                  params.id !== 'add'
                }
              />
            </Box>
          </Box>
          <Box
            display='flex'
            flexWrap='wrap'
            justifyContent='center'
            flexDirection='row'
            gap={4}
          >
            {/* {VEHICLES.map((vehicle) => ( */}
            <Box
              display='flex'
              flexDirection='column'
              justifyContent='space-between'
              alignItems='center'
              marginBottom={4}
              gap={2}
              sx={{
                backgroundColor: colors.primary[400],
                padding: 4,
                borderRadius: 2,
              }}
            >
              <Typography
                id='keep-mounted-modal-title'
                variant='h3'
                component='h2'
                sx={{ alignSelf: 'flex-start', marginBottom: 2 }}
              >
                Car
              </Typography>
              <Box
                display='flex'
                flexDirection='column'
                flex={1}
                gap={1}
                width='100%'
              >
                <Typography variant='p' component='p'>
                  Type
                </Typography>
                <Select
                  fullWidth
                  variant='filled'
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  value={car && car.type}
                  onChange={(e) => setCar({ ...car, type: e.target.value })}
                >
                  <MenuItem
                    value={'hourly'}
                    selected={car && car.type === 'hourly'}
                  >
                    Hourly
                  </MenuItem>
                  <MenuItem
                    value={'daily'}
                    selected={car && car.type === 'daily'}
                  >
                    Daily
                  </MenuItem>
                </Select>
              </Box>
              <Box
                display='flex'
                flexDirection='column'
                gridColumn='span 4'
                gap={1}
                width='100%'
              >
                <Typography variant='p' component='p'>
                  Rate *
                </Typography>
                <TextareaAutosize
                  minRows={8}
                  value={car && car.rate}
                  onChange={(e) => setCar({ ...car, rate: e.target.value })}
                />
              </Box>
              <Box
                display='flex'
                flexDirection='column'
                gridColumn='span 4'
                gap={1}
                width='100%'
              >
                <Typography variant='p' component='p'>
                  Checkin rate to display on ticket*
                </Typography>
                <TextField
                  fullWidth
                  variant='filled'
                  type='text'
                  name='info'
                  sx={{ gridColumn: 'span 4' }}
                  value={car ? car.info : ''}
                  onChange={(e) => setCar({ ...car, info: e.target.value })}
                />
              </Box>
              <Box
                display='flex'
                flexDirection={isNonMobile ? 'row' : 'column'}
                gridColumn='span 4'
                gap={4}
                marginTop={isNonMobile ? 2 : 1}
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Night Charge
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='night'
                    value={car ? car.night : 0}
                    onChange={(e) =>
                      setCar({ ...car, night: Number(e.target.value) })
                    }
                  />
                </Box>
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Holiday Mode
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='holiday'
                    value={car ? car.holiday : 0}
                    onChange={(e) =>
                      setCar({ ...car, holiday: Number(e.target.value) })
                    }
                  />
                </Box>
              </Box>
              <Box
                display='flex'
                flexDirection={isNonMobile ? 'row' : 'column'}
                gridColumn='span 4'
                gap={4}
                marginTop={isNonMobile ? 2 : 1}
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (daily day)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='dailyDayPass'
                    value={car ? car.dailyDayPass : 0}
                    onChange={(e) =>
                      setCar({ ...car, dailyDayPass: Number(e.target.value) })
                    }
                  />
                </Box>
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (daily night)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='dailyNightPass'
                    value={car ? car.dailyNightPass : 0}
                    onChange={(e) =>
                      setCar({ ...car, dailyNightPass: Number(e.target.value) })
                    }
                  />
                </Box>
              </Box>
              <Box
                display='flex'
                flexDirection={isNonMobile ? 'row' : 'column'}
                gridColumn='span 4'
                gap={4}
                marginTop={isNonMobile ? 2 : 1}
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (weekly day)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='weeklyDayPass'
                    value={car ? car.weeklyDayPass : 0}
                    onChange={(e) =>
                      setCar({ ...car, weeklyDayPass: Number(e.target.value) })
                    }
                  />
                </Box>
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (weekly night)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='weeklyNightPass'
                    value={car ? car.weeklyNightPass : 0}
                    onChange={(e) =>
                      setCar({
                        ...car,
                        weeklyNightPass: Number(e.target.value),
                      })
                    }
                  />
                </Box>
              </Box>
              <Box
                display='flex'
                flexDirection={isNonMobile ? 'row' : 'column'}
                gridColumn='span 4'
                gap={4}
                marginTop={isNonMobile ? 2 : 1}
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (monthly day)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='monthlyDayPass'
                    value={car ? car.monthlyDayPass : 0}
                    onChange={(e) =>
                      setCar({ ...car, monthlyDayPass: Number(e.target.value) })
                    }
                  />
                </Box>
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (monthly night)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='monthlyNightPass'
                    value={car ? car.monthlyNightPass : 0}
                    onChange={(e) =>
                      setCar({
                        ...car,
                        monthlyNightPass: Number(e.target.value),
                      })
                    }
                  />
                </Box>
              </Box>
              <Box
                display='flex'
                flexDirection='column'
                gridColumn='span 4'
                gap={1}
                width='100%'
              >
                <Typography variant='p' component='p'>
                  Pass rate to display on ticket*
                </Typography>
                <TextField
                  fullWidth
                  variant='filled'
                  type='text'
                  name='passInfo'
                  sx={{ gridColumn: 'span 4' }}
                  value={car ? car.passInfo : 0}
                  onChange={(e) => setCar({ ...car, passInfo: e.target.value })}
                />
              </Box>
              <Button
                type='submit'
                color='secondary'
                variant='contained'
                sx={{ fontSize: 14, alignSelf: 'flex-start', marginTop: 2 }}
                onClick={() => validate('car')}
                disabled={
                  loadingGetRateById || loadingAddRate || loadingUpdateRate
                }
              >
                Validate
              </Button>
            </Box>
            <Box
              display='flex'
              flexDirection='column'
              justifyContent='space-between'
              alignItems='center'
              marginBottom={4}
              gap={2}
              sx={{
                backgroundColor: colors.primary[400],
                padding: 4,
                borderRadius: 2,
              }}
            >
              <Typography
                id='keep-mounted-modal-title'
                variant='h3'
                component='h2'
                sx={{ alignSelf: 'flex-start', marginBottom: 2 }}
              >
                Bike
              </Typography>
              <Box
                display='flex'
                flexDirection='column'
                flex={1}
                gap={1}
                width='100%'
              >
                <Typography variant='p' component='p'>
                  Type
                </Typography>
                <Select
                  fullWidth
                  variant='filled'
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  value={bike && bike.type}
                  onChange={(e) => setBike({ ...bike, type: e.target.value })}
                >
                  <MenuItem
                    value={'hourly'}
                    selected={bike && bike.type === 'hourly'}
                  >
                    Hourly
                  </MenuItem>
                  <MenuItem
                    value={'daily'}
                    selected={bike && bike.type === 'daily'}
                  >
                    Daily
                  </MenuItem>
                </Select>
              </Box>
              <Box
                display='flex'
                flexDirection='column'
                gridColumn='span 4'
                gap={1}
                width='100%'
              >
                <Typography variant='p' component='p'>
                  Rate *
                </Typography>
                <TextareaAutosize
                  minRows={8}
                  value={bike && bike.rate}
                  onChange={(e) => setBike({ ...bike, rate: e.target.value })}
                />
              </Box>
              <Box
                display='flex'
                flexDirection='column'
                gridColumn='span 4'
                gap={1}
                width='100%'
              >
                <Typography variant='p' component='p'>
                  Checkin rate to display on ticket*
                </Typography>
                <TextField
                  fullWidth
                  variant='filled'
                  type='text'
                  name='info'
                  sx={{ gridColumn: 'span 4' }}
                  value={bike ? bike.info : ''}
                  onChange={(e) => setBike({ ...bike, info: e.target.value })}
                />
              </Box>
              <Box
                display='flex'
                flexDirection={isNonMobile ? 'row' : 'column'}
                gridColumn='span 4'
                gap={4}
                marginTop={isNonMobile ? 2 : 1}
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Night Charge
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='night'
                    value={bike?.night}
                    onChange={(e) =>
                      setBike({ ...bike, night: Number(e.target.value) })
                    }
                  />
                </Box>
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Holiday Mode
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='holiday'
                    value={bike ? bike.holiday : 0}
                    onChange={(e) =>
                      setBike({ ...bike, holiday: Number(e.target.value) })
                    }
                  />
                </Box>
              </Box>
              <Box
                display='flex'
                flexDirection={isNonMobile ? 'row' : 'column'}
                gridColumn='span 4'
                gap={4}
                marginTop={isNonMobile ? 2 : 1}
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (daily day)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='dailyDayPass'
                    value={bike ? bike.dailyDayPass : 0}
                    onChange={(e) =>
                      setBike({ ...bike, dailyDayPass: Number(e.target.value) })
                    }
                  />
                </Box>
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (daily night)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='dailyNightPass'
                    value={bike ? bike.dailyNightPass : 0}
                    onChange={(e) =>
                      setBike({
                        ...bike,
                        dailyNightPass: Number(e.target.value),
                      })
                    }
                  />
                </Box>
              </Box>
              <Box
                display='flex'
                flexDirection={isNonMobile ? 'row' : 'column'}
                gridColumn='span 4'
                gap={4}
                marginTop={isNonMobile ? 2 : 1}
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (weekly day)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='weeklyDayPass'
                    value={bike ? bike.weeklyDayPass : 0}
                    onChange={(e) =>
                      setBike({
                        ...bike,
                        weeklyDayPass: Number(e.target.value),
                      })
                    }
                  />
                </Box>
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (weekly night)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='weeklyNightPass'
                    value={bike ? bike.weeklyNightPass : 0}
                    onChange={(e) =>
                      setBike({
                        ...bike,
                        weeklyNightPass: Number(e.target.value),
                      })
                    }
                  />
                </Box>
              </Box>
              <Box
                display='flex'
                flexDirection={isNonMobile ? 'row' : 'column'}
                gridColumn='span 4'
                gap={4}
                marginTop={isNonMobile ? 2 : 1}
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (monthly day)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='monthlyDayPass'
                    value={bike ? bike.monthlyDayPass : 0}
                    onChange={(e) =>
                      setBike({
                        ...bike,
                        monthlyDayPass: Number(e.target.value),
                      })
                    }
                  />
                </Box>
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (monthly night)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='monthlyNightPass'
                    value={bike ? bike.monthlyNightPass : 0}
                    onChange={(e) =>
                      setBike({
                        ...bike,
                        monthlyNightPass: Number(e.target.value),
                      })
                    }
                  />
                </Box>
              </Box>
              <Box
                display='flex'
                flexDirection='column'
                gridColumn='span 4'
                gap={1}
                width='100%'
              >
                <Typography variant='p' component='p'>
                  Pass rate to display on ticket*
                </Typography>
                <TextField
                  fullWidth
                  variant='filled'
                  type='text'
                  name='passInfo'
                  sx={{ gridColumn: 'span 4' }}
                  value={bike ? bike.passInfo : 0}
                  onChange={(e) =>
                    setBike({ ...bike, passInfo: e.target.value })
                  }
                />
              </Box>
              <Button
                type='submit'
                color='secondary'
                variant='contained'
                sx={{ fontSize: 14, alignSelf: 'flex-start', marginTop: 2 }}
                onClick={() => validate('bike')}
                disabled={
                  loadingGetRateById || loadingAddRate || loadingUpdateRate
                }
              >
                Validate
              </Button>
            </Box>
            <Box
              display='flex'
              flexDirection='column'
              justifyContent='space-between'
              alignItems='center'
              marginBottom={4}
              gap={2}
              sx={{
                backgroundColor: colors.primary[400],
                padding: 4,
                borderRadius: 2,
              }}
            >
              <Typography
                id='keep-mounted-modal-title'
                variant='h3'
                component='h2'
                sx={{ alignSelf: 'flex-start', marginBottom: 2 }}
              >
                Sub Car
              </Typography>
              <Box
                display='flex'
                flexDirection='column'
                flex={1}
                gap={1}
                width='100%'
              >
                <Typography variant='p' component='p'>
                  Type
                </Typography>
                <Select
                  fullWidth
                  variant='filled'
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  value={subcar && subcar.type}
                  onChange={(e) => setSubCar({ ...subcar, type: e.target.value })}
                >
                  <MenuItem
                    value={'hourly'}
                    selected={subcar && subcar.type === 'hourly'}
                  >
                    Hourly
                  </MenuItem>
                  <MenuItem
                    value={'daily'}
                    selected={subcar && subcar.type === 'daily'}
                  >
                    Daily
                  </MenuItem>
                </Select>
              </Box>
              <Box
                display='flex'
                flexDirection='column'
                gridColumn='span 4'
                gap={1}
                width='100%'
              >
                <Typography variant='p' component='p'>
                  Rate *
                </Typography>
                <TextareaAutosize
                  minRows={8}
                  value={subcar && subcar.rate}
                  onChange={(e) => setSubCar({ ...subcar, rate: e.target.value })}
                />
              </Box>
              <Box
                display='flex'
                flexDirection='column'
                gridColumn='span 4'
                gap={1}
                width='100%'
              >
                <Typography variant='p' component='p'>
                  Checkin rate to display on ticket*
                </Typography>
                <TextField
                  fullWidth
                  variant='filled'
                  type='text'
                  name='info'
                  sx={{ gridColumn: 'span 4' }}
                  value={subcar ? subcar.info : ''}
                  onChange={(e) => setSubCar({ ...subcar, info: e.target.value })}
                />
              </Box>
              <Box
                display='flex'
                flexDirection={isNonMobile ? 'row' : 'column'}
                gridColumn='span 4'
                gap={4}
                marginTop={isNonMobile ? 2 : 1}
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Night Charge
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='night'
                    value={subcar ? subcar.night : 0}
                    onChange={(e) =>
                      setSubCar({ ...subcar, night: Number(e.target.value) })
                    }
                  />
                </Box>
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Holiday Mode
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='holiday'
                    value={subcar ? subcar.holiday : 0}
                    onChange={(e) =>
                      setSubCar({ ...subcar, holiday: Number(e.target.value) })
                    }
                  />
                </Box>
              </Box>
              <Box
                display='flex'
                flexDirection={isNonMobile ? 'row' : 'column'}
                gridColumn='span 4'
                gap={4}
                marginTop={isNonMobile ? 2 : 1}
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (daily day)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='dailyDayPass'
                    value={subcar ? subcar.dailyDayPass : 0}
                    onChange={(e) =>
                      setSubCar({ ...subcar, dailyDayPass: Number(e.target.value) })
                    }
                  />
                </Box>
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (daily night)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='dailyNightPass'
                    value={subcar ? subcar.dailyNightPass : 0}
                    onChange={(e) =>
                      setSubCar({ ...subcar, dailyNightPass: Number(e.target.value) })
                    }
                  />
                </Box>
              </Box>
              <Box
                display='flex'
                flexDirection={isNonMobile ? 'row' : 'column'}
                gridColumn='span 4'
                gap={4}
                marginTop={isNonMobile ? 2 : 1}
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (weekly day)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='weeklyDayPass'
                    value={subcar ? subcar.weeklyDayPass : 0}
                    onChange={(e) =>
                      setSubCar({ ...subcar, weeklyDayPass: Number(e.target.value) })
                    }
                  />
                </Box>
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (weekly night)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='weeklyNightPass'
                    value={subcar ? subcar.weeklyNightPass : 0}
                    onChange={(e) =>
                      setSubCar({
                        ...subcar,
                        weeklyNightPass: Number(e.target.value),
                      })
                    }
                  />
                </Box>
              </Box>
              <Box
                display='flex'
                flexDirection={isNonMobile ? 'row' : 'column'}
                gridColumn='span 4'
                gap={4}
                marginTop={isNonMobile ? 2 : 1}
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (monthly day)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='monthlyDayPass'
                    value={subcar ? subcar.monthlyDayPass : 0}
                    onChange={(e) =>
                      setSubCar({ ...subcar, monthlyDayPass: Number(e.target.value) })
                    }
                  />
                </Box>
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (monthly night)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='monthlyNightPass'
                    value={subcar ? subcar.monthlyNightPass : 0}
                    onChange={(e) =>
                      setSubCar({
                        ...subcar,
                        monthlyNightPass: Number(e.target.value),
                      })
                    }
                  />
                </Box>
              </Box>
              <Box
                display='flex'
                flexDirection='column'
                gridColumn='span 4'
                gap={1}
                width='100%'
              >
                <Typography variant='p' component='p'>
                  Pass rate to display on ticket*
                </Typography>
                <TextField
                  fullWidth
                  variant='filled'
                  type='text'
                  name='passInfo'
                  sx={{ gridColumn: 'span 4' }}
                  value={subcar ? subcar.passInfo : 0}
                  onChange={(e) => setSubCar({ ...subcar, passInfo: e.target.value })}
                />
              </Box>
              <Button
                type='submit'
                color='secondary'
                variant='contained'
                sx={{ fontSize: 14, alignSelf: 'flex-start', marginTop: 2 }}
                onClick={() => validate('subcar')}
                disabled={
                  loadingGetRateById || loadingAddRate || loadingUpdateRate
                }
              >
                Validate
              </Button>
            </Box>

            <Box
              display='flex'
              flexDirection='column'
              justifyContent='space-between'
              alignItems='center'
              marginBottom={4}
              gap={2}
              sx={{
                backgroundColor: colors.primary[400],
                padding: 4,
                borderRadius: 2,
              }}
            >
              <Typography
                id='keep-mounted-modal-title'
                variant='h3'
                component='h2'
                sx={{ alignSelf: 'flex-start', marginBottom: 2 }}
              >
                Sub Bike
              </Typography>
              <Box
                display='flex'
                flexDirection='column'
                flex={1}
                gap={1}
                width='100%'
              >
                <Typography variant='p' component='p'>
                  Type
                </Typography>
                <Select
                  fullWidth
                  variant='filled'
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  value={subbike && subbike.type}
                  onChange={(e) => setSubBike({ ...subbike, type: e.target.value })}
                >
                  <MenuItem
                    value={'hourly'}
                    selected={subbike && subbike.type === 'hourly'}
                  >
                    Hourly
                  </MenuItem>
                  <MenuItem
                    value={'daily'}
                    selected={subbike && subbike.type === 'daily'}
                  >
                    Daily
                  </MenuItem>
                </Select>
              </Box>
              <Box
                display='flex'
                flexDirection='column'
                gridColumn='span 4'
                gap={1}
                width='100%'
              >
                <Typography variant='p' component='p'>
                  Rate *
                </Typography>
                <TextareaAutosize
                  minRows={8}
                  value={subbike && subbike.rate}
                  onChange={(e) => setSubBike({ ...subbike, rate: e.target.value })}
                />
              </Box>
              <Box
                display='flex'
                flexDirection='column'
                gridColumn='span 4'
                gap={1}
                width='100%'
              >
                <Typography variant='p' component='p'>
                  Checkin rate to display on ticket*
                </Typography>
                <TextField
                  fullWidth
                  variant='filled'
                  type='text'
                  name='info'
                  sx={{ gridColumn: 'span 4' }}
                  value={subbike ? subbike.info : ''}
                  onChange={(e) => setSubBike({ ...subbike, info: e.target.value })}
                />
              </Box>
              <Box
                display='flex'
                flexDirection={isNonMobile ? 'row' : 'column'}
                gridColumn='span 4'
                gap={4}
                marginTop={isNonMobile ? 2 : 1}
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Night Charge
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='night'
                    value={subbike?.night}
                    onChange={(e) =>
                      setSubBike({ ...subbike, night: Number(e.target.value) })
                    }
                  />
                </Box>
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Holiday Mode
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='holiday'
                    value={subbike ? subbike.holiday : 0}
                    onChange={(e) =>
                      setSubBike({ ...subbike, holiday: Number(e.target.value) })
                    }
                  />
                </Box>
              </Box>
              <Box
                display='flex'
                flexDirection={isNonMobile ? 'row' : 'column'}
                gridColumn='span 4'
                gap={4}
                marginTop={isNonMobile ? 2 : 1}
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (daily day)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='dailyDayPass'
                    value={subbike ? subbike.dailyDayPass : 0}
                    onChange={(e) =>
                      setSubBike({ ...subbike, dailyDayPass: Number(e.target.value) })
                    }
                  />
                </Box>
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (daily night)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='dailyNightPass'
                    value={subbike ? subbike.dailyNightPass : 0}
                    onChange={(e) =>
                      setSubBike({
                        ...subbike,
                        dailyNightPass: Number(e.target.value),
                      })
                    }
                  />
                </Box>
              </Box>
              <Box
                display='flex'
                flexDirection={isNonMobile ? 'row' : 'column'}
                gridColumn='span 4'
                gap={4}
                marginTop={isNonMobile ? 2 : 1}
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (weekly day)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='weeklyDayPass'
                    value={subbike ? subbike.weeklyDayPass : 0}
                    onChange={(e) =>
                      setSubBike({
                        ...subbike,
                        weeklyDayPass: Number(e.target.value),
                      })
                    }
                  />
                </Box>
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (weekly night)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='weeklyNightPass'
                    value={subbike ? subbike.weeklyNightPass : 0}
                    onChange={(e) =>
                      setSubBike({
                        ...subbike,
                        weeklyNightPass: Number(e.target.value),
                      })
                    }
                  />
                </Box>
              </Box>
              <Box
                display='flex'
                flexDirection={isNonMobile ? 'row' : 'column'}
                gridColumn='span 4'
                gap={4}
                marginTop={isNonMobile ? 2 : 1}
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (monthly day)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='monthlyDayPass'
                    value={subbike ? subbike.monthlyDayPass : 0}
                    onChange={(e) =>
                      setSubBike({
                        ...subbike,
                        monthlyDayPass: Number(e.target.value),
                      })
                    }
                  />
                </Box>
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (monthly night)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='monthlyNightPass'
                    value={subbike ? subbike.monthlyNightPass : 0}
                    onChange={(e) =>
                      setSubBike({
                        ...subbike,
                        monthlyNightPass: Number(e.target.value),
                      })
                    }
                  />
                </Box>
              </Box>
              <Box
                display='flex'
                flexDirection='column'
                gridColumn='span 4'
                gap={1}
                width='100%'
              >
                <Typography variant='p' component='p'>
                  Pass rate to display on ticket*
                </Typography>
                <TextField
                  fullWidth
                  variant='filled'
                  type='text'
                  name='passInfo'
                  sx={{ gridColumn: 'span 4' }}
                  value={subbike ? subbike.passInfo : 0}
                  onChange={(e) =>
                    setSubBike({ ...subbike, passInfo: e.target.value })
                  }
                />
              </Box>
              <Button
                type='submit'
                color='secondary'
                variant='contained'
                sx={{ fontSize: 14, alignSelf: 'flex-start', marginTop: 2 }}
                onClick={() => validate('subbike')}
                disabled={
                  loadingGetRateById || loadingAddRate || loadingUpdateRate
                }
              >
                Validate
              </Button>
            </Box>

            
            <Box
              display='flex'
              flexDirection='column'
              justifyContent='space-between'
              alignItems='center'
              marginBottom={4}
              gap={2}
              sx={{
                backgroundColor: colors.primary[400],
                padding: 4,
                borderRadius: 2,
              }}
            >
              <Typography
                id='keep-mounted-modal-title'
                variant='h3'
                component='h2'
                sx={{ alignSelf: 'flex-start', marginBottom: 2 }}
              >
                Cycle
              </Typography>
              <Box
                display='flex'
                flexDirection='column'
                flex={1}
                gap={1}
                width='100%'
              >
                <Typography variant='p' component='p'>
                  Type
                </Typography>
                <Select
                  fullWidth
                  variant='filled'
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  value={cycle && cycle.type}
                  onChange={(e) => setCycle({ ...cycle, type: e.target.value })}
                >
                  <MenuItem
                    value={'hourly'}
                    selected={cycle && cycle.type === 'hourly'}
                  >
                    Hourly
                  </MenuItem>
                  <MenuItem
                    value={'daily'}
                    selected={cycle && cycle.type === 'daily'}
                  >
                    Daily
                  </MenuItem>
                </Select>
              </Box>
              <Box
                display='flex'
                flexDirection='column'
                gridColumn='span 4'
                gap={1}
                width='100%'
              >
                <Typography variant='p' component='p'>
                  Rate *
                </Typography>
                <TextareaAutosize
                  minRows={8}
                  value={cycle && cycle.rate}
                  onChange={(e) => setCycle({ ...cycle, rate: e.target.value })}
                />
              </Box>
              <Box
                display='flex'
                flexDirection='column'
                gridColumn='span 4'
                gap={1}
                width='100%'
              >
                <Typography variant='p' component='p'>
                  Checkin rate to display on ticket*
                </Typography>
                <TextField
                  fullWidth
                  variant='filled'
                  type='text'
                  name='info'
                  sx={{ gridColumn: 'span 4' }}
                  value={cycle ? cycle.info : ''}
                  onChange={(e) => setCycle({ ...cycle, info: e.target.value })}
                />
              </Box>
              <Box
                display='flex'
                flexDirection={isNonMobile ? 'row' : 'column'}
                gridColumn='span 4'
                gap={4}
                marginTop={isNonMobile ? 2 : 1}
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Night Charge
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='night'
                    value={cycle ? cycle.night : 0}
                    onChange={(e) =>
                      setCycle({ ...cycle, night: Number(e.target.value) })
                    }
                  />
                </Box>
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Holiday Mode
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='holiday'
                    value={cycle ? cycle.holiday : 0}
                    onChange={(e) =>
                      setCycle({ ...cycle, holiday: Number(e.target.value) })
                    }
                  />
                </Box>
              </Box>
              <Box
                display='flex'
                flexDirection={isNonMobile ? 'row' : 'column'}
                gridColumn='span 4'
                gap={4}
                marginTop={isNonMobile ? 2 : 1}
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (daily day)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='dailyDayPass'
                    value={cycle ? cycle.dailyDayPass : 0}
                    onChange={(e) =>
                      setCycle({
                        ...cycle,
                        dailyDayPass: Number(e.target.value),
                      })
                    }
                  />
                </Box>
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (daily night)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='dailyNightPass'
                    value={cycle ? cycle.dailyNightPass : 0}
                    onChange={(e) =>
                      setCycle({
                        ...cycle,
                        dailyNightPass: Number(e.target.value),
                      })
                    }
                  />
                </Box>
              </Box>
              <Box
                display='flex'
                flexDirection={isNonMobile ? 'row' : 'column'}
                gridColumn='span 4'
                gap={4}
                marginTop={isNonMobile ? 2 : 1}
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (weekly day)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='weeklyDayPass'
                    value={cycle ? cycle.weeklyDayPass : 0}
                    onChange={(e) =>
                      setCycle({
                        ...cycle,
                        weeklyDayPass: Number(e.target.value),
                      })
                    }
                  />
                </Box>
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (weekly night)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='weeklyNightPass'
                    value={cycle ? cycle.weeklyNightPass : 0}
                    onChange={(e) =>
                      setCycle({
                        ...cycle,
                        weeklyNightPass: Number(e.target.value),
                      })
                    }
                  />
                </Box>
              </Box>
              <Box
                display='flex'
                flexDirection={isNonMobile ? 'row' : 'column'}
                gridColumn='span 4'
                gap={4}
                marginTop={isNonMobile ? 2 : 1}
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (monthly day)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='monthlyDayPass'
                    value={cycle ? cycle.monthlyDayPass : 0}
                    onChange={(e) =>
                      setCycle({
                        ...cycle,
                        monthlyDayPass: Number(e.target.value),
                      })
                    }
                  />
                </Box>
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (monthly night)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='monthlyNightPass'
                    value={cycle ? cycle.monthlyNightPass : 0}
                    onChange={(e) =>
                      setCycle({
                        ...cycle,
                        monthlyNightPass: Number(e.target.value),
                      })
                    }
                  />
                </Box>
              </Box>
              <Box
                display='flex'
                flexDirection='column'
                gridColumn='span 4'
                gap={1}
                width='100%'
              >
                <Typography variant='p' component='p'>
                  Pass rate to display on ticket*
                </Typography>
                <TextField
                  fullWidth
                  variant='filled'
                  type='text'
                  name='passInfo'
                  sx={{ gridColumn: 'span 4' }}
                  value={cycle ? cycle.passInfo : 0}
                  onChange={(e) =>
                    setCycle({ ...cycle, passInfo: e.target.value })
                  }
                />
              </Box>
              <Button
                type='submit'
                color='secondary'
                variant='contained'
                sx={{ fontSize: 14, alignSelf: 'flex-start', marginTop: 2 }}
                onClick={() => validate('cycle')}
                disabled={
                  loadingGetRateById || loadingAddRate || loadingUpdateRate
                }
              >
                Validate
              </Button>
            </Box>
            <Box
              display='flex'
              flexDirection='column'
              justifyContent='space-between'
              alignItems='center'
              marginBottom={4}
              gap={2}
              sx={{
                backgroundColor: colors.primary[400],
                padding: 4,
                borderRadius: 2,
              }}
            >
              <Typography
                id='keep-mounted-modal-title'
                variant='h3'
                component='h2'
                sx={{ alignSelf: 'flex-start', marginBottom: 2 }}
              >
                Tempo
              </Typography>
              <Box
                display='flex'
                flexDirection='column'
                flex={1}
                gap={1}
                width='100%'
              >
                <Typography variant='p' component='p'>
                  Type
                </Typography>
                <Select
                  fullWidth
                  variant='filled'
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  value={tempo && tempo.type}
                  onChange={(e) => setTempo({ ...tempo, type: e.target.value })}
                >
                  <MenuItem
                    value={'hourly'}
                    selected={tempo && tempo.type === 'hourly'}
                  >
                    Hourly
                  </MenuItem>
                  <MenuItem
                    value={'daily'}
                    selected={tempo && tempo.type === 'daily'}
                  >
                    Daily
                  </MenuItem>
                </Select>
              </Box>
              <Box
                display='flex'
                flexDirection='column'
                gridColumn='span 4'
                gap={1}
                width='100%'
              >
                <Typography variant='p' component='p'>
                  Rate *
                </Typography>
                <TextareaAutosize
                  minRows={8}
                  value={tempo && tempo.rate}
                  onChange={(e) => setTempo({ ...tempo, rate: e.target.value })}
                />
              </Box>
              <Box
                display='flex'
                flexDirection='column'
                gridColumn='span 4'
                gap={1}
                width='100%'
              >
                <Typography variant='p' component='p'>
                  Checkin rate to display on ticket*
                </Typography>
                <TextField
                  fullWidth
                  variant='filled'
                  type='text'
                  name='info'
                  sx={{ gridColumn: 'span 4' }}
                  value={tempo ? tempo.info : ''}
                  onChange={(e) => setTempo({ ...tempo, info: e.target.value })}
                />
              </Box>
              <Box
                display='flex'
                flexDirection={isNonMobile ? 'row' : 'column'}
                gridColumn='span 4'
                gap={4}
                marginTop={isNonMobile ? 2 : 1}
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Night Charge
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='night'
                    value={tempo ? tempo.night : 0}
                    onChange={(e) =>
                      setTempo({ ...tempo, night: Number(e.target.value) })
                    }
                  />
                </Box>
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Holiday Mode
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='holiday'
                    value={tempo ? tempo.holiday : 0}
                    onChange={(e) =>
                      setTempo({ ...tempo, holiday: Number(e.target.value) })
                    }
                  />
                </Box>
              </Box>
              <Box
                display='flex'
                flexDirection={isNonMobile ? 'row' : 'column'}
                gridColumn='span 4'
                gap={4}
                marginTop={isNonMobile ? 2 : 1}
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (daily day)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='dailyDayPass'
                    value={tempo ? tempo.dailyDayPass : 0}
                    onChange={(e) =>
                      setTempo({
                        ...tempo,
                        dailyDayPass: Number(e.target.value),
                      })
                    }
                  />
                </Box>
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (daily night)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='dailyNightPass'
                    value={tempo ? tempo.dailyNightPass : 0}
                    onChange={(e) =>
                      setTempo({
                        ...tempo,
                        dailyNightPass: Number(e.target.value),
                      })
                    }
                  />
                </Box>
              </Box>
              <Box
                display='flex'
                flexDirection={isNonMobile ? 'row' : 'column'}
                gridColumn='span 4'
                gap={4}
                marginTop={isNonMobile ? 2 : 1}
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (weekly day)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='weeklyDayPass'
                    value={tempo ? tempo.weeklyDayPass : 0}
                    onChange={(e) =>
                      setTempo({
                        ...tempo,
                        weeklyDayPass: Number(e.target.value),
                      })
                    }
                  />
                </Box>
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (weekly night)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='weeklyNightPass'
                    value={tempo ? tempo.weeklyNightPass : 0}
                    onChange={(e) =>
                      setTempo({
                        ...tempo,
                        weeklyNightPass: Number(e.target.value),
                      })
                    }
                  />
                </Box>
              </Box>
              <Box
                display='flex'
                flexDirection={isNonMobile ? 'row' : 'column'}
                gridColumn='span 4'
                gap={4}
                marginTop={isNonMobile ? 2 : 1}
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (monthly day)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='monthlyDayPass'
                    value={tempo ? tempo.monthlyDayPass : 0}
                    onChange={(e) =>
                      setTempo({
                        ...tempo,
                        monthlyDayPass: Number(e.target.value),
                      })
                    }
                  />
                </Box>
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (monthly night)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='monthlyNightPass'
                    value={tempo ? tempo.monthlyNightPass : 0}
                    onChange={(e) =>
                      setTempo({
                        ...tempo,
                        monthlyNightPass: Number(e.target.value),
                      })
                    }
                  />
                </Box>
              </Box>
              <Box
                display='flex'
                flexDirection='column'
                gridColumn='span 4'
                gap={1}
                width='100%'
              >
                <Typography variant='p' component='p'>
                  Pass rate to display on ticket*
                </Typography>
                <TextField
                  fullWidth
                  variant='filled'
                  type='text'
                  name='passInfo'
                  sx={{ gridColumn: 'span 4' }}
                  value={tempo ? tempo.passInfo : 0}
                  onChange={(e) =>
                    setTempo({ ...tempo, passInfo: e.target.value })
                  }
                />
              </Box>
              <Button
                type='submit'
                color='secondary'
                variant='contained'
                sx={{ fontSize: 14, alignSelf: 'flex-start', marginTop: 2 }}
                onClick={() => validate('tempo')}
                disabled={
                  loadingGetRateById || loadingAddRate || loadingUpdateRate
                }
              >
                Validate
              </Button>
            </Box>
            <Box
              display='flex'
              flexDirection='column'
              justifyContent='space-between'
              alignItems='center'
              marginBottom={4}
              gap={2}
              sx={{
                backgroundColor: colors.primary[400],
                padding: 4,
                borderRadius: 2,
              }}
            >
              <Typography
                id='keep-mounted-modal-title'
                variant='h3'
                component='h2'
                sx={{ alignSelf: 'flex-start', marginBottom: 2 }}
              >
                Truck
              </Typography>
              <Box
                display='flex'
                flexDirection='column'
                flex={1}
                gap={1}
                width='100%'
              >
                <Typography variant='p' component='p'>
                  Type
                </Typography>
                <Select
                  fullWidth
                  variant='filled'
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  value={truck && truck.type}
                  onChange={(e) => setTruck({ ...truck, type: e.target.value })}
                >
                  <MenuItem
                    value={'hourly'}
                    selected={truck && truck.type === 'hourly'}
                  >
                    Hourly
                  </MenuItem>
                  <MenuItem
                    value={'daily'}
                    selected={truck && truck.type === 'daily'}
                  >
                    Daily
                  </MenuItem>
                </Select>
              </Box>
              <Box
                display='flex'
                flexDirection='column'
                gridColumn='span 4'
                gap={1}
                width='100%'
              >
                <Typography variant='p' component='p'>
                  Rate *
                </Typography>
                <TextareaAutosize
                  minRows={8}
                  value={truck && truck.rate}
                  onChange={(e) => setTruck({ ...truck, rate: e.target.value })}
                />
              </Box>
              <Box
                display='flex'
                flexDirection='column'
                gridColumn='span 4'
                gap={1}
                width='100%'
              >
                <Typography variant='p' component='p'>
                  Checkin rate to display on ticket*
                </Typography>
                <TextField
                  fullWidth
                  variant='filled'
                  type='text'
                  name='info'
                  sx={{ gridColumn: 'span 4' }}
                  value={truck ? truck.info : ''}
                  onChange={(e) => setTruck({ ...truck, info: e.target.value })}
                />
              </Box>
              <Box
                display='flex'
                flexDirection={isNonMobile ? 'row' : 'column'}
                gridColumn='span 4'
                gap={4}
                marginTop={isNonMobile ? 2 : 1}
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Night Charge
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='night'
                    value={truck ? truck.night : 0}
                    onChange={(e) =>
                      setTruck({ ...truck, night: Number(e.target.value) })
                    }
                  />
                </Box>
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Holiday Mode
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='holiday'
                    value={truck ? truck.holiday : 0}
                    onChange={(e) =>
                      setTruck({ ...truck, holiday: Number(e.target.value) })
                    }
                  />
                </Box>
              </Box>
              <Box
                display='flex'
                flexDirection={isNonMobile ? 'row' : 'column'}
                gridColumn='span 4'
                gap={4}
                marginTop={isNonMobile ? 2 : 1}
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (daily day)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='dailyDayPass'
                    value={truck ? truck.dailyDayPass : 0}
                    onChange={(e) =>
                      setTruck({
                        ...truck,
                        dailyDayPass: Number(e.target.value),
                      })
                    }
                  />
                </Box>
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (daily night)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='dailyNightPass'
                    value={truck ? truck.dailyNightPass : 0}
                    onChange={(e) =>
                      setTruck({
                        ...truck,
                        dailyNightPass: Number(e.target.value),
                      })
                    }
                  />
                </Box>
              </Box>
              <Box
                display='flex'
                flexDirection={isNonMobile ? 'row' : 'column'}
                gridColumn='span 4'
                gap={4}
                marginTop={isNonMobile ? 2 : 1}
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (weekly day)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='weeklyDayPass'
                    value={truck ? truck.weeklyDayPass : 0}
                    onChange={(e) =>
                      setTruck({
                        ...truck,
                        weeklyDayPass: Number(e.target.value),
                      })
                    }
                  />
                </Box>
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (weekly night)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='weeklyNightPass'
                    value={truck ? truck.weeklyNightPass : 0}
                    onChange={(e) =>
                      setTruck({
                        ...truck,
                        weeklyNightPass: Number(e.target.value),
                      })
                    }
                  />
                </Box>
              </Box>
              <Box
                display='flex'
                flexDirection={isNonMobile ? 'row' : 'column'}
                gridColumn='span 4'
                gap={4}
                marginTop={isNonMobile ? 2 : 1}
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (monthly day)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='monthlyDayPass'
                    value={truck ? truck.monthlyDayPass : 0}
                    onChange={(e) =>
                      setTruck({
                        ...truck,
                        monthlyDayPass: Number(e.target.value),
                      })
                    }
                  />
                </Box>
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (monthly night)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='monthlyNightPass'
                    value={truck ? truck.monthlyNightPass : 0}
                    onChange={(e) =>
                      setTruck({
                        ...truck,
                        monthlyNightPass: Number(e.target.value),
                      })
                    }
                  />
                </Box>
              </Box>
              <Box
                display='flex'
                flexDirection='column'
                gridColumn='span 4'
                gap={1}
                width='100%'
              >
                <Typography variant='p' component='p'>
                  Pass rate to display on ticket*
                </Typography>
                <TextField
                  fullWidth
                  variant='filled'
                  type='text'
                  name='passInfo'
                  sx={{ gridColumn: 'span 4' }}
                  value={truck ? truck.passInfo : 0}
                  onChange={(e) =>
                    setTruck({ ...truck, passInfo: e.target.value })
                  }
                />
              </Box>
              <Button
                type='submit'
                color='secondary'
                variant='contained'
                sx={{ fontSize: 14, alignSelf: 'flex-start', marginTop: 2 }}
                onClick={() => validate('truck')}
                disabled={
                  loadingGetRateById || loadingAddRate || loadingUpdateRate
                }
              >
                Validate
              </Button>
            </Box>
            <Box
              display='flex'
              flexDirection='column'
              justifyContent='space-between'
              alignItems='center'
              marginBottom={4}
              gap={2}
              sx={{
                backgroundColor: colors.primary[400],
                padding: 4,
                borderRadius: 2,
              }}
            >
              <Typography
                id='keep-mounted-modal-title'
                variant='h3'
                component='h2'
                sx={{ alignSelf: 'flex-start', marginBottom: 2 }}
              >
                Bus
              </Typography>
              <Box
                display='flex'
                flexDirection='column'
                flex={1}
                gap={1}
                width='100%'
              >
                <Typography variant='p' component='p'>
                  Type
                </Typography>
                <Select
                  fullWidth
                  variant='filled'
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  value={bus && bus.type}
                  onChange={(e) => setBus({ ...bus, type: e.target.value })}
                >
                  <MenuItem
                    value={'hourly'}
                    selected={bus && bus.type === 'hourly'}
                  >
                    Hourly
                  </MenuItem>
                  <MenuItem
                    value={'daily'}
                    selected={bus && bus.type === 'daily'}
                  >
                    Daily
                  </MenuItem>
                </Select>
              </Box>
              <Box
                display='flex'
                flexDirection='column'
                gridColumn='span 4'
                gap={1}
                width='100%'
              >
                <Typography variant='p' component='p'>
                  Rate *
                </Typography>
                <TextareaAutosize
                  minRows={8}
                  value={bus && bus.rate}
                  onChange={(e) => setBus({ ...bus, rate: e.target.value })}
                />
              </Box>
              <Box
                display='flex'
                flexDirection='column'
                gridColumn='span 4'
                gap={1}
                width='100%'
              >
                <Typography variant='p' component='p'>
                  Checkin rate to display on ticket*
                </Typography>
                <TextField
                  fullWidth
                  variant='filled'
                  type='text'
                  name='info'
                  sx={{ gridColumn: 'span 4' }}
                  value={bus ? bus.info : ''}
                  onChange={(e) => setBus({ ...bus, info: e.target.value })}
                />
              </Box>
              <Box
                display='flex'
                flexDirection={isNonMobile ? 'row' : 'column'}
                gridColumn='span 4'
                gap={4}
                marginTop={isNonMobile ? 2 : 1}
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Night Charge
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='night'
                    value={bus ? bus.night : 0}
                    onChange={(e) =>
                      setBus({ ...bus, night: Number(e.target.value) })
                    }
                  />
                </Box>
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Holiday Mode
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='holiday'
                    value={bus ? bus.holiday : 0}
                    onChange={(e) =>
                      setBus({ ...bus, holiday: Number(e.target.value) })
                    }
                  />
                </Box>
              </Box>
              <Box
                display='flex'
                flexDirection={isNonMobile ? 'row' : 'column'}
                gridColumn='span 4'
                gap={4}
                marginTop={isNonMobile ? 2 : 1}
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (daily day)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='dailyDayPass'
                    value={bus ? bus.dailyDayPass : 0}
                    onChange={(e) =>
                      setBus({ ...bus, dailyDayPass: Number(e.target.value) })
                    }
                  />
                </Box>
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (daily night)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='dailyNightPass'
                    value={bus ? bus.dailyNightPass : 0}
                    onChange={(e) =>
                      setBus({ ...bus, dailyNightPass: Number(e.target.value) })
                    }
                  />
                </Box>
              </Box>
              <Box
                display='flex'
                flexDirection={isNonMobile ? 'row' : 'column'}
                gridColumn='span 4'
                gap={4}
                marginTop={isNonMobile ? 2 : 1}
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (weekly day)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='weeklyDayPass'
                    value={bus ? bus.weeklyDayPass : 0}
                    onChange={(e) =>
                      setBus({ ...bus, weeklyDayPass: Number(e.target.value) })
                    }
                  />
                </Box>
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (weekly night)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='weeklyNightPass'
                    value={bus ? bus.weeklyNightPass : 0}
                    onChange={(e) =>
                      setBus({
                        ...bus,
                        weeklyNightPass: Number(e.target.value),
                      })
                    }
                  />
                </Box>
              </Box>
              <Box
                display='flex'
                flexDirection={isNonMobile ? 'row' : 'column'}
                gridColumn='span 4'
                gap={4}
                marginTop={isNonMobile ? 2 : 1}
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (monthly day)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='monthlyDayPass'
                    value={bus ? bus.monthlyDayPass : 0}
                    onChange={(e) =>
                      setBus({ ...bus, monthlyDayPass: Number(e.target.value) })
                    }
                  />
                </Box>
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (monthly night)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='monthlyNightPass'
                    value={bus ? bus.monthlyNightPass : 0}
                    onChange={(e) =>
                      setBus({
                        ...bus,
                        monthlyNightPass: Number(e.target.value),
                      })
                    }
                  />
                </Box>
              </Box>
              <Box
                display='flex'
                flexDirection='column'
                gridColumn='span 4'
                gap={1}
                width='100%'
              >
                <Typography variant='p' component='p'>
                  Pass rate to display on ticket*
                </Typography>
                <TextField
                  fullWidth
                  variant='filled'
                  type='text'
                  name='passInfo'
                  sx={{ gridColumn: 'span 4' }}
                  value={bus ? bus.passInfo : 0}
                  onChange={(e) => setBus({ ...bus, passInfo: e.target.value })}
                />
              </Box>
              <Button
                type='submit'
                color='secondary'
                variant='contained'
                sx={{ fontSize: 14, alignSelf: 'flex-start', marginTop: 2 }}
                onClick={() => validate('bus')}
                disabled={
                  loadingGetRateById || loadingAddRate || loadingUpdateRate
                }
              >
                Validate
              </Button>
            </Box>
            <Box
              display='flex'
              flexDirection='column'
              justifyContent='space-between'
              alignItems='center'
              marginBottom={4}
              gap={2}
              sx={{
                backgroundColor: colors.primary[400],
                padding: 4,
                borderRadius: 2,
              }}
            >
              <Typography
                id='keep-mounted-modal-title'
                variant='h3'
                component='h2'
                sx={{ alignSelf: 'flex-start', marginBottom: 2 }}
              >
                Auto
              </Typography>
              <Box
                display='flex'
                flexDirection='column'
                flex={1}
                gap={1}
                width='100%'
              >
                <Typography variant='p' component='p'>
                  Type
                </Typography>
                <Select
                  fullWidth
                  variant='filled'
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  value={auto && auto.type}
                  onChange={(e) => setAuto({ ...auto, type: e.target.value })}
                >
                  <MenuItem
                    value={'hourly'}
                    selected={auto && auto.type === 'hourly'}
                  >
                    Hourly
                  </MenuItem>
                  <MenuItem
                    value={'daily'}
                    selected={auto && auto.type === 'daily'}
                  >
                    Daily
                  </MenuItem>
                </Select>
              </Box>
              <Box
                display='flex'
                flexDirection='column'
                gridColumn='span 4'
                gap={1}
                width='100%'
              >
                <Typography variant='p' component='p'>
                  Rate *
                </Typography>
                <TextareaAutosize
                  minRows={8}
                  value={auto && auto.rate}
                  onChange={(e) => setAuto({ ...auto, rate: e.target.value })}
                />
              </Box>
              <Box
                display='flex'
                flexDirection='column'
                gridColumn='span 4'
                gap={1}
                width='100%'
              >
                <Typography variant='p' component='p'>
                  Checkin rate to display on ticket*
                </Typography>
                <TextField
                  fullWidth
                  variant='filled'
                  type='text'
                  name='info'
                  sx={{ gridColumn: 'span 4' }}
                  value={auto ? auto.info : ''}
                  onChange={(e) => setAuto({ ...auto, info: e.target.value })}
                />
              </Box>
              <Box
                display='flex'
                flexDirection={isNonMobile ? 'row' : 'column'}
                gridColumn='span 4'
                gap={4}
                marginTop={isNonMobile ? 2 : 1}
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Night Charge
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='night'
                    value={auto ? auto.night : 0}
                    onChange={(e) =>
                      setAuto({ ...auto, night: Number(e.target.value) })
                    }
                  />
                </Box>
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Holiday Mode
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='holiday'
                    value={auto ? auto.holiday : 0}
                    onChange={(e) =>
                      setAuto({ ...auto, holiday: Number(e.target.value) })
                    }
                  />
                </Box>
              </Box>
              <Box
                display='flex'
                flexDirection={isNonMobile ? 'row' : 'column'}
                gridColumn='span 4'
                gap={4}
                marginTop={isNonMobile ? 2 : 1}
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (daily day)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='dailyDayPass'
                    value={auto ? auto.dailyDayPass : 0}
                    onChange={(e) =>
                      setAuto({ ...auto, dailyDayPass: Number(e.target.value) })
                    }
                  />
                </Box>
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (daily night)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='dailyNightPass'
                    value={auto ? auto.dailyNightPass : 0}
                    onChange={(e) =>
                      setAuto({
                        ...auto,
                        dailyNightPass: Number(e.target.value),
                      })
                    }
                  />
                </Box>
              </Box>
              <Box
                display='flex'
                flexDirection={isNonMobile ? 'row' : 'column'}
                gridColumn='span 4'
                gap={4}
                marginTop={isNonMobile ? 2 : 1}
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (weekly day)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='weeklyDayPass'
                    value={auto ? auto.weeklyDayPass : 0}
                    onChange={(e) =>
                      setAuto({
                        ...auto,
                        weeklyDayPass: Number(e.target.value),
                      })
                    }
                  />
                </Box>
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (weekly night)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='weeklyNightPass'
                    value={auto ? auto.weeklyNightPass : 0}
                    onChange={(e) =>
                      setAuto({
                        ...auto,
                        weeklyNightPass: Number(e.target.value),
                      })
                    }
                  />
                </Box>
              </Box>
              <Box
                display='flex'
                flexDirection={isNonMobile ? 'row' : 'column'}
                gridColumn='span 4'
                gap={4}
                marginTop={isNonMobile ? 2 : 1}
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (monthly day)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='monthlyDayPass'
                    value={auto ? auto.monthlyDayPass : 0}
                    onChange={(e) =>
                      setAuto({
                        ...auto,
                        monthlyDayPass: Number(e.target.value),
                      })
                    }
                  />
                </Box>
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (monthly night)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='monthlyNightPass'
                    value={auto ? auto.monthlyNightPass : 0}
                    onChange={(e) =>
                      setAuto({
                        ...auto,
                        monthlyNightPass: Number(e.target.value),
                      })
                    }
                  />
                </Box>
              </Box>
              <Box
                display='flex'
                flexDirection='column'
                gridColumn='span 4'
                gap={1}
                width='100%'
              >
                <Typography variant='p' component='p'>
                  Pass rate to display on ticket*
                </Typography>
                <TextField
                  fullWidth
                  variant='filled'
                  type='text'
                  name='passInfo'
                  sx={{ gridColumn: 'span 4' }}
                  value={auto ? auto.passInfo : 0}
                  onChange={(e) =>
                    setAuto({ ...auto, passInfo: e.target.value })
                  }
                />
              </Box>
              <Button
                type='submit'
                color='secondary'
                variant='contained'
                sx={{ fontSize: 14, alignSelf: 'flex-start', marginTop: 2 }}
                onClick={() => validate('auto')}
                disabled={
                  loadingGetRateById || loadingAddRate || loadingUpdateRate
                }
              >
                Validate
              </Button>
            </Box>
            <Box
              display='flex'
              flexDirection='column'
              justifyContent='space-between'
              alignItems='center'
              marginBottom={4}
              gap={2}
              sx={{
                backgroundColor: colors.primary[400],
                padding: 4,
                borderRadius: 2,
              }}
            >
              <Typography
                id='keep-mounted-modal-title'
                variant='h3'
                component='h2'
                sx={{ alignSelf: 'flex-start', marginBottom: 2 }}
              >
                E Rickshaw
              </Typography>
              <Box
                display='flex'
                flexDirection='column'
                flex={1}
                gap={1}
                width='100%'
              >
                <Typography variant='p' component='p'>
                  Type
                </Typography>
                <Select
                  fullWidth
                  variant='filled'
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  value={eRickshaw && eRickshaw.type}
                  onChange={(e) =>
                    setErickshaw({ ...eRickshaw, type: e.target.value })
                  }
                >
                  <MenuItem
                    value={'hourly'}
                    selected={eRickshaw && eRickshaw.type === 'hourly'}
                  >
                    Hourly
                  </MenuItem>
                  <MenuItem
                    value={'daily'}
                    selected={eRickshaw && eRickshaw.type === 'daily'}
                  >
                    Daily
                  </MenuItem>
                </Select>
              </Box>
              <Box
                display='flex'
                flexDirection='column'
                gridColumn='span 4'
                gap={1}
                width='100%'
              >
                <Typography variant='p' component='p'>
                  Rate *
                </Typography>
                <TextareaAutosize
                  minRows={8}
                  value={eRickshaw && eRickshaw.rate}
                  onChange={(e) =>
                    setErickshaw({ ...eRickshaw, rate: e.target.value })
                  }
                />
              </Box>
              <Box
                display='flex'
                flexDirection='column'
                gridColumn='span 4'
                gap={1}
                width='100%'
              >
                <Typography variant='p' component='p'>
                  Checkin rate to display on ticket*
                </Typography>
                <TextField
                  fullWidth
                  variant='filled'
                  type='text'
                  name='info'
                  sx={{ gridColumn: 'span 4' }}
                  value={eRickshaw ? eRickshaw.info : ''}
                  onChange={(e) =>
                    setErickshaw({ ...eRickshaw, info: e.target.value })
                  }
                />
              </Box>
              <Box
                display='flex'
                flexDirection={isNonMobile ? 'row' : 'column'}
                gridColumn='span 4'
                gap={4}
                marginTop={isNonMobile ? 2 : 1}
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Night Charge
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='night'
                    value={eRickshaw ? eRickshaw.night : 0}
                    onChange={(e) =>
                      setErickshaw({
                        ...eRickshaw,
                        night: Number(e.target.value),
                      })
                    }
                  />
                </Box>
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Holiday Mode
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='holiday'
                    value={eRickshaw ? eRickshaw.holiday : 0}
                    onChange={(e) =>
                      setErickshaw({
                        ...eRickshaw,
                        holiday: Number(e.target.value),
                      })
                    }
                  />
                </Box>
              </Box>
              <Box
                display='flex'
                flexDirection={isNonMobile ? 'row' : 'column'}
                gridColumn='span 4'
                gap={4}
                marginTop={isNonMobile ? 2 : 1}
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (daily day)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='dailyDayPass'
                    value={eRickshaw ? eRickshaw.dailyDayPass : 0}
                    onChange={(e) =>
                      setErickshaw({
                        ...eRickshaw,
                        dailyDayPass: Number(e.target.value),
                      })
                    }
                  />
                </Box>
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (daily night)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='dailyNightPass'
                    value={eRickshaw ? eRickshaw.dailyNightPass : 0}
                    onChange={(e) =>
                      setErickshaw({
                        ...eRickshaw,
                        dailyNightPass: Number(e.target.value),
                      })
                    }
                  />
                </Box>
              </Box>
              <Box
                display='flex'
                flexDirection={isNonMobile ? 'row' : 'column'}
                gridColumn='span 4'
                gap={4}
                marginTop={isNonMobile ? 2 : 1}
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (weekly day)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='weeklyDayPass'
                    value={eRickshaw ? eRickshaw.weeklyDayPass : 0}
                    onChange={(e) =>
                      setErickshaw({
                        ...eRickshaw,
                        weeklyDayPass: Number(e.target.value),
                      })
                    }
                  />
                </Box>
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (weekly night)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='weeklyNightPass'
                    value={eRickshaw ? eRickshaw.weeklyNightPass : 0}
                    onChange={(e) =>
                      setErickshaw({
                        ...eRickshaw,
                        weeklyNightPass: Number(e.target.value),
                      })
                    }
                  />
                </Box>
              </Box>
              <Box
                display='flex'
                flexDirection={isNonMobile ? 'row' : 'column'}
                gridColumn='span 4'
                gap={4}
                marginTop={isNonMobile ? 2 : 1}
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (monthly day)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='monthlyDayPass'
                    value={eRickshaw ? eRickshaw.monthlyDayPass : 0}
                    onChange={(e) =>
                      setErickshaw({
                        ...eRickshaw,
                        monthlyDayPass: Number(e.target.value),
                      })
                    }
                  />
                </Box>
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (monthly night)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='monthlyNightPass'
                    value={eRickshaw ? eRickshaw.monthlyNightPass : 0}
                    onChange={(e) =>
                      setErickshaw({
                        ...eRickshaw,
                        monthlyNightPass: Number(e.target.value),
                      })
                    }
                  />
                </Box>
              </Box>
              <Box
                display='flex'
                flexDirection='column'
                gridColumn='span 4'
                gap={1}
                width='100%'
              >
                <Typography variant='p' component='p'>
                  Pass rate to display on ticket*
                </Typography>
                <TextField
                  fullWidth
                  variant='filled'
                  type='text'
                  name='passInfo'
                  sx={{ gridColumn: 'span 4' }}
                  value={eRickshaw ? eRickshaw.passInfo : 0}
                  onChange={(e) =>
                    setErickshaw({ ...eRickshaw, passInfo: e.target.value })
                  }
                />
              </Box>
              <Button
                type='submit'
                color='secondary'
                variant='contained'
                sx={{ fontSize: 14, alignSelf: 'flex-start', marginTop: 2 }}
                onClick={() => validate('e rickshaw')}
                disabled={
                  loadingGetRateById || loadingAddRate || loadingUpdateRate
                }
              >
                Validate
              </Button>
            </Box>
            <Box
              display='flex'
              flexDirection='column'
              justifyContent='space-between'
              alignItems='center'
              marginBottom={4}
              gap={2}
              sx={{
                backgroundColor: colors.primary[400],
                padding: 4,
                borderRadius: 2,
              }}
            >
              <Typography
                id='keep-mounted-modal-title'
                variant='h3'
                component='h2'
                sx={{ alignSelf: 'flex-start', marginBottom: 2 }}
              >
                Staff Car
              </Typography>
              <Box
                display='flex'
                flexDirection='column'
                flex={1}
                gap={1}
                width='100%'
              >
                <Typography variant='p' component='p'>
                  Type
                </Typography>
                <Select
                  fullWidth
                  variant='filled'
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  value={staffcar && staffcar.type}
                  onChange={(e) =>
                    setStaffCar({ ...staffcar, type: e.target.value })
                  }
                >
                  <MenuItem
                    value={'hourly'}
                    selected={staffcar && staffcar.type === 'hourly'}
                  >
                    Hourly
                  </MenuItem>
                  <MenuItem
                    value={'daily'}
                    selected={staffcar && staffcar.type === 'daily'}
                  >
                    Daily
                  </MenuItem>
                </Select>
              </Box>
              <Box
                display='flex'
                flexDirection='column'
                gridColumn='span 4'
                gap={1}
                width='100%'
              >
                <Typography variant='p' component='p'>
                  Rate *
                </Typography>
                <TextareaAutosize
                  minRows={8}
                  value={staffcar && staffcar.rate}
                  onChange={(e) =>
                    setStaffCar({ ...staffcar, rate: e.target.value })
                  }
                />
              </Box>
              <Box
                display='flex'
                flexDirection='column'
                gridColumn='span 4'
                gap={1}
                width='100%'
              >
                <Typography variant='p' component='p'>
                  Checkin rate to display on ticket*
                </Typography>
                <TextField
                  fullWidth
                  variant='filled'
                  type='text'
                  name='info'
                  sx={{ gridColumn: 'span 4' }}
                  value={staffcar ? staffcar.info : ''}
                  onChange={(e) =>
                    setStaffCar({ ...staffcar, info: e.target.value })
                  }
                />
              </Box>
              <Box
                display='flex'
                flexDirection={isNonMobile ? 'row' : 'column'}
                gridColumn='span 4'
                gap={4}
                marginTop={isNonMobile ? 2 : 1}
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Night Charge
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='night'
                    value={staffcar ? staffcar.night : 0}
                    onChange={(e) =>
                      setStaffCar({
                        ...staffcar,
                        night: Number(e.target.value),
                      })
                    }
                  />
                </Box>
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Holiday Mode
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='holiday'
                    value={staffcar ? staffcar.holiday : 0}
                    onChange={(e) =>
                      setStaffCar({
                        ...staffcar,
                        holiday: Number(e.target.value),
                      })
                    }
                  />
                </Box>
              </Box>
              <Box
                display='flex'
                flexDirection={isNonMobile ? 'row' : 'column'}
                gridColumn='span 4'
                gap={4}
                marginTop={isNonMobile ? 2 : 1}
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (daily day)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='dailyDayPass'
                    value={staffcar ? staffcar.dailyDayPass : 0}
                    onChange={(e) =>
                      setStaffCar({
                        ...staffcar,
                        dailyDayPass: Number(e.target.value),
                      })
                    }
                  />
                </Box>
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (daily night)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='dailyNightPass'
                    value={staffcar ? staffcar.dailyNightPass : 0}
                    onChange={(e) =>
                      setStaffCar({
                        ...staffcar,
                        dailyNightPass: Number(e.target.value),
                      })
                    }
                  />
                </Box>
              </Box>
              <Box
                display='flex'
                flexDirection={isNonMobile ? 'row' : 'column'}
                gridColumn='span 4'
                gap={4}
                marginTop={isNonMobile ? 2 : 1}
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (weekly day)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='weeklyDayPass'
                    value={staffcar ? staffcar.weeklyDayPass : 0}
                    onChange={(e) =>
                      setStaffCar({
                        ...staffcar,
                        weeklyDayPass: Number(e.target.value),
                      })
                    }
                  />
                </Box>
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (weekly night)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='weeklyNightPass'
                    value={staffcar ? staffcar.weeklyNightPass : 0}
                    onChange={(e) =>
                      setStaffCar({
                        ...staffcar,
                        weeklyNightPass: Number(e.target.value),
                      })
                    }
                  />
                </Box>
              </Box>
              <Box
                display='flex'
                flexDirection={isNonMobile ? 'row' : 'column'}
                gridColumn='span 4'
                gap={4}
                marginTop={isNonMobile ? 2 : 1}
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (monthly day)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='monthlyDayPass'
                    value={staffcar ? staffcar.monthlyDayPass : 0}
                    onChange={(e) =>
                      setStaffCar({
                        ...staffcar,
                        monthlyDayPass: Number(e.target.value),
                      })
                    }
                  />
                </Box>
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (monthly night)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='monthlyNightPass'
                    value={staffcar ? staffcar.monthlyNightPass : 0}
                    onChange={(e) =>
                      setStaffCar({
                        ...staffcar,
                        monthlyNightPass: Number(e.target.value),
                      })
                    }
                  />
                </Box>
              </Box>
              <Box
                display='flex'
                flexDirection='column'
                gridColumn='span 4'
                gap={1}
                width='100%'
              >
                <Typography variant='p' component='p'>
                  Pass rate to display on ticket*
                </Typography>
                <TextField
                  fullWidth
                  variant='filled'
                  type='text'
                  name='passInfo'
                  sx={{ gridColumn: 'span 4' }}
                  value={staffcar ? staffcar.passInfo : 0}
                  onChange={(e) =>
                    setStaffCar({ ...staffcar, passInfo: e.target.value })
                  }
                />
              </Box>
              <Button
                type='submit'
                color='secondary'
                variant='contained'
                sx={{ fontSize: 14, alignSelf: 'flex-start', marginTop: 2 }}
                onClick={() => validate('staff car')}
                disabled={
                  loadingGetRateById || loadingAddRate || loadingUpdateRate
                }
              >
                Validate
              </Button>
            </Box>
            <Box
              display='flex'
              flexDirection='column'
              justifyContent='space-between'
              alignItems='center'
              marginBottom={4}
              gap={2}
              sx={{
                backgroundColor: colors.primary[400],
                padding: 4,
                borderRadius: 2,
              }}
            >
              <Typography
                id='keep-mounted-modal-title'
                variant='h3'
                component='h2'
                sx={{ alignSelf: 'flex-start', marginBottom: 2 }}
              >
                Premium Car
              </Typography>
              <Box
                display='flex'
                flexDirection='column'
                flex={1}
                gap={1}
                width='100%'
              >
                <Typography variant='p' component='p'>
                  Type
                </Typography>
                <Select
                  fullWidth
                  variant='filled'
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  value={premiumcar && premiumcar.type}
                  onChange={(e) =>
                    setPremiumcar({ ...premiumcar, type: e.target.value })
                  }
                >
                  <MenuItem
                    value={'hourly'}
                    selected={premiumcar && premiumcar.type === 'hourly'}
                  >
                    Hourly
                  </MenuItem>
                  <MenuItem
                    value={'daily'}
                    selected={premiumcar && premiumcar.type === 'daily'}
                  >
                    Daily
                  </MenuItem>
                </Select>
              </Box>
              <Box
                display='flex'
                flexDirection='column'
                gridColumn='span 4'
                gap={1}
                width='100%'
              >
                <Typography variant='p' component='p'>
                  Rate *
                </Typography>
                <TextareaAutosize
                  minRows={8}
                  value={premiumcar && premiumcar.rate}
                  onChange={(e) =>
                    setPremiumcar({ ...premiumcar, rate: e.target.value })
                  }
                />
              </Box>
              <Box
                display='flex'
                flexDirection='column'
                gridColumn='span 4'
                gap={1}
                width='100%'
              >
                <Typography variant='p' component='p'>
                  Checkin rate to display on ticket*
                </Typography>
                <TextField
                  fullWidth
                  variant='filled'
                  type='text'
                  name='info'
                  sx={{ gridColumn: 'span 4' }}
                  value={premiumcar ? premiumcar.info : ''}
                  onChange={(e) =>
                    setPremiumcar({ ...premiumcar, info: e.target.value })
                  }
                />
              </Box>
              <Box
                display='flex'
                flexDirection={isNonMobile ? 'row' : 'column'}
                gridColumn='span 4'
                gap={4}
                marginTop={isNonMobile ? 2 : 1}
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Night Charge
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='night'
                    value={premiumcar ? premiumcar.night : 0}
                    onChange={(e) =>
                      setPremiumcar({
                        ...premiumcar,
                        night: Number(e.target.value),
                      })
                    }
                  />
                </Box>
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Holiday Mode
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='holiday'
                    value={premiumcar ? premiumcar.holiday : 0}
                    onChange={(e) =>
                      setPremiumcar({
                        ...premiumcar,
                        holiday: Number(e.target.value),
                      })
                    }
                  />
                </Box>
              </Box>
              <Box
                display='flex'
                flexDirection={isNonMobile ? 'row' : 'column'}
                gridColumn='span 4'
                gap={4}
                marginTop={isNonMobile ? 2 : 1}
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (daily day)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='dailyDayPass'
                    value={premiumcar ? premiumcar.dailyDayPass : 0}
                    onChange={(e) =>
                      setPremiumcar({
                        ...premiumcar,
                        dailyDayPass: Number(e.target.value),
                      })
                    }
                  />
                </Box>
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (daily night)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='dailyNightPass'
                    value={premiumcar ? premiumcar.dailyNightPass : 0}
                    onChange={(e) =>
                      setPremiumcar({
                        ...premiumcar,
                        dailyNightPass: Number(e.target.value),
                      })
                    }
                  />
                </Box>
              </Box>
              <Box
                display='flex'
                flexDirection={isNonMobile ? 'row' : 'column'}
                gridColumn='span 4'
                gap={4}
                marginTop={isNonMobile ? 2 : 1}
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (weekly day)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='weeklyDayPass'
                    value={premiumcar ? premiumcar.weeklyDayPass : 0}
                    onChange={(e) =>
                      setPremiumcar({
                        ...premiumcar,
                        weeklyDayPass: Number(e.target.value),
                      })
                    }
                  />
                </Box>
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (weekly night)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='weeklyNightPass'
                    value={premiumcar ? premiumcar.weeklyNightPass : 0}
                    onChange={(e) =>
                      setPremiumcar({
                        ...premiumcar,
                        weeklyNightPass: Number(e.target.value),
                      })
                    }
                  />
                </Box>
              </Box>
              <Box
                display='flex'
                flexDirection={isNonMobile ? 'row' : 'column'}
                gridColumn='span 4'
                gap={4}
                marginTop={isNonMobile ? 2 : 1}
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (monthly day)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='monthlyDayPass'
                    value={premiumcar ? premiumcar.monthlyDayPass : 0}
                    onChange={(e) =>
                      setPremiumcar({
                        ...premiumcar,
                        monthlyDayPass: Number(e.target.value),
                      })
                    }
                  />
                </Box>
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (monthly night)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='monthlyNightPass'
                    value={premiumcar ? premiumcar.monthlyNightPass : 0}
                    onChange={(e) =>
                      setPremiumcar({
                        ...premiumcar,
                        monthlyNightPass: Number(e.target.value),
                      })
                    }
                  />
                </Box>
              </Box>
              <Box
                display='flex'
                flexDirection='column'
                gridColumn='span 4'
                gap={1}
                width='100%'
              >
                <Typography variant='p' component='p'>
                  Pass rate to display on ticket*
                </Typography>
                <TextField
                  fullWidth
                  variant='filled'
                  type='text'
                  name='passInfo'
                  sx={{ gridColumn: 'span 4' }}
                  value={premiumcar ? premiumcar.passInfo : 0}
                  onChange={(e) =>
                    setPremiumcar({ ...premiumcar, passInfo: e.target.value })
                  }
                />
              </Box>
              <Button
                type='submit'
                color='secondary'
                variant='contained'
                sx={{ fontSize: 14, alignSelf: 'flex-start', marginTop: 2 }}
                onClick={() => validate('premium car')}
                disabled={
                  loadingGetRateById || loadingAddRate || loadingUpdateRate
                }
              >
                Validate
              </Button>
            </Box>
            <Box
              display='flex'
              flexDirection='column'
              justifyContent='space-between'
              alignItems='center'
              marginBottom={4}
              gap={2}
              sx={{
                backgroundColor: colors.primary[400],
                padding: 4,
                borderRadius: 2,
              }}
            >
              <Typography
                id='keep-mounted-modal-title'
                variant='h3'
                component='h2'
                sx={{ alignSelf: 'flex-start', marginBottom: 2 }}
              >
                Staff Bike
              </Typography>
              <Box
                display='flex'
                flexDirection='column'
                flex={1}
                gap={1}
                width='100%'
              >
                <Typography variant='p' component='p'>
                  Type
                </Typography>
                <Select
                  fullWidth
                  variant='filled'
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  value={staffbike && staffbike.type}
                  onChange={(e) =>
                    setStaffbike({ ...staffbike, type: e.target.value })
                  }
                >
                  <MenuItem
                    value={'hourly'}
                    selected={staffbike && staffbike.type === 'hourly'}
                  >
                    Hourly
                  </MenuItem>
                  <MenuItem
                    value={'daily'}
                    selected={staffbike && staffbike.type === 'daily'}
                  >
                    Daily
                  </MenuItem>
                </Select>
              </Box>
              <Box
                display='flex'
                flexDirection='column'
                gridColumn='span 4'
                gap={1}
                width='100%'
              >
                <Typography variant='p' component='p'>
                  Rate *
                </Typography>
                <TextareaAutosize
                  minRows={8}
                  value={staffbike && staffbike.rate}
                  onChange={(e) =>
                    setStaffbike({ ...staffbike, rate: e.target.value })
                  }
                />
              </Box>
              <Box
                display='flex'
                flexDirection='column'
                gridColumn='span 4'
                gap={1}
                width='100%'
              >
                <Typography variant='p' component='p'>
                  Checkin rate to display on ticket*
                </Typography>
                <TextField
                  fullWidth
                  variant='filled'
                  type='text'
                  name='info'
                  sx={{ gridColumn: 'span 4' }}
                  value={staffbike ? staffbike.info : ''}
                  onChange={(e) =>
                    setStaffbike({ ...staffbike, info: e.target.value })
                  }
                />
              </Box>
              <Box
                display='flex'
                flexDirection={isNonMobile ? 'row' : 'column'}
                gridColumn='span 4'
                gap={4}
                marginTop={isNonMobile ? 2 : 1}
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Night Charge
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='night'
                    value={staffbike ? staffbike.night : 0}
                    onChange={(e) =>
                      setStaffbike({
                        ...staffbike,
                        night: Number(e.target.value),
                      })
                    }
                  />
                </Box>
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Holiday Mode
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='holiday'
                    value={staffbike ? staffbike.holiday : 0}
                    onChange={(e) =>
                      setStaffbike({
                        ...staffbike,
                        holiday: Number(e.target.value),
                      })
                    }
                  />
                </Box>
              </Box>
              <Box
                display='flex'
                flexDirection={isNonMobile ? 'row' : 'column'}
                gridColumn='span 4'
                gap={4}
                marginTop={isNonMobile ? 2 : 1}
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (daily day)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='dailyDayPass'
                    value={staffbike ? staffbike.dailyDayPass : 0}
                    onChange={(e) =>
                      setStaffbike({
                        ...staffbike,
                        dailyDayPass: Number(e.target.value),
                      })
                    }
                  />
                </Box>
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (daily night)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='dailyNightPass'
                    value={staffbike ? staffbike.dailyNightPass : 0}
                    onChange={(e) =>
                      setStaffbike({
                        ...staffbike,
                        dailyNightPass: Number(e.target.value),
                      })
                    }
                  />
                </Box>
              </Box>
              <Box
                display='flex'
                flexDirection={isNonMobile ? 'row' : 'column'}
                gridColumn='span 4'
                gap={4}
                marginTop={isNonMobile ? 2 : 1}
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (weekly day)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='weeklyDayPass'
                    value={staffbike ? staffbike.weeklyDayPass : 0}
                    onChange={(e) =>
                      setStaffbike({
                        ...staffbike,
                        weeklyDayPass: Number(e.target.value),
                      })
                    }
                  />
                </Box>
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (weekly night)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='weeklyNightPass'
                    value={staffbike ? staffbike.weeklyNightPass : 0}
                    onChange={(e) =>
                      setStaffbike({
                        ...staffbike,
                        weeklyNightPass: Number(e.target.value),
                      })
                    }
                  />
                </Box>
              </Box>
              <Box
                display='flex'
                flexDirection={isNonMobile ? 'row' : 'column'}
                gridColumn='span 4'
                gap={4}
                marginTop={isNonMobile ? 2 : 1}
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (monthly day)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='monthlyDayPass'
                    value={staffbike ? staffbike.monthlyDayPass : 0}
                    onChange={(e) =>
                      setStaffbike({
                        ...staffbike,
                        monthlyDayPass: Number(e.target.value),
                      })
                    }
                  />
                </Box>
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (monthly night)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='monthlyNightPass'
                    value={staffbike ? staffbike.monthlyNightPass : 0}
                    onChange={(e) =>
                      setStaffbike({
                        ...staffbike,
                        monthlyNightPass: Number(e.target.value),
                      })
                    }
                  />
                </Box>
              </Box>
              <Box
                display='flex'
                flexDirection='column'
                gridColumn='span 4'
                gap={1}
                width='100%'
              >
                <Typography variant='p' component='p'>
                  Pass rate to display on ticket*
                </Typography>
                <TextField
                  fullWidth
                  variant='filled'
                  type='text'
                  name='passInfo'
                  sx={{ gridColumn: 'span 4' }}
                  value={staffbike ? staffbike.passInfo : 0}
                  onChange={(e) =>
                    setStaffbike({ ...staffbike, passInfo: e.target.value })
                  }
                />
              </Box>
              <Button
                type='submit'
                color='secondary'
                variant='contained'
                sx={{ fontSize: 14, alignSelf: 'flex-start', marginTop: 2 }}
                onClick={() => validate('staff bike')}
                disabled={
                  loadingGetRateById || loadingAddRate || loadingUpdateRate
                }
              >
                Validate
              </Button>
            </Box>
            <Box
              display='flex'
              flexDirection='column'
              justifyContent='space-between'
              alignItems='center'
              marginBottom={4}
              gap={2}
              sx={{
                backgroundColor: colors.primary[400],
                padding: 4,
                borderRadius: 2,
              }}
            >
              <Typography
                id='keep-mounted-modal-title'
                variant='h3'
                component='h2'
                sx={{ alignSelf: 'flex-start', marginBottom: 2 }}
              >
                Premium Bike
              </Typography>
              <Box
                display='flex'
                flexDirection='column'
                flex={1}
                gap={1}
                width='100%'
              >
                <Typography variant='p' component='p'>
                  Type
                </Typography>
                <Select
                  fullWidth
                  variant='filled'
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  value={premiumbike && premiumbike.type}
                  onChange={(e) =>
                    setPremiumBike({ ...premiumbike, type: e.target.value })
                  }
                >
                  <MenuItem
                    value={'hourly'}
                    selected={premiumbike && premiumbike.type === 'hourly'}
                  >
                    Hourly
                  </MenuItem>
                  <MenuItem
                    value={'daily'}
                    selected={premiumbike && premiumbike.type === 'daily'}
                  >
                    Daily
                  </MenuItem>
                </Select>
              </Box>
              <Box
                display='flex'
                flexDirection='column'
                gridColumn='span 4'
                gap={1}
                width='100%'
              >
                <Typography variant='p' component='p'>
                  Rate *
                </Typography>
                <TextareaAutosize
                  minRows={8}
                  value={premiumbike && premiumbike.rate}
                  onChange={(e) =>
                    setPremiumBike({ ...premiumbike, rate: e.target.value })
                  }
                />
              </Box>
              <Box
                display='flex'
                flexDirection='column'
                gridColumn='span 4'
                gap={1}
                width='100%'
              >
                <Typography variant='p' component='p'>
                  Checkin rate to display on ticket*
                </Typography>
                <TextField
                  fullWidth
                  variant='filled'
                  type='text'
                  name='info'
                  sx={{ gridColumn: 'span 4' }}
                  value={premiumbike ? premiumbike.info : ''}
                  onChange={(e) =>
                    setPremiumBike({ ...premiumbike, info: e.target.value })
                  }
                />
              </Box>
              <Box
                display='flex'
                flexDirection={isNonMobile ? 'row' : 'column'}
                gridColumn='span 4'
                gap={4}
                marginTop={isNonMobile ? 2 : 1}
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Night Charge
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='night'
                    value={premiumbike ? premiumbike.night : 0}
                    onChange={(e) =>
                      setPremiumBike({
                        ...premiumbike,
                        night: Number(e.target.value),
                      })
                    }
                  />
                </Box>
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Holiday Mode
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='holiday'
                    value={premiumbike ? premiumbike.holiday : 0}
                    onChange={(e) =>
                      setPremiumBike({
                        ...premiumbike,
                        holiday: Number(e.target.value),
                      })
                    }
                  />
                </Box>
              </Box>
              <Box
                display='flex'
                flexDirection={isNonMobile ? 'row' : 'column'}
                gridColumn='span 4'
                gap={4}
                marginTop={isNonMobile ? 2 : 1}
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (daily day)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='dailyDayPass'
                    value={premiumbike ? premiumbike.dailyDayPass : 0}
                    onChange={(e) =>
                      setPremiumBike({
                        ...premiumbike,
                        dailyDayPass: Number(e.target.value),
                      })
                    }
                  />
                </Box>
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (daily night)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='dailyNightPass'
                    value={premiumbike ? premiumbike.dailyNightPass : 0}
                    onChange={(e) =>
                      setPremiumBike({
                        ...premiumbike,
                        dailyNightPass: Number(e.target.value),
                      })
                    }
                  />
                </Box>
              </Box>
              <Box
                display='flex'
                flexDirection={isNonMobile ? 'row' : 'column'}
                gridColumn='span 4'
                gap={4}
                marginTop={isNonMobile ? 2 : 1}
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (weekly day)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='weeklyDayPass'
                    value={premiumbike ? premiumbike.weeklyDayPass : 0}
                    onChange={(e) =>
                      setPremiumBike({
                        ...premiumbike,
                        weeklyDayPass: Number(e.target.value),
                      })
                    }
                  />
                </Box>
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (weekly night)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='weeklyNightPass'
                    value={premiumbike ? premiumbike.weeklyNightPass : 0}
                    onChange={(e) =>
                      setPremiumBike({
                        ...premiumbike,
                        weeklyNightPass: Number(e.target.value),
                      })
                    }
                  />
                </Box>
              </Box>
              <Box
                display='flex'
                flexDirection={isNonMobile ? 'row' : 'column'}
                gridColumn='span 4'
                gap={4}
                marginTop={isNonMobile ? 2 : 1}
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (monthly day)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='monthlyDayPass'
                    value={premiumbike ? premiumbike.monthlyDayPass : 0}
                    onChange={(e) =>
                      setPremiumBike({
                        ...premiumbike,
                        monthlyDayPass: Number(e.target.value),
                      })
                    }
                  />
                </Box>
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (monthly night)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='monthlyNightPass'
                    value={premiumbike ? premiumbike.monthlyNightPass : 0}
                    onChange={(e) =>
                      setPremiumBike({
                        ...premiumbike,
                        monthlyNightPass: Number(e.target.value),
                      })
                    }
                  />
                </Box>
              </Box>
              <Box
                display='flex'
                flexDirection='column'
                gridColumn='span 4'
                gap={1}
                width='100%'
              >
                <Typography variant='p' component='p'>
                  Pass rate to display on ticket*
                </Typography>
                <TextField
                  fullWidth
                  variant='filled'
                  type='text'
                  name='passInfo'
                  sx={{ gridColumn: 'span 4' }}
                  value={premiumbike ? premiumbike.passInfo : 0}
                  onChange={(e) =>
                    setPremiumBike({ ...premiumbike, passInfo: e.target.value })
                  }
                />
              </Box>
              <Button
                type='submit'
                color='secondary'
                variant='contained'
                sx={{ fontSize: 14, alignSelf: 'flex-start', marginTop: 2 }}
                onClick={() => validate('premium bike')}
                disabled={
                  loadingGetRateById || loadingAddRate || loadingUpdateRate
                }
              >
                Validate
              </Button>
            </Box>
            {/* ))} */}
            <Box
              display='flex'
              flexDirection='column'
              justifyContent='space-between'
              alignItems='center'
              marginBottom={4}
              gap={2}
              sx={{
                backgroundColor: colors.primary[400],
                padding: 4,
                borderRadius: 2,
              }}
            >
              <Typography
                id='keep-mounted-modal-title'
                variant='h3'
                component='h2'
                sx={{ alignSelf: 'flex-start', marginBottom: 2 }}
              >
                Valet Car
              </Typography>
              <Box
                display='flex'
                flexDirection='column'
                flex={1}
                gap={1}
                width='100%'
              >
                <Typography variant='p' component='p'>
                  Type
                </Typography>
                <Select
                  fullWidth
                  variant='filled'
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  value={valetCar && valetCar.type}
                  onChange={(e) =>
                    setValetCar({ ...valetCar, type: e.target.value })
                  }
                >
                  <MenuItem
                    value={'hourly'}
                    selected={valetCar && valetCar.type === 'hourly'}
                  >
                    Hourly
                  </MenuItem>
                  <MenuItem
                    value={'daily'}
                    selected={valetCar && valetCar.type === 'daily'}
                  >
                    Daily
                  </MenuItem>
                </Select>
              </Box>
              <Box
                display='flex'
                flexDirection='column'
                gridColumn='span 4'
                gap={1}
                width='100%'
              >
                <Typography variant='p' component='p'>
                  Rate *
                </Typography>
                <TextareaAutosize
                  minRows={8}
                  value={valetCar && valetCar.rate}
                  onChange={(e) =>
                    setValetCar({ ...valetCar, rate: e.target.value })
                  }
                />
              </Box>
              <Box
                display='flex'
                flexDirection='column'
                gridColumn='span 4'
                gap={1}
                width='100%'
              >
                <Typography variant='p' component='p'>
                  Checkin rate to display on ticket*
                </Typography>
                <TextField
                  fullWidth
                  variant='filled'
                  type='text'
                  name='info'
                  sx={{ gridColumn: 'span 4' }}
                  value={valetCar ? valetCar.info : ''}
                  onChange={(e) =>
                    setValetCar({ ...valetCar, info: e.target.value })
                  }
                />
              </Box>
              <Box
                display='flex'
                flexDirection={isNonMobile ? 'row' : 'column'}
                gridColumn='span 4'
                gap={4}
                marginTop={isNonMobile ? 2 : 1}
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Night Charge
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='night'
                    value={valetCar ? valetCar.night : 0}
                    onChange={(e) =>
                      setValetCar({
                        ...valetCar,
                        night: Number(e.target.value),
                      })
                    }
                  />
                </Box>
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Holiday Mode
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='holiday'
                    value={valetCar ? valetCar.holiday : 0}
                    onChange={(e) =>
                      setValetCar({
                        ...valetCar,
                        holiday: Number(e.target.value),
                      })
                    }
                  />
                </Box>
              </Box>
              <Box
                display='flex'
                flexDirection={isNonMobile ? 'row' : 'column'}
                gridColumn='span 4'
                gap={4}
                marginTop={isNonMobile ? 2 : 1}
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (daily day)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='dailyDayPass'
                    value={valetCar ? valetCar.dailyDayPass : 0}
                    onChange={(e) =>
                      setValetCar({
                        ...valetCar,
                        dailyDayPass: Number(e.target.value),
                      })
                    }
                  />
                </Box>
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (daily night)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='dailyNightPass'
                    value={valetCar ? valetCar.dailyNightPass : 0}
                    onChange={(e) =>
                      setValetCar({
                        ...valetCar,
                        dailyNightPass: Number(e.target.value),
                      })
                    }
                  />
                </Box>
              </Box>
              <Box
                display='flex'
                flexDirection={isNonMobile ? 'row' : 'column'}
                gridColumn='span 4'
                gap={4}
                marginTop={isNonMobile ? 2 : 1}
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (weekly day)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='weeklyDayPass'
                    value={valetCar ? valetCar.weeklyDayPass : 0}
                    onChange={(e) =>
                      setValetCar({
                        ...valetCar,
                        weeklyDayPass: Number(e.target.value),
                      })
                    }
                  />
                </Box>
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (weekly night)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='weeklyNightPass'
                    value={valetCar ? valetCar.weeklyNightPass : 0}
                    onChange={(e) =>
                      setValetCar({
                        ...valetCar,
                        weeklyNightPass: Number(e.target.value),
                      })
                    }
                  />
                </Box>
              </Box>
              <Box
                display='flex'
                flexDirection={isNonMobile ? 'row' : 'column'}
                gridColumn='span 4'
                gap={4}
                marginTop={isNonMobile ? 2 : 1}
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (monthly day)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='monthlyDayPass'
                    value={valetCar ? valetCar.monthlyDayPass : 0}
                    onChange={(e) =>
                      setValetCar({
                        ...valetCar,
                        monthlyDayPass: Number(e.target.value),
                      })
                    }
                  />
                </Box>
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (monthly night)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='monthlyNightPass'
                    value={valetCar ? valetCar.monthlyNightPass : 0}
                    onChange={(e) =>
                      setValetCar({
                        ...valetCar,
                        monthlyNightPass: Number(e.target.value),
                      })
                    }
                  />
                </Box>
              </Box>
              <Box
                display='flex'
                flexDirection='column'
                gridColumn='span 4'
                gap={1}
                width='100%'
              >
                <Typography variant='p' component='p'>
                  Pass rate to display on ticket*
                </Typography>
                <TextField
                  fullWidth
                  variant='filled'
                  type='text'
                  name='passInfo'
                  sx={{ gridColumn: 'span 4' }}
                  value={valetCar ? valetCar.passInfo : 0}
                  onChange={(e) =>
                    setValetCar({ ...valetCar, passInfo: e.target.value })
                  }
                />
              </Box>
              <Button
                type='submit'
                color='secondary'
                variant='contained'
                sx={{ fontSize: 14, alignSelf: 'flex-start', marginTop: 2 }}
                onClick={() => validate('valet car')}
                disabled={
                  loadingGetRateById || loadingAddRate || loadingUpdateRate
                }
              >
                Validate
              </Button>
            </Box>
            <Box
              display='flex'
              flexDirection='column'
              justifyContent='space-between'
              alignItems='center'
              marginBottom={4}
              gap={2}
              sx={{
                backgroundColor: colors.primary[400],
                padding: 4,
                borderRadius: 2,
              }}
            >
              <Typography
                id='keep-mounted-modal-title'
                variant='h3'
                component='h2'
                sx={{ alignSelf: 'flex-start', marginBottom: 2 }}
              >
                Challan Car
              </Typography>
              <Box
                display='flex'
                flexDirection='column'
                flex={1}
                gap={1}
                width='100%'
              >
                <Typography variant='p' component='p'>
                  Type
                </Typography>
                <Select
                  fullWidth
                  variant='filled'
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  value={challanCar && challanCar.type}
                  onChange={(e) =>
                    setChallanCar({ ...challanCar, type: e.target.value })
                  }
                >
                  <MenuItem
                    value={'hourly'}
                    selected={challanCar && challanCar.type === 'hourly'}
                  >
                    Hourly
                  </MenuItem>
                  <MenuItem
                    value={'daily'}
                    selected={challanCar && challanCar.type === 'daily'}
                  >
                    Daily
                  </MenuItem>
                </Select>
              </Box>
              <Box
                display='flex'
                flexDirection='column'
                gridColumn='span 4'
                gap={1}
                width='100%'
              >
                <Typography variant='p' component='p'>
                  Rate *
                </Typography>
                <TextareaAutosize
                  minRows={8}
                  value={challanCar && challanCar.rate}
                  onChange={(e) =>
                    setChallanCar({ ...challanCar, rate: e.target.value })
                  }
                />
              </Box>
              <Box
                display='flex'
                flexDirection='column'
                gridColumn='span 4'
                gap={1}
                width='100%'
              >
                <Typography variant='p' component='p'>
                  Checkin rate to display on ticket*
                </Typography>
                <TextField
                  fullWidth
                  variant='filled'
                  type='text'
                  name='info'
                  sx={{ gridColumn: 'span 4' }}
                  value={challanCar ? challanCar.info : ''}
                  onChange={(e) =>
                    setChallanCar({ ...challanCar, info: e.target.value })
                  }
                />
              </Box>
              <Box
                display='flex'
                flexDirection={isNonMobile ? 'row' : 'column'}
                gridColumn='span 4'
                gap={4}
                marginTop={isNonMobile ? 2 : 1}
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Night Charge
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='night'
                    value={challanCar ? challanCar.night : 0}
                    onChange={(e) =>
                      setChallanCar({
                        ...challanCar,
                        night: Number(e.target.value),
                      })
                    }
                  />
                </Box>
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Holiday Mode
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='holiday'
                    value={challanCar ? challanCar.holiday : 0}
                    onChange={(e) =>
                      setChallanCar({
                        ...challanCar,
                        holiday: Number(e.target.value),
                      })
                    }
                  />
                </Box>
              </Box>
              <Box
                display='flex'
                flexDirection={isNonMobile ? 'row' : 'column'}
                gridColumn='span 4'
                gap={4}
                marginTop={isNonMobile ? 2 : 1}
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (daily day)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='dailyDayPass'
                    value={challanCar ? challanCar.dailyDayPass : 0}
                    onChange={(e) =>
                      setChallanCar({
                        ...challanCar,
                        dailyDayPass: Number(e.target.value),
                      })
                    }
                  />
                </Box>
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (daily night)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='dailyNightPass'
                    value={challanCar ? challanCar.dailyNightPass : 0}
                    onChange={(e) =>
                      setChallanCar({
                        ...challanCar,
                        dailyNightPass: Number(e.target.value),
                      })
                    }
                  />
                </Box>
              </Box>
              <Box
                display='flex'
                flexDirection={isNonMobile ? 'row' : 'column'}
                gridColumn='span 4'
                gap={4}
                marginTop={isNonMobile ? 2 : 1}
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (weekly day)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='weeklyDayPass'
                    value={challanCar ? challanCar.weeklyDayPass : 0}
                    onChange={(e) =>
                      setChallanCar({
                        ...challanCar,
                        weeklyDayPass: Number(e.target.value),
                      })
                    }
                  />
                </Box>
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (weekly night)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='weeklyNightPass'
                    value={challanCar ? challanCar.weeklyNightPass : 0}
                    onChange={(e) =>
                      setChallanCar({
                        ...challanCar,
                        weeklyNightPass: Number(e.target.value),
                      })
                    }
                  />
                </Box>
              </Box>
              <Box
                display='flex'
                flexDirection={isNonMobile ? 'row' : 'column'}
                gridColumn='span 4'
                gap={4}
                marginTop={isNonMobile ? 2 : 1}
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (monthly day)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='monthlyDayPass'
                    value={challanCar ? challanCar.monthlyDayPass : 0}
                    onChange={(e) =>
                      setChallanCar({
                        ...challanCar,
                        monthlyDayPass: Number(e.target.value),
                      })
                    }
                  />
                </Box>
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (monthly night)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='monthlyNightPass'
                    value={challanCar ? challanCar.monthlyNightPass : 0}
                    onChange={(e) =>
                      setChallanCar({
                        ...challanCar,
                        monthlyNightPass: Number(e.target.value),
                      })
                    }
                  />
                </Box>
              </Box>
              <Box
                display='flex'
                flexDirection='column'
                gridColumn='span 4'
                gap={1}
                width='100%'
              >
                <Typography variant='p' component='p'>
                  Pass rate to display on ticket*
                </Typography>
                <TextField
                  fullWidth
                  variant='filled'
                  type='text'
                  name='passInfo'
                  sx={{ gridColumn: 'span 4' }}
                  value={challanCar ? challanCar.passInfo : 0}
                  onChange={(e) =>
                    setChallanCar({ ...challanCar, passInfo: e.target.value })
                  }
                />
              </Box>
              <Button
                type='submit'
                color='secondary'
                variant='contained'
                sx={{ fontSize: 14, alignSelf: 'flex-start', marginTop: 2 }}
                onClick={() => validate('challan car')}
                disabled={
                  loadingGetRateById || loadingAddRate || loadingUpdateRate
                }
              >
                Validate
              </Button>
            </Box>
            <Box
              display='flex'
              flexDirection='column'
              justifyContent='space-between'
              alignItems='center'
              marginBottom={4}
              gap={2}
              sx={{
                backgroundColor: colors.primary[400],
                padding: 4,
                borderRadius: 2,
              }}
            >
              <Typography
                id='keep-mounted-modal-title'
                variant='h3'
                component='h2'
                sx={{ alignSelf: 'flex-start', marginBottom: 2 }}
              >
                Challan Bike
              </Typography>
              <Box
                display='flex'
                flexDirection='column'
                flex={1}
                gap={1}
                width='100%'
              >
                <Typography variant='p' component='p'>
                  Type
                </Typography>
                <Select
                  fullWidth
                  variant='filled'
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  value={challanBike && challanBike.type}
                  onChange={(e) =>
                    setChallanBike({ ...challanBike, type: e.target.value })
                  }
                >
                  <MenuItem
                    value={'hourly'}
                    selected={challanBike && challanBike.type === 'hourly'}
                  >
                    Hourly
                  </MenuItem>
                  <MenuItem
                    value={'daily'}
                    selected={challanBike && challanBike.type === 'daily'}
                  >
                    Daily
                  </MenuItem>
                </Select>
              </Box>
              <Box
                display='flex'
                flexDirection='column'
                gridColumn='span 4'
                gap={1}
                width='100%'
              >
                <Typography variant='p' component='p'>
                  Rate *
                </Typography>
                <TextareaAutosize
                  minRows={8}
                  value={challanBike && challanBike.rate}
                  onChange={(e) =>
                    setChallanBike({ ...challanBike, rate: e.target.value })
                  }
                />
              </Box>
              <Box
                display='flex'
                flexDirection='column'
                gridColumn='span 4'
                gap={1}
                width='100%'
              >
                <Typography variant='p' component='p'>
                  Checkin rate to display on ticket*
                </Typography>
                <TextField
                  fullWidth
                  variant='filled'
                  type='text'
                  name='info'
                  sx={{ gridColumn: 'span 4' }}
                  value={challanBike ? challanBike.info : ''}
                  onChange={(e) =>
                    setChallanBike({ ...challanBike, info: e.target.value })
                  }
                />
              </Box>
              <Box
                display='flex'
                flexDirection={isNonMobile ? 'row' : 'column'}
                gridColumn='span 4'
                gap={4}
                marginTop={isNonMobile ? 2 : 1}
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Night Charge
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='night'
                    value={challanBike ? challanBike.night : 0}
                    onChange={(e) =>
                      setChallanBike({
                        ...challanBike,
                        night: Number(e.target.value),
                      })
                    }
                  />
                </Box>
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Holiday Mode
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='holiday'
                    value={challanBike ? challanBike.holiday : 0}
                    onChange={(e) =>
                      setChallanBike({
                        ...challanBike,
                        holiday: Number(e.target.value),
                      })
                    }
                  />
                </Box>
              </Box>
              <Box
                display='flex'
                flexDirection={isNonMobile ? 'row' : 'column'}
                gridColumn='span 4'
                gap={4}
                marginTop={isNonMobile ? 2 : 1}
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (daily day)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='dailyDayPass'
                    value={challanBike ? challanBike.dailyDayPass : 0}
                    onChange={(e) =>
                      setChallanBike({
                        ...challanBike,
                        dailyDayPass: Number(e.target.value),
                      })
                    }
                  />
                </Box>
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (daily night)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='dailyNightPass'
                    value={challanBike ? challanBike.dailyNightPass : 0}
                    onChange={(e) =>
                      setChallanBike({
                        ...challanBike,
                        dailyNightPass: Number(e.target.value),
                      })
                    }
                  />
                </Box>
              </Box>
              <Box
                display='flex'
                flexDirection={isNonMobile ? 'row' : 'column'}
                gridColumn='span 4'
                gap={4}
                marginTop={isNonMobile ? 2 : 1}
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (weekly day)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='weeklyDayPass'
                    value={challanBike ? challanBike.weeklyDayPass : 0}
                    onChange={(e) =>
                      setChallanBike({
                        ...challanBike,
                        weeklyDayPass: Number(e.target.value),
                      })
                    }
                  />
                </Box>
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (weekly night)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='weeklyNightPass'
                    value={challanBike ? challanBike.weeklyNightPass : 0}
                    onChange={(e) =>
                      setChallanBike({
                        ...challanBike,
                        weeklyNightPass: Number(e.target.value),
                      })
                    }
                  />
                </Box>
              </Box>
              <Box
                display='flex'
                flexDirection={isNonMobile ? 'row' : 'column'}
                gridColumn='span 4'
                gap={4}
                marginTop={isNonMobile ? 2 : 1}
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (monthly day)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='monthlyDayPass'
                    value={challanBike ? challanBike.monthlyDayPass : 0}
                    onChange={(e) =>
                      setChallanBike({
                        ...challanBike,
                        monthlyDayPass: Number(e.target.value),
                      })
                    }
                  />
                </Box>
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (monthly night)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='monthlyNightPass'
                    value={challanBike ? challanBike.monthlyNightPass : 0}
                    onChange={(e) =>
                      setChallanBike({
                        ...challanBike,
                        monthlyNightPass: Number(e.target.value),
                      })
                    }
                  />
                </Box>
              </Box>
              <Box
                display='flex'
                flexDirection='column'
                gridColumn='span 4'
                gap={1}
                width='100%'
              >
                <Typography variant='p' component='p'>
                  Pass rate to display on ticket*
                </Typography>
                <TextField
                  fullWidth
                  variant='filled'
                  type='text'
                  name='passInfo'
                  sx={{ gridColumn: 'span 4' }}
                  value={challanBike ? challanBike.passInfo : 0}
                  onChange={(e) =>
                    setChallanBike({ ...challanBike, passInfo: e.target.value })
                  }
                />
              </Box>
              <Button
                type='submit'
                color='secondary'
                variant='contained'
                sx={{ fontSize: 14, alignSelf: 'flex-start', marginTop: 2 }}
                onClick={() => validate('challan bike')}
                disabled={
                  loadingGetRateById || loadingAddRate || loadingUpdateRate
                }
              >
                Validate
              </Button>
            </Box>
            <Box
              display='flex'
              flexDirection='column'
              justifyContent='space-between'
              alignItems='center'
              marginBottom={4}
              gap={2}
              sx={{
                backgroundColor: colors.primary[400],
                padding: 4,
                borderRadius: 2,
              }}
            >
              <Typography
                id='keep-mounted-modal-title'
                variant='h3'
                component='h2'
                sx={{ alignSelf: 'flex-start', marginBottom: 2 }}
              >
                Taxi
              </Typography>
              <Box
                display='flex'
                flexDirection='column'
                flex={1}
                gap={1}
                width='100%'
              >
                <Typography variant='p' component='p'>
                  Type
                </Typography>
                <Select
                  fullWidth
                  variant='filled'
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  value={taxi && taxi.type}
                  onChange={(e) => setTaxi({ ...taxi, type: e.target.value })}
                >
                  <MenuItem
                    value={'hourly'}
                    selected={taxi && taxi.type === 'hourly'}
                  >
                    Hourly
                  </MenuItem>
                  <MenuItem
                    value={'daily'}
                    selected={taxi && taxi.type === 'daily'}
                  >
                    Daily
                  </MenuItem>
                </Select>
              </Box>
              <Box
                display='flex'
                flexDirection='column'
                gridColumn='span 4'
                gap={1}
                width='100%'
              >
                <Typography variant='p' component='p'>
                  Rate *
                </Typography>
                <TextareaAutosize
                  minRows={8}
                  value={taxi && taxi.rate}
                  onChange={(e) => setTaxi({ ...taxi, rate: e.target.value })}
                />
              </Box>
              <Box
                display='flex'
                flexDirection='column'
                gridColumn='span 4'
                gap={1}
                width='100%'
              >
                <Typography variant='p' component='p'>
                  Checkin rate to display on ticket*
                </Typography>
                <TextField
                  fullWidth
                  variant='filled'
                  type='text'
                  name='info'
                  sx={{ gridColumn: 'span 4' }}
                  value={taxi ? taxi.info : ''}
                  onChange={(e) => setTaxi({ ...taxi, info: e.target.value })}
                />
              </Box>
              <Box
                display='flex'
                flexDirection={isNonMobile ? 'row' : 'column'}
                gridColumn='span 4'
                gap={4}
                marginTop={isNonMobile ? 2 : 1}
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Night Charge
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='night'
                    value={taxi ? taxi.night : 0}
                    onChange={(e) =>
                      setTaxi({
                        ...taxi,
                        night: Number(e.target.value),
                      })
                    }
                  />
                </Box>
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Holiday Mode
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='holiday'
                    value={taxi ? taxi.holiday : 0}
                    onChange={(e) =>
                      setTaxi({
                        ...taxi,
                        holiday: Number(e.target.value),
                      })
                    }
                  />
                </Box>
              </Box>
              <Box
                display='flex'
                flexDirection={isNonMobile ? 'row' : 'column'}
                gridColumn='span 4'
                gap={4}
                marginTop={isNonMobile ? 2 : 1}
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (daily day)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='dailyDayPass'
                    value={taxi ? taxi.dailyDayPass : 0}
                    onChange={(e) =>
                      setTaxi({
                        ...taxi,
                        dailyDayPass: Number(e.target.value),
                      })
                    }
                  />
                </Box>
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (daily night)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='dailyNightPass'
                    value={taxi ? taxi.dailyNightPass : 0}
                    onChange={(e) =>
                      setTaxi({
                        ...taxi,
                        dailyNightPass: Number(e.target.value),
                      })
                    }
                  />
                </Box>
              </Box>
              <Box
                display='flex'
                flexDirection={isNonMobile ? 'row' : 'column'}
                gridColumn='span 4'
                gap={4}
                marginTop={isNonMobile ? 2 : 1}
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (weekly day)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='weeklyDayPass'
                    value={taxi ? taxi.weeklyDayPass : 0}
                    onChange={(e) =>
                      setTaxi({
                        ...taxi,
                        weeklyDayPass: Number(e.target.value),
                      })
                    }
                  />
                </Box>
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (weekly night)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='weeklyNightPass'
                    value={taxi ? taxi.weeklyNightPass : 0}
                    onChange={(e) =>
                      setTaxi({
                        ...taxi,
                        weeklyNightPass: Number(e.target.value),
                      })
                    }
                  />
                </Box>
              </Box>
              <Box
                display='flex'
                flexDirection={isNonMobile ? 'row' : 'column'}
                gridColumn='span 4'
                gap={4}
                marginTop={isNonMobile ? 2 : 1}
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (monthly day)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='monthlyDayPass'
                    value={taxi ? taxi.monthlyDayPass : 0}
                    onChange={(e) =>
                      setTaxi({
                        ...taxi,
                        monthlyDayPass: Number(e.target.value),
                      })
                    }
                  />
                </Box>
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (monthly night)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='monthlyNightPass'
                    value={taxi ? taxi.monthlyNightPass : 0}
                    onChange={(e) =>
                      setTaxi({
                        ...taxi,
                        monthlyNightPass: Number(e.target.value),
                      })
                    }
                  />
                </Box>
              </Box>
              <Box
                display='flex'
                flexDirection='column'
                gridColumn='span 4'
                gap={1}
                width='100%'
              >
                <Typography variant='p' component='p'>
                  Pass rate to display on ticket*
                </Typography>
                <TextField
                  fullWidth
                  variant='filled'
                  type='text'
                  name='passInfo'
                  sx={{ gridColumn: 'span 4' }}
                  value={taxi ? taxi.passInfo : 0}
                  onChange={(e) =>
                    setTaxi({ ...taxi, passInfo: e.target.value })
                  }
                />
              </Box>
              <Button
                type='submit'
                color='secondary'
                variant='contained'
                sx={{ fontSize: 14, alignSelf: 'flex-start', marginTop: 2 }}
                onClick={() => validate('taxi')}
                disabled={
                  loadingGetRateById || loadingAddRate || loadingUpdateRate
                }
              >
                Validate
              </Button>
            </Box>
            <Box
              display='flex'
              flexDirection='column'
              justifyContent='space-between'
              alignItems='center'
              marginBottom={4}
              gap={2}
              sx={{
                backgroundColor: colors.primary[400],
                padding: 4,
                borderRadius: 2,
              }}
            >
              <Typography
                id='keep-mounted-modal-title'
                variant='h3'
                component='h2'
                sx={{ alignSelf: 'flex-start', marginBottom: 2 }}
              >
                Commercial Vehicle
              </Typography>
              <Box
                display='flex'
                flexDirection='column'
                flex={1}
                gap={1}
                width='100%'
              >
                <Typography variant='p' component='p'>
                  Type
                </Typography>
                <Select
                  fullWidth
                  variant='filled'
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  value={commercialVehicle && commercialVehicle.type}
                  onChange={(e) =>
                    setCommercialVehicle({
                      ...commercialVehicle,
                      type: e.target.value,
                    })
                  }
                >
                  <MenuItem
                    value={'hourly'}
                    selected={
                      commercialVehicle && commercialVehicle.type === 'hourly'
                    }
                  >
                    Hourly
                  </MenuItem>
                  <MenuItem
                    value={'daily'}
                    selected={
                      commercialVehicle && commercialVehicle.type === 'daily'
                    }
                  >
                    Daily
                  </MenuItem>
                </Select>
              </Box>
              <Box
                display='flex'
                flexDirection='column'
                gridColumn='span 4'
                gap={1}
                width='100%'
              >
                <Typography variant='p' component='p'>
                  Rate *
                </Typography>
                <TextareaAutosize
                  minRows={8}
                  value={commercialVehicle && commercialVehicle.rate}
                  onChange={(e) =>
                    setCommercialVehicle({
                      ...commercialVehicle,
                      rate: e.target.value,
                    })
                  }
                />
              </Box>
              <Box
                display='flex'
                flexDirection='column'
                gridColumn='span 4'
                gap={1}
                width='100%'
              >
                <Typography variant='p' component='p'>
                  Checkin rate to display on ticket*
                </Typography>
                <TextField
                  fullWidth
                  variant='filled'
                  type='text'
                  name='info'
                  sx={{ gridColumn: 'span 4' }}
                  value={commercialVehicle ? commercialVehicle.info : ''}
                  onChange={(e) =>
                    setCommercialVehicle({
                      ...commercialVehicle,
                      info: e.target.value,
                    })
                  }
                />
              </Box>
              <Box
                display='flex'
                flexDirection={isNonMobile ? 'row' : 'column'}
                gridColumn='span 4'
                gap={4}
                marginTop={isNonMobile ? 2 : 1}
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Night Charge
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='night'
                    value={commercialVehicle ? commercialVehicle.night : 0}
                    onChange={(e) =>
                      setCommercialVehicle({
                        ...commercialVehicle,
                        night: Number(e.target.value),
                      })
                    }
                  />
                </Box>
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Holiday Mode
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='holiday'
                    value={commercialVehicle ? commercialVehicle.holiday : 0}
                    onChange={(e) =>
                      setCommercialVehicle({
                        ...commercialVehicle,
                        holiday: Number(e.target.value),
                      })
                    }
                  />
                </Box>
              </Box>
              <Box
                display='flex'
                flexDirection={isNonMobile ? 'row' : 'column'}
                gridColumn='span 4'
                gap={4}
                marginTop={isNonMobile ? 2 : 1}
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (daily day)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='dailyDayPass'
                    value={
                      commercialVehicle ? commercialVehicle.dailyDayPass : 0
                    }
                    onChange={(e) =>
                      setCommercialVehicle({
                        ...commercialVehicle,
                        dailyDayPass: Number(e.target.value),
                      })
                    }
                  />
                </Box>
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (daily night)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='dailyNightPass'
                    value={
                      commercialVehicle ? commercialVehicle.dailyNightPass : 0
                    }
                    onChange={(e) =>
                      setCommercialVehicle({
                        ...commercialVehicle,
                        dailyNightPass: Number(e.target.value),
                      })
                    }
                  />
                </Box>
              </Box>
              <Box
                display='flex'
                flexDirection={isNonMobile ? 'row' : 'column'}
                gridColumn='span 4'
                gap={4}
                marginTop={isNonMobile ? 2 : 1}
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (weekly day)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='weeklyDayPass'
                    value={
                      commercialVehicle ? commercialVehicle.weeklyDayPass : 0
                    }
                    onChange={(e) =>
                      setCommercialVehicle({
                        ...commercialVehicle,
                        weeklyDayPass: Number(e.target.value),
                      })
                    }
                  />
                </Box>
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (weekly night)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='weeklyNightPass'
                    value={
                      commercialVehicle ? commercialVehicle.weeklyNightPass : 0
                    }
                    onChange={(e) =>
                      setCommercialVehicle({
                        ...commercialVehicle,
                        weeklyNightPass: Number(e.target.value),
                      })
                    }
                  />
                </Box>
              </Box>
              <Box
                display='flex'
                flexDirection={isNonMobile ? 'row' : 'column'}
                gridColumn='span 4'
                gap={4}
                marginTop={isNonMobile ? 2 : 1}
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (monthly day)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='monthlyDayPass'
                    value={
                      commercialVehicle ? commercialVehicle.monthlyDayPass : 0
                    }
                    onChange={(e) =>
                      setCommercialVehicle({
                        ...commercialVehicle,
                        monthlyDayPass: Number(e.target.value),
                      })
                    }
                  />
                </Box>
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (monthly night)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='monthlyNightPass'
                    value={
                      commercialVehicle ? commercialVehicle.monthlyNightPass : 0
                    }
                    onChange={(e) =>
                      setCommercialVehicle({
                        ...commercialVehicle,
                        monthlyNightPass: Number(e.target.value),
                      })
                    }
                  />
                </Box>
              </Box>
              <Box
                display='flex'
                flexDirection='column'
                gridColumn='span 4'
                gap={1}
                width='100%'
              >
                <Typography variant='p' component='p'>
                  Pass rate to display on ticket*
                </Typography>
                <TextField
                  fullWidth
                  variant='filled'
                  type='text'
                  name='passInfo'
                  sx={{ gridColumn: 'span 4' }}
                  value={commercialVehicle ? commercialVehicle.passInfo : 0}
                  onChange={(e) =>
                    setCommercialVehicle({
                      ...commercialVehicle,
                      passInfo: e.target.value,
                    })
                  }
                />
              </Box>
              <Button
                type='submit'
                color='secondary'
                variant='contained'
                sx={{ fontSize: 14, alignSelf: 'flex-start', marginTop: 2 }}
                onClick={() => validate('commercial vehicle')}
                disabled={
                  loadingGetRateById || loadingAddRate || loadingUpdateRate
                }
              >
                Validate
              </Button>
            </Box>
            <Box
              display='flex'
              flexDirection='column'
              justifyContent='space-between'
              alignItems='center'
              marginBottom={4}
              gap={2}
              sx={{
                backgroundColor: colors.primary[400],
                padding: 4,
                borderRadius: 2,
              }}
            >
              <Typography
                id='keep-mounted-modal-title'
                variant='h3'
                component='h2'
                sx={{ alignSelf: 'flex-start', marginBottom: 2 }}
              >
                Tenant Car
              </Typography>
              <Box
                display='flex'
                flexDirection='column'
                flex={1}
                gap={1}
                width='100%'
              >
                <Typography variant='p' component='p'>
                  Type
                </Typography>
                <Select
                  fullWidth
                  variant='filled'
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  value={tenantCar && tenantCar.type}
                  onChange={(e) =>
                    setTenantCar({
                      ...tenantCar,
                      type: e.target.value,
                    })
                  }
                >
                  <MenuItem
                    value={'hourly'}
                    selected={tenantCar && tenantCar.type === 'hourly'}
                  >
                    Hourly
                  </MenuItem>
                  <MenuItem
                    value={'daily'}
                    selected={tenantCar && tenantCar.type === 'daily'}
                  >
                    Daily
                  </MenuItem>
                </Select>
              </Box>
              <Box
                display='flex'
                flexDirection='column'
                gridColumn='span 4'
                gap={1}
                width='100%'
              >
                <Typography variant='p' component='p'>
                  Rate *
                </Typography>
                <TextareaAutosize
                  minRows={8}
                  value={tenantCar && tenantCar.rate}
                  onChange={(e) =>
                    setTenantCar({
                      ...tenantCar,
                      rate: e.target.value,
                    })
                  }
                />
              </Box>
              <Box
                display='flex'
                flexDirection='column'
                gridColumn='span 4'
                gap={1}
                width='100%'
              >
                <Typography variant='p' component='p'>
                  Checkin rate to display on ticket*
                </Typography>
                <TextField
                  fullWidth
                  variant='filled'
                  type='text'
                  name='info'
                  sx={{ gridColumn: 'span 4' }}
                  value={tenantCar ? tenantCar.info : ''}
                  onChange={(e) =>
                    setTenantCar({
                      ...tenantCar,
                      info: e.target.value,
                    })
                  }
                />
              </Box>
              <Box
                display='flex'
                flexDirection={isNonMobile ? 'row' : 'column'}
                gridColumn='span 4'
                gap={4}
                marginTop={isNonMobile ? 2 : 1}
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Night Charge
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='night'
                    value={tenantCar ? tenantCar.night : 0}
                    onChange={(e) =>
                      setTenantCar({
                        ...tenantCar,
                        night: Number(e.target.value),
                      })
                    }
                  />
                </Box>
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Holiday Mode
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='holiday'
                    value={tenantCar ? tenantCar.holiday : 0}
                    onChange={(e) =>
                      setTenantCar({
                        ...tenantCar,
                        holiday: Number(e.target.value),
                      })
                    }
                  />
                </Box>
              </Box>
              <Box
                display='flex'
                flexDirection={isNonMobile ? 'row' : 'column'}
                gridColumn='span 4'
                gap={4}
                marginTop={isNonMobile ? 2 : 1}
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (daily day)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='dailyDayPass'
                    value={tenantCar ? tenantCar.dailyDayPass : 0}
                    onChange={(e) =>
                      setTenantCar({
                        ...tenantCar,
                        dailyDayPass: Number(e.target.value),
                      })
                    }
                  />
                </Box>
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (daily night)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='dailyNightPass'
                    value={tenantCar ? tenantCar.dailyNightPass : 0}
                    onChange={(e) =>
                      setTenantCar({
                        ...tenantCar,
                        dailyNightPass: Number(e.target.value),
                      })
                    }
                  />
                </Box>
              </Box>
              <Box
                display='flex'
                flexDirection={isNonMobile ? 'row' : 'column'}
                gridColumn='span 4'
                gap={4}
                marginTop={isNonMobile ? 2 : 1}
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (weekly day)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='weeklyDayPass'
                    value={tenantCar ? tenantCar.weeklyDayPass : 0}
                    onChange={(e) =>
                      setTenantCar({
                        ...tenantCar,
                        weeklyDayPass: Number(e.target.value),
                      })
                    }
                  />
                </Box>
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (weekly night)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='weeklyNightPass'
                    value={tenantCar ? tenantCar.weeklyNightPass : 0}
                    onChange={(e) =>
                      setTenantCar({
                        ...tenantCar,
                        weeklyNightPass: Number(e.target.value),
                      })
                    }
                  />
                </Box>
              </Box>
              <Box
                display='flex'
                flexDirection={isNonMobile ? 'row' : 'column'}
                gridColumn='span 4'
                gap={4}
                marginTop={isNonMobile ? 2 : 1}
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (monthly day)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='monthlyDayPass'
                    value={tenantCar ? tenantCar.monthlyDayPass : 0}
                    onChange={(e) =>
                      setTenantCar({
                        ...tenantCar,
                        monthlyDayPass: Number(e.target.value),
                      })
                    }
                  />
                </Box>
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (monthly night)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='monthlyNightPass'
                    value={tenantCar ? tenantCar.monthlyNightPass : 0}
                    onChange={(e) =>
                      setTenantCar({
                        ...tenantCar,
                        monthlyNightPass: Number(e.target.value),
                      })
                    }
                  />
                </Box>
              </Box>
              <Box
                display='flex'
                flexDirection='column'
                gridColumn='span 4'
                gap={1}
                width='100%'
              >
                <Typography variant='p' component='p'>
                  Pass rate to display on ticket*
                </Typography>
                <TextField
                  fullWidth
                  variant='filled'
                  type='text'
                  name='passInfo'
                  sx={{ gridColumn: 'span 4' }}
                  value={tenantCar ? tenantCar.passInfo : 0}
                  onChange={(e) =>
                    setTenantCar({
                      ...tenantCar,
                      passInfo: e.target.value,
                    })
                  }
                />
              </Box>
              <Button
                type='submit'
                color='secondary'
                variant='contained'
                sx={{ fontSize: 14, alignSelf: 'flex-start', marginTop: 2 }}
                onClick={() => validate('tenant car')}
                disabled={
                  loadingGetRateById || loadingAddRate || loadingUpdateRate
                }
              >
                Validate
              </Button>
            </Box>
            <Box
              display='flex'
              flexDirection='column'
              justifyContent='space-between'
              alignItems='center'
              marginBottom={4}
              gap={2}
              sx={{
                backgroundColor: colors.primary[400],
                padding: 4,
                borderRadius: 2,
              }}
            >
              <Typography
                id='keep-mounted-modal-title'
                variant='h3'
                component='h2'
                sx={{ alignSelf: 'flex-start', marginBottom: 2 }}
              >
                Tenant Bike
              </Typography>
              <Box
                display='flex'
                flexDirection='column'
                flex={1}
                gap={1}
                width='100%'
              >
                <Typography variant='p' component='p'>
                  Type
                </Typography>
                <Select
                  fullWidth
                  variant='filled'
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  value={tenantBike && tenantBike.type}
                  onChange={(e) =>
                    setTenantBike({
                      ...tenantBike,
                      type: e.target.value,
                    })
                  }
                >
                  <MenuItem
                    value={'hourly'}
                    selected={tenantBike && tenantBike.type === 'hourly'}
                  >
                    Hourly
                  </MenuItem>
                  <MenuItem
                    value={'daily'}
                    selected={tenantBike && tenantBike.type === 'daily'}
                  >
                    Daily
                  </MenuItem>
                </Select>
              </Box>
              <Box
                display='flex'
                flexDirection='column'
                gridColumn='span 4'
                gap={1}
                width='100%'
              >
                <Typography variant='p' component='p'>
                  Rate *
                </Typography>
                <TextareaAutosize
                  minRows={8}
                  value={tenantBike && tenantBike.rate}
                  onChange={(e) =>
                    setTenantBike({
                      ...tenantBike,
                      rate: e.target.value,
                    })
                  }
                />
              </Box>
              <Box
                display='flex'
                flexDirection='column'
                gridColumn='span 4'
                gap={1}
                width='100%'
              >
                <Typography variant='p' component='p'>
                  Checkin rate to display on ticket*
                </Typography>
                <TextField
                  fullWidth
                  variant='filled'
                  type='text'
                  name='info'
                  sx={{ gridColumn: 'span 4' }}
                  value={tenantBike ? tenantBike.info : ''}
                  onChange={(e) =>
                    setTenantBike({
                      ...tenantBike,
                      info: e.target.value,
                    })
                  }
                />
              </Box>
              <Box
                display='flex'
                flexDirection={isNonMobile ? 'row' : 'column'}
                gridColumn='span 4'
                gap={4}
                marginTop={isNonMobile ? 2 : 1}
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Night Charge
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='night'
                    value={tenantBike ? tenantBike.night : 0}
                    onChange={(e) =>
                      setTenantBike({
                        ...tenantBike,
                        night: Number(e.target.value),
                      })
                    }
                  />
                </Box>
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Holiday Mode
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='holiday'
                    value={tenantBike ? tenantBike.holiday : 0}
                    onChange={(e) =>
                      setTenantBike({
                        ...tenantBike,
                        holiday: Number(e.target.value),
                      })
                    }
                  />
                </Box>
              </Box>
              <Box
                display='flex'
                flexDirection={isNonMobile ? 'row' : 'column'}
                gridColumn='span 4'
                gap={4}
                marginTop={isNonMobile ? 2 : 1}
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (daily day)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='dailyDayPass'
                    value={tenantBike ? tenantBike.dailyDayPass : 0}
                    onChange={(e) =>
                      setTenantBike({
                        ...tenantBike,
                        dailyDayPass: Number(e.target.value),
                      })
                    }
                  />
                </Box>
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (daily night)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='dailyNightPass'
                    value={tenantBike ? tenantBike.dailyNightPass : 0}
                    onChange={(e) =>
                      setTenantBike({
                        ...tenantBike,
                        dailyNightPass: Number(e.target.value),
                      })
                    }
                  />
                </Box>
              </Box>
              <Box
                display='flex'
                flexDirection={isNonMobile ? 'row' : 'column'}
                gridColumn='span 4'
                gap={4}
                marginTop={isNonMobile ? 2 : 1}
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (weekly day)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='weeklyDayPass'
                    value={tenantBike ? tenantBike.weeklyDayPass : 0}
                    onChange={(e) =>
                      setTenantBike({
                        ...tenantBike,
                        weeklyDayPass: Number(e.target.value),
                      })
                    }
                  />
                </Box>
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (weekly night)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='weeklyNightPass'
                    value={tenantBike ? tenantBike.weeklyNightPass : 0}
                    onChange={(e) =>
                      setTenantBike({
                        ...tenantBike,
                        weeklyNightPass: Number(e.target.value),
                      })
                    }
                  />
                </Box>
              </Box>
              <Box
                display='flex'
                flexDirection={isNonMobile ? 'row' : 'column'}
                gridColumn='span 4'
                gap={4}
                marginTop={isNonMobile ? 2 : 1}
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (monthly day)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='monthlyDayPass'
                    value={tenantBike ? tenantBike.monthlyDayPass : 0}
                    onChange={(e) =>
                      setTenantBike({
                        ...tenantBike,
                        monthlyDayPass: Number(e.target.value),
                      })
                    }
                  />
                </Box>
                <Box
                  display='flex'
                  flexDirection='column'
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant='p' component='p'>
                    Pass Rate (monthly night)
                  </Typography>
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    name='monthlyNightPass'
                    value={tenantBike ? tenantBike.monthlyNightPass : 0}
                    onChange={(e) =>
                      setTenantBike({
                        ...tenantBike,
                        monthlyNightPass: Number(e.target.value),
                      })
                    }
                  />
                </Box>
              </Box>
              <Box
                display='flex'
                flexDirection='column'
                gridColumn='span 4'
                gap={1}
                width='100%'
              >
                <Typography variant='p' component='p'>
                  Pass rate to display on ticket*
                </Typography>
                <TextField
                  fullWidth
                  variant='filled'
                  type='text'
                  name='passInfo'
                  sx={{ gridColumn: 'span 4' }}
                  value={tenantBike ? tenantBike.passInfo : 0}
                  onChange={(e) =>
                    setTenantBike({
                      ...tenantBike,
                      passInfo: e.target.value,
                    })
                  }
                />
              </Box>
              <Button
                type='submit'
                color='secondary'
                variant='contained'
                sx={{ fontSize: 14, alignSelf: 'flex-start', marginTop: 2 }}
                onClick={() => validate('tenant bike')}
                disabled={
                  loadingGetRateById || loadingAddRate || loadingUpdateRate
                }
              >
                Validate
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default RateById
