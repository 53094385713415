export const GET_CUSTOMERS_REQUEST = 'GET_CUSTOMERS_REQUEST'
export const GET_CUSTOMERS_SUCCESS = 'GET_CUSTOMERS_SUCCESS'
export const GET_CUSTOMERS_FAIL = 'GET_CUSTOMERS_FAIL'
export const GET_CUSTOMERS_RESET = 'GET_CUSTOMERS_RESET'

export const SEARCH_CUSTOMERS_SUCCESS = 'SEARCH_CUSTOMERS_SUCCESS'
export const SEARCH_CUSTOMERS_FAIL = 'SEARCH_CUSTOMERS_FAIL'
export const SEARCH_CUSTOMERS_REQUEST = 'SEARCH_CUSTOMERS_REQUEST'
export const SEARCH_CUSTOMERS_RESET = 'SEARCH_CUSTOMERS_RESET'

export const ADD_CUSTOMER_VEHICLE_REQUEST = 'ADD_CUSTOMER_VEHICLE_REQUEST'
export const ADD_CUSTOMER_VEHICLE_SUCCESS = 'ADD_CUSTOMER_VEHICLE_SUCCESS'
export const ADD_CUSTOMER_VEHICLE_FAIL = 'ADD_CUSTOMER_VEHICLE_FAIL'
export const ADD_CUSTOMER_VEHICLE_RESET = 'ADD_CUSTOMER_VEHICLE_RESET'

export const ADD_CUSTOMER_PARKING_REQUEST = 'ADD_CUSTOMER_PARKING_REQUEST'
export const ADD_CUSTOMER_PARKING_SUCCESS = 'ADD_CUSTOMER_PARKING_SUCCESS'
export const ADD_CUSTOMER_PARKING_FAIL = 'ADD_CUSTOMER_PARKING_FAIL'
export const ADD_CUSTOMER_PARKING_RESET = 'ADD_CUSTOMER_PARKING_RESET'

export const ADD_CUSTOMER_DETAILS_REQUEST = 'ADD_CUSTOMER_DETAILS_REQUEST'
export const ADD_CUSTOMER_DETAILS_SUCCESS = 'ADD_CUSTOMER_DETAILS_SUCCESS'
export const ADD_CUSTOMER_DETAILS_FAIL = 'ADD_CUSTOMER_DETAILS_FAIL'
export const ADD_CUSTOMER_DETAILS_RESET = 'ADD_CUSTOMER_DETAILS_RESET'

export const ADD_EMPLOYEE_REQUEST = 'ADD_EMPLOYEE_REQUEST'
export const ADD_EMPLOYEE_SUCCESS = 'ADD_EMPLOYEE_SUCCESS'
export const ADD_EMPLOYEE_FAIL = 'ADD_EMPLOYEE_FAIL'
export const ADD_EMPLOYEE_RESET = 'ADD_EMPLOYEE_RESET'


export const EDIT_CUSTOMER_DETAILS_REQUEST = 'EDIT_CUSTOMER_DETAILS_REQUEST'
export const EDIT_CUSTOMER_DETAILS_SUCCESS = 'EDIT_CUSTOMER_DETAILS_SUCCESS'
export const EDIT_CUSTOMER_DETAILS_FAIL = 'EDIT_CUSTOMER_DETAILS_FAIL'
export const EDIT_CUSTOMER_DETAILS_RESET = 'EDIT_CUSTOMER_DETAILS_RESET'

export const EDIT_CUSTOMER_VEHICLE_REQUEST = 'EDIT_CUSTOMER_VEHICLE_REQUEST'
export const EDIT_CUSTOMER_VEHICLE_SUCCESS = 'EDIT_CUSTOMER_VEHICLE_SUCCESS'
export const EDIT_CUSTOMER_VEHICLE_FAIL = 'EDIT_CUSTOMER_VEHICLE_FAIL'
export const EDIT_CUSTOMER_VEHICLE_RESET = 'EDIT_CUSTOMER_VEHICLE_RESET'

export const EDIT_CUSTOMER_PARKING_REQUEST = 'EDIT_CUSTOMER_PARKING_REQUEST'
export const EDIT_CUSTOMER_PARKING_SUCCESS = 'EDIT_CUSTOMER_PARKING_SUCCESS'
export const EDIT_CUSTOMER_PARKING_FAIL = 'EDIT_CUSTOMER_PARKING_FAIL'
export const EDIT_CUSTOMER_PARKING_RESET = 'EDIT_CUSTOMER_PARKING_RESET'