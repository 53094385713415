import { useEffect } from 'react'
import { Box, Button, TextField } from '@mui/material'
import { Formik } from 'formik'
import * as yup from 'yup'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { login } from '../actions/adminActions'

const Login = () => {
  const isNonMobile = useMediaQuery('(min-width:600px)')

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleFormSubmit = (values) => {
    dispatch(login(values.email, values.password))
  }

  const adminLogin = useSelector((state) => state.adminLogin)
  const { loadingAdminInfo, adminInfo, errorAdminInfo } = adminLogin

  useEffect(() => {
    if (adminInfo) {
      toast.success('You are logged in successfully !!')
      navigate('/dashboard')
    } else if (errorAdminInfo) {
      toast.error(errorAdminInfo)
    }
  }, [adminInfo, errorAdminInfo])

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        textAlign: 'center',
      }}
    >
      <Box m='20px' sx={{ width: 300 }}>
        <h1>ParkOye</h1>
        <Formik
          onSubmit={handleFormSubmit}
          initialValues={initialValues}
          validationSchema={checkoutSchema}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box
                display='grid'
                gap='30px'
                gridTemplateColumns='repeat(4, minmax(0, 1fr))'
                sx={{
                  '& > div': { gridColumn: isNonMobile ? undefined : 'span 4' },
                }}
              >
                <TextField
                  fullWidth
                  variant='filled'
                  type='text'
                  label='Email'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email}
                  name='email'
                  error={!!touched.email && !!errors.email}
                  helperText={touched.email && errors.email}
                  sx={{ gridColumn: 'span 4' }}
                  disabled={loadingAdminInfo}
                />
                <TextField
                  fullWidth
                  variant='filled'
                  type='password'
                  label='Password'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.password}
                  name='password'
                  error={!!touched.password && !!errors.password}
                  helperText={touched.password && errors.password}
                  sx={{ gridColumn: 'span 4' }}
                  disabled={loadingAdminInfo}
                />
              </Box>
              <Box mt='20px' display='flex' sx={{ height: 42 }}>
                <Button
                  type='submit'
                  color='secondary'
                  variant='contained'
                  sx={{ width: '100%', fontSize: 16 }}
                  disabled={loadingAdminInfo}
                >
                  Login
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </div>
  )
}

const checkoutSchema = yup.object().shape({
  email: yup.string().email('invalid email').required('required'),
  password: yup.string().required('required').min(4),
})
const initialValues = {
  email: '',
  password: '',
}

export default Login
