import { Box, useTheme } from '@mui/material'
import React from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'
import { tokens } from '../theme'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'

const Table = ({
  onPageChange,
  total,
  rows,
  columns,
  onRowClick,
  getRowId,
  marginBottom,
  page,
  checkboxSelection = false,
  onSelectionModelChange,
}) => {
  const isNonMobile = useMediaQuery('(min-width:600px)')
  const theme = useTheme()
  const colors = tokens(theme.palette.mode)

  return (
    <Box
      width='100%'
      m='40px 0 0 0'
      height={isNonMobile ? '65vh' : '60vh'}
      sx={{
        '& .MuiDataGrid-root': {
          border: 'none',
        },
        '& .MuiDataGrid-cell': {
          borderBottom: 'none',
        },
        '& .name-column--cell': {
          color: colors.greenAccent[300],
        },
        '& .MuiDataGrid-columnHeaders': {
          backgroundColor: colors.blueAccent[700],
          borderBottom: 'none',
        },
        '& .MuiDataGrid-virtualScroller': {
          backgroundColor: colors.primary[400],
        },
        '& .MuiDataGrid-footerContainer': {
          borderTop: 'none',
          backgroundColor: colors.blueAccent[700],
        },
        '& .MuiCheckbox-root': {
          color: `${colors.greenAccent[200]} !important`,
        },
        '& .MuiDataGrid-toolbarContainer .MuiButton-text': {
          color: `${colors.grey[100]} !important`,
        },
      }}
      marginBottom={marginBottom}
    >
      <DataGrid
        onPageChange={onPageChange}
        onRowClick={onRowClick}
        rows={rows}
        columns={columns}
        components={{ Toolbar: GridToolbar }}
        getRowId={getRowId}
        pageSize={25}
        rowsPerPageOptions={[25]}
        rowCount={total}
        pagination
        paginationMode='server'
        page={page - 1}
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
        disableSelectionOnClick
        checkboxSelection={checkboxSelection}
        onSelectionModelChange={onSelectionModelChange}
      />
    </Box>
  )
}

export default Table
