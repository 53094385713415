import axios from 'axios'
import {
  ADD_LOCATION_FAIL,
  ADD_LOCATION_REQUEST,
  ADD_LOCATION_SUCCESS,
  GET_LOCATIONS_FAIL,
  GET_LOCATIONS_FOR_ADMINS_FAIL,
  GET_LOCATIONS_FOR_ADMINS_REQUEST,
  GET_LOCATIONS_FOR_ADMINS_SUCCESS,
  GET_LOCATIONS_REQUEST,
  GET_LOCATIONS_SUCCESS,
  GET_LOCATION_BY_ID_FAIL,
  GET_LOCATION_BY_ID_REQUEST,
  GET_LOCATION_BY_ID_SUCCESS,
  UPDATE_LOCATION_FAIL,
  UPDATE_LOCATION_REQUEST,
  UPDATE_LOCATION_SUCCESS,
} from '../constants/locationConstants'

export const getLocations = (page, name, gst) => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_LOCATIONS_REQUEST,
    })

    const {
      adminLogin: { adminInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${adminInfo.token}`,
      },
    }

    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/locations?page=${page}&name=${name}&gst=${gst}`,
      config
    )

    dispatch({
      type: GET_LOCATIONS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: GET_LOCATIONS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getLocationsForAdmins = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_LOCATIONS_FOR_ADMINS_REQUEST,
    })

    const {
      adminLogin: { adminInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${adminInfo.token}`,
      },
    }

    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/locations/admins`,
      config
    )

    dispatch({
      type: GET_LOCATIONS_FOR_ADMINS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: GET_LOCATIONS_FOR_ADMINS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getLocationById = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_LOCATION_BY_ID_REQUEST,
    })

    const {
      adminLogin: { adminInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${adminInfo.token}`,
      },
    }

    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/locations?id=${id}`,
      config
    )

    dispatch({
      type: GET_LOCATION_BY_ID_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: GET_LOCATION_BY_ID_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const updateLocation =
  (
    id,
    name,
    address,
    latitude,
    longitude,
    contractStartedOn,
    contractEndedOn,
    gst,
    modules,
    vehicles,
    inventoryCompanies,
    inventoryGeneral,
    businessType,
    displayName,
    rateToDisplay,
    note,
    showOnApp,
    rate
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: UPDATE_LOCATION_REQUEST,
      })

      const {
        adminLogin: { adminInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${adminInfo.token}`,
        },
      }

      const { data } = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/locations/${id}`,
        {
          name,
          address,
          latitude,
          longitude,
          contractStartedOn,
          contractEndedOn,
          gst,
          modules,
          vehicles,
          inventoryCompanies,
          inventoryGeneral,
          businessType,
          displayName,
          rateToDisplay,
          note,
          showOnApp,
          rate,
        },
        config
      )

      dispatch({
        type: UPDATE_LOCATION_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: UPDATE_LOCATION_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const addLocation =
  (
    name,
    address,
    latitude,
    longitude,
    contractStartedOn,
    contractEndedOn,
    gst,
    modules,
    vehicles,
    inventoryCompanies,
    inventoryGeneral,
    businessType,
    displayName,
    rateToDisplay,
    note,
    showOnApp,
    rate
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: ADD_LOCATION_REQUEST,
      })

      const {
        adminLogin: { adminInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${adminInfo.token}`,
        },
      }

      const { data } = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/locations`,
        {
          name,
          address,
          latitude,
          longitude,
          contractStartedOn,
          contractEndedOn,
          gst,
          modules,
          vehicles,
          inventoryCompanies,
          inventoryGeneral,
          businessType,
          displayName,
          rateToDisplay,
          note,
          showOnApp,
          rate,
        },
        config
      )

      dispatch({
        type: ADD_LOCATION_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: ADD_LOCATION_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }
