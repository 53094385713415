import axios from 'axios'
import {
  GET_ENTRIES_FAIL,
  GET_ENTRIES_REQUEST,
  GET_ENTRIES_SUCCESS,
} from '../constants/entryConstants'

export const getEntries = (page) => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_ENTRIES_REQUEST,
    })

    const {
      adminLogin: { adminInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${adminInfo.token}`,
      },
    }

    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/entries?page=${page}`,
      config
    )

    dispatch({
      type: GET_ENTRIES_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: GET_ENTRIES_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
