import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import moment from "moment";
import { useNavigate } from "react-router-dom";

import { logout } from "../actions/adminActions";
import { getInvites } from "../actions/inviteActions";
import { GET_INVITES_RESET } from "../constants/inviteConstants";
import Header from "../components/Header";
import Table from "../components/Table";
import Loader from "../components/Loader";

const Invites = () => {
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const adminLogin = useSelector((state) => state.adminLogin);
  const { adminInfo } = adminLogin;

  useEffect(() => {
    if (!adminInfo) {
      dispatch(logout(navigate));
    }
  }, [adminInfo]);

  useEffect(() => {
    dispatch(getInvites(page));
    setLoading(true);
  }, [page]);

  const getInvitesInfo = useSelector((state) => state.getInvitesInfo);
  const { loadingGetInvites, getInvitesData, errorGetInvites } = getInvitesInfo;

  useEffect(() => {
    dispatch({ type: GET_INVITES_RESET });
    if (getInvitesData) {
      setData(getInvitesData.data);
      setTotal(getInvitesData.total);
      setPage(+getInvitesData.page);
      setLoading(false);
    } else if (errorGetInvites) {
      toast.error(errorGetInvites);
      setLoading(false);
    }
  }, [getInvitesData, errorGetInvites]);

  const columns = [
    { field: "mobile", headerName: "Mobile", minWidth: 200 },
    {
      field: "vehicleNumber",
      headerName: "Vehicle Number",
      minWidth: 200,
      valueGetter: ({ value }) => (value ? value : "N/A"),
    },
    {
      field: "vehicleType",
      headerName: "Vehicle Type",
      minWidth: 200,
    },
    {
      field: "startTime",
      headerName: "Start Time",
      valueGetter: ({ value }) =>
        value && moment(value).format("DD-MM-YYYY HH:mm:ss"),
      minWidth: 200,
    },
    {
      field: "endTime",
      headerName: "End Time",
      valueGetter: ({ value }) =>
        value && moment(value).format("DD-MM-YYYY HH:mm:ss"),
      minWidth: 200,
    },
    {
      field: "status",
      headerName: "Checked Out",
      minWidth: 200,
    },
    {
      field: "checkin",
      headerName: "Checkin",
      valueGetter: ({ value }) => (value ? "Yes" : "No"),
      minWidth: 200,
    },
    {
      field: "location",
      headerName: "Location",
      valueGetter: ({ value }) => (value ? value.name : "N/A"),
      minWidth: 200,
    },
    {
      field: "parking",
      headerName: "Parking",
      valueGetter: ({ value }) => (value ? value.name : "N/A"),
      minWidth: 200,
    },
    {
      field: "invitedBy",
      headerName: "Invited By",
      valueGetter: ({ value }) => (value ? value.name : "N/A"),
      minWidth: 200,
    },
    {
      field: "parkingInvite",
      headerName: "Parking Invite",
      valueGetter: ({ value }) => (value && value === true ? "Yes" : "No"),
      minWidth: 200,
    },
    {
      field: "isParkingChargeable",
      headerName: "Parking Chargeable",
      valueGetter: ({ value }) => (value && value === true ? "Yes" : "No"),
      minWidth: 200,
    },
  ];

  return (
    <Box m="20px">
      <Header title="INVITES" subtitle="List of invites" />
      {loading ? (
        <Loader />
      ) : (
        <Table
          onPageChange={(newPage) => {
            setPage(newPage + 1);
          }}
          total={total}
          rows={data}
          columns={columns}
          page={page}
          getRowId={(row) => row._id}
        />
      )}
    </Box>
  );
};

export default Invites;
