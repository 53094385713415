export const GET_LOCATIONS_REQUEST = 'GET_LOCATIONS_REQUEST'
export const GET_LOCATIONS_SUCCESS = 'GET_LOCATIONS_SUCCESS'
export const GET_LOCATIONS_FAIL = 'GET_LOCATIONS_FAIL'
export const GET_LOCATIONS_RESET = 'GET_LOCATIONS_RESET'

export const GET_LOCATIONS_FOR_ADMINS_REQUEST =
  'GET_LOCATIONS_FOR_ADMINS_REQUEST'
export const GET_LOCATIONS_FOR_ADMINS_SUCCESS =
  'GET_LOCATIONS_FOR_ADMINS_SUCCESS'
export const GET_LOCATIONS_FOR_ADMINS_FAIL = 'GET_LOCATIONS_FOR_ADMINS_FAIL'
export const GET_LOCATIONS_FOR_ADMINS_RESET = 'GET_LOCATIONS_FOR_ADMINS_RESET'

export const GET_LOCATION_BY_ID_REQUEST = 'GET_LOCATION_BY_ID_REQUEST'
export const GET_LOCATION_BY_ID_SUCCESS = 'GET_LOCATION_BY_ID_SUCCESS'
export const GET_LOCATION_BY_ID_FAIL = 'GET_LOCATION_BY_ID_FAIL'
export const GET_LOCATION_BY_ID_RESET = 'GET_LOCATION_BY_ID_RESET'

export const UPDATE_LOCATION_REQUEST = 'UPDATE_LOCATION_REQUEST'
export const UPDATE_LOCATION_SUCCESS = 'UPDATE_LOCATION_SUCCESS'
export const UPDATE_LOCATION_FAIL = 'UPDATE_LOCATION_FAIL'
export const UPDATE_LOCATION_RESET = 'UPDATE_LOCATION_RESET'

export const ADD_LOCATION_REQUEST = 'ADD_LOCATION_REQUEST'
export const ADD_LOCATION_SUCCESS = 'ADD_LOCATION_SUCCESS'
export const ADD_LOCATION_FAIL = 'ADD_LOCATION_FAIL'
export const ADD_LOCATION_RESET = 'ADD_LOCATION_RESET'
