import axios from "axios";
import {
  DOWNLOAD_PASSES_FAIL,
  DOWNLOAD_PASSES_REQUEST,
  DOWNLOAD_PASSES_SUCCESS,
  GET_PASSES_FAIL,
  GET_PASSES_REQUEST,
  GET_PASSES_SUCCESS,
  CREATE_PASS_REQUEST,
  CREATE_PASS_SUCCESS,
  CREATE_PASS_FAIL,
} from "../constants/passConstants";

export const getPasses =
  (page, mobile, vehicleNumber, vehicleType, isExpired, from, to) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: GET_PASSES_REQUEST,
      });

      const {
        adminLogin: { adminInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${adminInfo.token}`,
        },
      };

      const { data } = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/passes/admins?page=${page}&mobile=${mobile}&vehicleNumber=${vehicleNumber}&vehicleType=${vehicleType}&isExpired=${isExpired}&from=${from}&to=${to}`,
        config
      );

      dispatch({
        type: GET_PASSES_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_PASSES_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const createPass =
  (vehicleNumber, vehicleType, mobile, startTime, endTime, amount, parkingId, location) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: CREATE_PASS_REQUEST,
      });

      const {
        adminLogin: { adminInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${adminInfo.token}`,
        },
      };
      console.log(
        `${process.env.REACT_APP_BASE_URL}/passes`,
        { vehicleNumber, vehicleType, mobile, startTime, endTime, amount, parkingId,location },
        config
      );
      const { data } = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/passes/admins`,
        { vehicleNumber, vehicleType, mobile, startTime, endTime, amount, parkingId, locationId: location },
        config
      );

      dispatch({
        type: CREATE_PASS_SUCCESS,
        payload: data,
      });
      dispatch(getPasses());
    } catch (error) {
      dispatch({
        type: CREATE_PASS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const downloadPasses =
  (mobile, vehicleNumber, vehicleType, isExpired, from, to) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: DOWNLOAD_PASSES_REQUEST,
      });

      const {
        adminLogin: { adminInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${adminInfo.token}`,
        },
      };

      const { data } = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/passes/download`,
        { mobile, vehicleNumber, vehicleType, isExpired, from, to },
        config
      );

      dispatch({
        type: DOWNLOAD_PASSES_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: DOWNLOAD_PASSES_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
