export const GET_USERS_REQUEST = 'GET_USERS_REQUEST'
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS'
export const GET_USERS_FAIL = 'GET_USERS_FAIL'
export const GET_USERS_RESET = 'GET_USERS_RESET'

export const EDIT_USER_REQUEST = 'EDIT_USER_REQUEST'
export const EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS'
export const EDIT_USER_FAIL = 'EDIT_USER_FAIL'
export const EDIT_USER_RESET = 'EDIT_USER_RESET'

export const GENERATE_PASSWORD_USER_REQUEST = 'GENERATE_PASSWORD_USER_REQUEST'
export const GENERATE_PASSWORD_USER_SUCCESS = 'GENERATE_PASSWORD_USER_SUCCESS'
export const GENERATE_PASSWORD_USER_FAIL = 'GENERATE_PASSWORD_USER_FAIL'
export const GENERATE_PASSWORD_USER_RESET = 'GENERATE_PASSWORD_USER_RESET'

export const RESET_DEVICE_ID_USER_REQUEST = 'RESET_DEVICE_ID_USER_REQUEST'
export const RESET_DEVICE_ID_USER_SUCCESS = 'RESET_DEVICE_ID_USER_SUCCESS'
export const RESET_DEVICE_ID_USER_FAIL = 'RESET_DEVICE_ID_USER_FAIL'
export const RESET_DEVICE_ID_USER_RESET = 'RESET_DEVICE_ID_USER_RESET'

export const ADD_USER_REQUEST = 'ADD_USER_REQUEST'
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS'
export const ADD_USER_FAIL = 'ADD_USER_FAIL'
export const ADD_USER_RESET = 'ADD_USER_RESET'
