import axios from 'axios';
import {
  ADD_USER_FAIL,
  ADD_USER_REQUEST,
  ADD_USER_SUCCESS,
  EDIT_USER_FAIL,
  EDIT_USER_REQUEST,
  EDIT_USER_SUCCESS,
  GENERATE_PASSWORD_USER_FAIL,
  GENERATE_PASSWORD_USER_REQUEST,
  GENERATE_PASSWORD_USER_SUCCESS,
  GET_USERS_FAIL,
  GET_USERS_REQUEST,
  GET_USERS_SUCCESS,
  RESET_DEVICE_ID_USER_FAIL,
  RESET_DEVICE_ID_USER_REQUEST,
  RESET_DEVICE_ID_USER_SUCCESS,
} from '../constants/userConstants';

export const getUsers =
  (page, username, name, role) => async (dispatch, getState) => {
    try {
      dispatch({
        type: GET_USERS_REQUEST,
      });

      const {
        adminLogin: { adminInfo },
      } = getState();

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${adminInfo.token}`,
        },
      };

      const { data } = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/users?page=${page}&username=${username}&name=${name}&role=${role}`,
        config
      );

      dispatch({
        type: GET_USERS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_USERS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const editUser =
  (id, name, status, parking, iotDeviceId) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: EDIT_USER_REQUEST,
      });

      const {
        adminLogin: { adminInfo },
      } = getState();

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${adminInfo.token}`,
        },
      };

      const { data } = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/users/${id}`,
        { name, status, parking, iotDeviceId },
        config
      );

      dispatch({
        type: EDIT_USER_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: EDIT_USER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const generatePasswordUser =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({
        type: GENERATE_PASSWORD_USER_REQUEST,
      });

      const {
        adminLogin: { adminInfo },
      } = getState();

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${adminInfo.token}`,
        },
      };

      const { data } = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/users/${id}/generate-password`,
        {},
        config
      );

      dispatch({
        type: GENERATE_PASSWORD_USER_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GENERATE_PASSWORD_USER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const resetDeviceIdUser =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({
        type: RESET_DEVICE_ID_USER_REQUEST,
      });

      const {
        adminLogin: { adminInfo },
      } = getState();

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${adminInfo.token}`,
        },
      };

      const { data } = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/users/${id}/reset-device-id`,
        {},
        config
      );

      dispatch({
        type: RESET_DEVICE_ID_USER_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: RESET_DEVICE_ID_USER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const addUser =
  (name, username, location, role, parking, iotDeviceId) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: ADD_USER_REQUEST,
      });

      const {
        adminLogin: { adminInfo },
      } = getState();

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${adminInfo.token}`,
        },
      };

      const { data } = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/users`,
        { name, username, location, role, parking, iotDeviceId },
        config
      );

      dispatch({
        type: ADD_USER_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ADD_USER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
