import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { logout } from "../actions/adminActions";
import { getLocationsForAdmins } from "../actions/locationActions";
import Header from "../components/Header";
import Loader from "../components/Loader";
import { GET_LOCATIONS_FOR_ADMINS_RESET } from "../constants/locationConstants";
import {
  addParking,
  getParkingById,
  updateParking,
} from "../actions/parkingActions";
import {
  ADD_PARKING_RESET,
  GET_PARKING_BY_ID_RESET,
  UPDATE_PARKING_RESET,
} from "../constants/parkingConstants";
import { VEHICLES } from "../constants";
import { getRatesForAdmins } from "../actions/rateActions";
import { GET_RATES_FOR_ADMINS_RESET } from "../constants/rateConstants";

const ParkingById = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const [loading, setLoading] = useState(true);
  const [allLocations, setAllLocations] = useState([]);
  const [location, setLocation] = useState("");
  const [businessType, setBusinessType] = useState("");
  const [name, setName] = useState("");
  const [whiteListing, setWhiteListing] = useState(false);
  const [visitorManagement, setVisitorManagement] = useState(false);
  const [foc, setFoc] = useState(false);
  const [inventoryEliteTotal, setInventoryEliteTotal] = useState(0);
  const [inventoryEliteFreeTime, setInventoryEliteFreeTime] = useState(480);
  const [inventoryGeneralTotal, setInventoryGeneralTotal] = useState(0);
  const [inventoryGeneralFreeTime, setInventoryGeneralFreeTime] = useState(480);
  const [receiptDetailsName, setReceiptDetailsName] = useState("");
  const [bannerText, setBannerText] = useState("");
  const [footerText, setFooterText] = useState("");
  const [barcode, setBarcode] = useState(false);
  const [qrcode, setQrcode] = useState(false);
  const [fontSize, setFontSize] = useState("Normal");
  const [reporting, setReporting] = useState(false);
  const [statesOption, setStatesOption] = useState(false);
  const [displayMobile, setDisplayMobile] = useState(false);
  const [autoPrint, setAutoPrint] = useState(false);
  const [autoBack, setAutoBack] = useState(false);
  const [pass, setPass] = useState(false);
  const [dailyDayPass, setDailyDayPass] = useState(false);
  const [dailyNightPass, setDailyNightPass] = useState(false);
  const [weeklyDayPass, setWeeklyDayPass] = useState(false);
  const [weeklyNightPass, setWeeklyNightPass] = useState(false);
  const [monthlyDayPass, setMonthlyDayPass] = useState(false);
  const [monthlyNightPass, setMonthlyNightPass] = useState(false);
  const [customPass, setCustomPass] = useState(false);
  const [nightCharge, setNightCharge] = useState(false);
  const [nightChargeStartTime, setNightChargeStartTime] = useState("00:00");
  const [nightChargeEndTime, setNightChargeEndTime] = useState("05:00");
  const [reportAdjustment, setReportAdjustment] = useState(false);
  const [percentage, setPercentage] = useState(0);
  const [prepaid, setPrepaid] = useState(false);
  const [resetCheckins, setResetCheckins] = useState(false);
  const [holidayMode, setHolidayMode] = useState(false);
  const [ocr, setOcr] = useState(false);
  const [onlinePayments, setOnlinePayments] = useState(false);
  const [advance, setAdvance] = useState(false);
  const [vehicles, setVehicles] = useState([]);
  const [rates, setRates] = useState(null);
  const [allRates, setAllRates] = useState([]);

  const adminLogin = useSelector((state) => state.adminLogin);
  const { adminInfo } = adminLogin;

  useEffect(() => {
    if (!adminInfo) {
      dispatch(logout(navigate));
    }
  }, [adminInfo]);

  useEffect(() => {
    if (params?.id && params.id !== "add") {
      dispatch(getParkingById(params.id));
      // setLoading(true)
    }
  }, [params?.id]);

  const getParkingByIdInfo = useSelector((state) => state.getParkingByIdInfo);
  const { loadingGetParkingById, getParkingByIdData, errorGetParkingById } =
    getParkingByIdInfo;

  useEffect(() => {
    dispatch({ type: GET_PARKING_BY_ID_RESET });
    console.log("getParkingByIdDatagetParkingByIdData", getParkingByIdData);
    if (getParkingByIdData) {
      const parking = getParkingByIdData.data[0];

      setName(parking?.name);
      setLocation(parking?.location?._id);
      setBusinessType(parking?.location?.businessType);
      setFoc(parking?.modules?.foc);
      setWhiteListing(parking?.modules?.whiteListing);
      setVisitorManagement(parking?.modules?.visitorManagement);
      setInventoryEliteTotal(parking?.inventory?.elite?.total);
      setInventoryEliteFreeTime(parking?.inventory?.elite?.freeTime);
      setInventoryGeneralTotal(parking?.inventory?.general?.total);
      setInventoryGeneralFreeTime(parking?.inventory?.general?.freeTime);
      setReceiptDetailsName(parking?.receiptDetails?.name);
      setBannerText(parking?.receiptDetails?.bannerText);
      setFooterText(parking?.receiptDetails?.footerText);
      setBarcode(parking?.receiptDetails?.barcode);
      setQrcode(parking?.receiptDetails?.qrcode);
      setFontSize(parking?.receiptDetails?.fontSize);
      setReporting(parking?.attendantAppSettings?.reporting);
      setStatesOption(parking?.attendantAppSettings?.statesOption);
      setDisplayMobile(parking?.attendantAppSettings?.displayMobile);
      setAutoPrint(parking?.attendantAppSettings?.autoPrint);
      setAutoBack(parking?.attendantAppSettings?.autoBack);
      setPass(parking?.pass?.status);
      setDailyDayPass(parking?.pass?.dailyDayPass);
      setDailyNightPass(parking?.pass?.dailyNightPass);
      setWeeklyDayPass(parking?.pass?.weeklyDayPass);
      setWeeklyNightPass(parking?.pass?.weeklyNightPass);
      setMonthlyDayPass(parking?.pass?.monthlyDayPass);
      setMonthlyNightPass(parking?.pass?.monthlyNightPass);
      setCustomPass(parking?.pass?.customPass);
      setNightCharge(parking?.nightCharge?.status);
      setNightChargeStartTime(parking?.nightCharge?.startTime);
      setNightChargeEndTime(parking?.nightCharge?.endTime);
      setReportAdjustment(parking?.reportAdjustment?.status);
      setPercentage(parking?.reportAdjustment?.percentage);
      setPrepaid(parking?.prepaid);
      setResetCheckins(parking?.resetCheckins);
      setHolidayMode(parking?.HolidayMode);
      setOcr(parking?.ocr);
      setOnlinePayments(parking?.onlinePayments);
      setAdvance(parking?.advance);
      setVehicles(parking?.vehicles.map((el) => el.toUpperCase()));
      setRates(parking?.rates);

      setLoading(false);
    } else if (errorGetParkingById) {
      console.log(
        "errorGetParkingByIderrorGetParkingById",
        errorGetParkingById
      );
      setLoading(false);
      toast.error(errorGetParkingById);
      navigate("/companies");
    }
  }, [getParkingByIdData, errorGetParkingById]);

  useEffect(() => {
    dispatch(getLocationsForAdmins());
    // setLoading(true)
  }, []);

  const getLocationsForAdminsInfo = useSelector(
    (state) => state.getLocationsForAdminsInfo
  );
  const {
    loadingGetLocationsForAdmins,
    getLocationsForAdminsData,
    errorGetLocationsForAdmins,
  } = getLocationsForAdminsInfo;

  useEffect(() => {
    dispatch({ type: GET_LOCATIONS_FOR_ADMINS_RESET });
    if (getLocationsForAdminsData) {
      setAllLocations(getLocationsForAdminsData);
      setLoading(false);
    } else if (errorGetLocationsForAdmins) {
      toast.error(errorGetLocationsForAdmins);
      setLoading(false);
    }
  }, [getLocationsForAdminsData, errorGetLocationsForAdmins]);

  useEffect(() => {
    if (location) {
      const locationFound = allLocations.find((el) => {
        return el._id.toString() === location;
      });
      console.log("locationlocationlocationlocation", location, locationFound);
      if (locationFound) {
        setBusinessType(locationFound?.businessType);
      }
    }
  }, [location]);

  useEffect(() => {
    if (barcode) {
      setQrcode(false);
    }
  }, [barcode]);

  useEffect(() => {
    if (qrcode) {
      setBarcode(false);
    }
  }, [qrcode]);

  useEffect(() => {
    if (!pass) {
      setCustomPass(false);
      setDailyDayPass(false);
      setDailyNightPass(false);
      setWeeklyDayPass(false);
      setWeeklyNightPass(false);
      setMonthlyDayPass(false);
      setMonthlyNightPass(false);
    }
  }, [pass]);

  const submitHandler = () => {
    if (!location || !name) {
      toast.error("All the fields are mandatory");
      return;
    }

    if (businessType === "Unorganised") {
      if (
        !receiptDetailsName ||
        !bannerText ||
        !footerText ||
        !fontSize ||
        !rates
      ) {
        toast.error("Receipt details are mandatory");
        return;
      }

      if (nightCharge && (!nightChargeStartTime || !nightChargeEndTime)) {
        toast.error("Night charge details are mandatory");
        return;
      }

      if (reportAdjustment && !percentage) {
        toast.error("Report adjustment details are mandatory");
        return;
      }

      if (!pass) {
        setCustomPass(false);
        setDailyDayPass(false);
        setDailyNightPass(false);
        setWeeklyDayPass(false);
        setWeeklyNightPass(false);
        setMonthlyDayPass(false);
        setMonthlyNightPass(false);
      }

      if (vehicles.length === 0) {
        toast.error("Select atleast 1 vehicles from the list");
        return;
      }
    }

    if (businessType === "Corporate") {
      if (!whiteListing) {
        setInventoryEliteTotal(0);
        setInventoryEliteFreeTime(0);
        setInventoryGeneralTotal(0);
        setInventoryGeneralFreeTime(0);
      }
    }

    setLoading(true);
    dispatch(
      params.id === "add"
        ? addParking(
            name,
            location,
            foc,
            whiteListing,
            visitorManagement,
            inventoryEliteTotal,
            inventoryEliteFreeTime,
            inventoryGeneralTotal,
            inventoryGeneralFreeTime,
            vehicles,
            {
              name: receiptDetailsName,
              bannerText,
              footerText,
              barcode,
              qrcode,
              fontSize,
            },
            {
              reporting,
              statesOption,
              displayMobile,
              autoPrint,
              autoBack,
            },
            rates,
            {
              status: pass,
              customPass,
              dailyDayPass,
              dailyNightPass,
              weeklyDayPass,
              weeklyNightPass,
              monthlyDayPass,
              monthlyNightPass,
            },
            {
              status: nightCharge,
              startTime: nightChargeStartTime,
              endTime: nightChargeEndTime,
            },
            prepaid,
            {
              status: reportAdjustment,
              percentage: Number(percentage),
            },
            resetCheckins,
            holidayMode,
            ocr,
            onlinePayments,
            advance
          )
        : updateParking(
            params.id,
            name,
            foc,
            whiteListing,
            visitorManagement,
            inventoryEliteTotal,
            inventoryEliteFreeTime,
            inventoryGeneralTotal,
            inventoryGeneralFreeTime,
            vehicles,
            {
              name: receiptDetailsName,
              bannerText,
              footerText,
              barcode,
              qrcode,
              fontSize,
            },
            {
              reporting,
              statesOption,
              displayMobile,
              autoPrint,
              autoBack,
            },
            rates,
            {
              status: pass,
              customPass,
              dailyDayPass,
              dailyNightPass,
              weeklyDayPass,
              weeklyNightPass,
              monthlyDayPass,
              monthlyNightPass,
            },
            {
              status: nightCharge,
              startTime: nightChargeStartTime,
              endTime: nightChargeEndTime,
            },
            prepaid,
            {
              status: reportAdjustment,
              percentage: Number(percentage),
            },
            resetCheckins,
            holidayMode,
            ocr,
            onlinePayments,
            advance
          )
    );
  };

  const addParkingInfo = useSelector((state) => state.addParkingInfo);
  const { loadingAddParking, addParkingData, errorAddParking } = addParkingInfo;

  useEffect(() => {
    dispatch({ type: ADD_PARKING_RESET });
    if (addParkingData) {
      setLoading(false);
      toast.success("Company added successfully");
      navigate("/companies");
    } else if (errorAddParking) {
      setLoading(false);
      toast.error(errorAddParking);
    }
  }, [addParkingData, errorAddParking]);

  const updateParkingInfo = useSelector((state) => state.updateParkingInfo);
  const { loadingUpdateParking, updateParkingData, errorUpdateParking } =
    updateParkingInfo;

  useEffect(() => {
    dispatch({ type: UPDATE_PARKING_RESET });
    if (updateParkingData) {
      setLoading(false);
      toast.success("Company updated successfully");
      navigate("/companies/" + params.id);
    } else if (errorUpdateParking) {
      setLoading(false);
      toast.error(errorUpdateParking);
    }
  }, [updateParkingData, errorUpdateParking]);

  useEffect(() => {
    dispatch(getRatesForAdmins());
    setLoading(true);
  }, []);

  const getRatesForAdminsInfo = useSelector(
    (state) => state.getRatesForAdminsInfo
  );
  const {
    loadingGetRatesForAdmins,
    getRatesForAdminsData,
    errorGetRatesForAdmins,
  } = getRatesForAdminsInfo;

  useEffect(() => {
    dispatch({ type: GET_RATES_FOR_ADMINS_RESET });
    if (getRatesForAdminsData) {
      setAllRates(getRatesForAdminsData);
      setLoading(false);
    } else if (errorGetRatesForAdmins) {
      toast.error(errorGetRatesForAdmins);
      setLoading(false);
    }
  }, [getRatesForAdminsData, errorGetRatesForAdmins]);

  return (
    <Box m="20px">
      <Header
        title={params?.id === "add" ? "Add Company" : name}
        subtitle="Details of the company"
      />
      {loading ? (
        <Loader />
      ) : (
        <Box width="100%" m="40px 0 0 0">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{ width: "100%" }}
          >
            <Typography
              id="keep-mounted-modal-title"
              variant="h3"
              component="h2"
            >
              Basic Details
            </Typography>
            <Button
              type="submit"
              color="secondary"
              variant="contained"
              sx={{ fontSize: 14 }}
              onClick={submitHandler}
              disabled={loading}
            >
              {params?.id === "add" ? "Add" : "Update"}
            </Button>
          </Box>
          <Box
            display="grid"
            gap={2}
            gridTemplateColumns="repeat(4, minmax(0,
        1fr))"
            sx={{
              "& > div": {
                gridColumn: isNonMobile ? undefined : "span 4",
              },
              marginBottom: 6,
              marginTop: "20px",
            }}
          >
            <Box
              display="flex"
              flexDirection="column"
              gridColumn="span 4"
              gap={1}
            >
              <Typography variant="p" component="p">
                Location
              </Typography>
              <Select
                fullWidth
                variant="filled"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={location}
                label="Business Type"
                onChange={(e) => setLocation(e.target.value)}
                disabled={loading || params.id !== "add"}
              >
                {allLocations.map((item) => (
                  <MenuItem
                    value={item._id}
                    key={item._id}
                    selected={location === item._id}
                  >
                    {item.name} - ({item.businessType})
                  </MenuItem>
                ))}
              </Select>
            </Box>
            {businessType && (
              <Box
                display="flex"
                flexDirection="column"
                gridColumn="span 4"
                gap={1}
              >
                <Typography variant="p" component="p">
                  Name
                </Typography>
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Name"
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  name="name"
                  disabled={loading}
                  sx={{ gridColumn: "span 4" }}
                />
              </Box>
            )}
            {businessType === "Corporate" && (
              <>
                <Box
                  sx={{ gridColumn: "span 4" }}
                  display="flex"
                  flexDirection={isNonMobile ? "row" : "column"}
                  justifyContent="space-between"
                >
                  <FormControlLabel
                    label="Whitelisting"
                    control={
                      <Checkbox
                        checked={whiteListing}
                        onChange={(e) => setWhiteListing(e.target.checked)}
                        color="success"
                        disabled={loading}
                      />
                    }
                  />
                  <FormControlLabel
                    label="Visitor Management"
                    control={
                      <Checkbox
                        checked={visitorManagement}
                        onChange={(e) => setVisitorManagement(e.target.checked)}
                        color="success"
                        disabled={loading}
                      />
                    }
                  />
                  <FormControlLabel
                    label="FOC"
                    control={
                      <Checkbox
                        checked={foc}
                        onChange={(e) => setFoc(e.target.checked)}
                        color="success"
                        disabled={loading}
                      />
                    }
                  />
                </Box>
                {whiteListing && (
                  <>
                    <Box
                      display="flex"
                      flexDirection={isNonMobile ? "row" : "column"}
                      gridColumn="span 4"
                      gap={4}
                      marginTop={isNonMobile ? 2 : 1}
                      marginBottom={isNonMobile ? 2 : 4}
                    >
                      <Box
                        display="flex"
                        flexDirection="column"
                        flex={1}
                        gap={1}
                        marginTop={-2}
                      >
                        <Typography variant="p" component="p">
                          Elite parking slots
                        </Typography>
                        <TextField
                          fullWidth
                          variant="filled"
                          type="number"
                          onInput={(e) => {
                            e.target.value = Math.max(
                              0,
                              parseInt(e.target.value)
                            )
                              .toString()
                              .slice(0, 3);
                          }}
                          label="Elite Parking Slots"
                          onChange={(e) =>
                            setInventoryEliteTotal(e.target.value)
                          }
                          value={inventoryEliteTotal}
                          name="inventoryEliteTotal"
                          disabled={loading}
                        />
                      </Box>
                      <Box
                        display="flex"
                        flexDirection="column"
                        flex={1}
                        gap={1}
                        marginTop={-2}
                      >
                        <Typography variant="p" component="p">
                          Elite parking free time (in minutes)
                        </Typography>
                        <TextField
                          fullWidth
                          variant="filled"
                          type="number"
                          label="Elite Parking Free Time (in minutes)"
                          onChange={(e) =>
                            setInventoryEliteFreeTime(e.target.value)
                          }
                          value={inventoryEliteFreeTime}
                          name="inventoryEliteFreeTime"
                          disabled={loading}
                        />
                      </Box>
                    </Box>
                    <Box
                      display="flex"
                      flexDirection={isNonMobile ? "row" : "column"}
                      gridColumn="span 4"
                      gap={4}
                      marginTop={isNonMobile ? 0 : -2}
                    >
                      <Box
                        display="flex"
                        flexDirection="column"
                        flex={1}
                        gap={1}
                        marginTop={-2}
                      >
                        <Typography variant="p" component="p">
                          General parking slots
                        </Typography>
                        <TextField
                          fullWidth
                          variant="filled"
                          type="number"
                          onInput={(e) => {
                            e.target.value = Math.max(
                              0,
                              parseInt(e.target.value)
                            )
                              .toString()
                              .slice(0, 3);
                          }}
                          label="General Parking Slots"
                          onChange={(e) =>
                            setInventoryGeneralTotal(e.target.value)
                          }
                          value={inventoryGeneralTotal}
                          name="inventoryGeneralTotal"
                          disabled={loading}
                        />
                      </Box>
                      <Box
                        display="flex"
                        flexDirection="column"
                        flex={1}
                        gap={1}
                        marginTop={-2}
                      >
                        <Typography variant="p" component="p">
                          General parking free time (in minutes)
                        </Typography>
                        <TextField
                          fullWidth
                          variant="filled"
                          type="number"
                          label="General Parking Free Time (in minutes)"
                          onChange={(e) =>
                            setInventoryGeneralFreeTime(e.target.value)
                          }
                          value={inventoryGeneralFreeTime}
                          name="inventoryGeneralFreeTime"
                          disabled={loading}
                        />
                      </Box>
                    </Box>
                  </>
                )}
              </>
            )}
          </Box>
          {businessType === "Unorganised" && (
            <>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                sx={{ width: "100%" }}
                marginTop={2}
              >
                <Typography
                  id="keep-mounted-modal-title"
                  variant="h3"
                  component="h2"
                >
                  Receipt Details
                </Typography>
              </Box>
              <Box
                display="grid"
                gap={2}
                gridTemplateColumns="repeat(4, minmax(0,
        1fr))"
                sx={{
                  "& > div": {
                    gridColumn: isNonMobile ? undefined : "span 4",
                  },
                  marginBottom: 6,
                  marginTop: "20px",
                }}
              >
                <Box
                  display="flex"
                  flexDirection={isNonMobile ? "row" : "column"}
                  gridColumn="span 4"
                  gap={4}
                  marginTop={isNonMobile ? 2 : 1}
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    flex={1}
                    gap={1}
                    marginTop={-2}
                  >
                    <Typography variant="p" component="p">
                      Receipt Details Name
                    </Typography>
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Receipt Details Name"
                      onChange={(e) => setReceiptDetailsName(e.target.value)}
                      value={receiptDetailsName}
                      name="receiptDetailsName"
                      disabled={loading}
                    />
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="column"
                    flex={1}
                    gap={1}
                    marginTop={-2}
                  >
                    <Typography variant="p" component="p">
                      Banner Text
                    </Typography>
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Banner Text"
                      onChange={(e) => setBannerText(e.target.value)}
                      value={bannerText}
                      name="bannerText"
                      disabled={loading}
                    />
                  </Box>
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  gridColumn="span 4"
                  gap={1}
                >
                  <Typography variant="p" component="p">
                    Footer Text
                  </Typography>
                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Footer Text"
                    onChange={(e) => setFooterText(e.target.value)}
                    value={footerText}
                    name="footerText"
                    disabled={loading}
                    sx={{ gridColumn: "span 4" }}
                  />
                </Box>
                <Box
                  display="flex"
                  flexDirection={isNonMobile ? "row" : "column"}
                  gridColumn="span 4"
                  alignItems={"center"}
                  gap={4}
                  marginTop={isNonMobile ? 2 : 1}
                >
                  <Box
                    display="flex"
                    flexDirection="row"
                    flex={1}
                    gap={1}
                    justifyContent="space-between"
                  >
                    <FormControlLabel
                      label="Barcode"
                      control={
                        <Checkbox
                          checked={barcode}
                          onChange={(e) => setBarcode(e.target.checked)}
                          color="success"
                          disabled={loading}
                        />
                      }
                    />
                    <FormControlLabel
                      label="QR Code"
                      control={
                        <Checkbox
                          checked={qrcode}
                          onChange={(e) => setQrcode(e.target.checked)}
                          color="success"
                          disabled={loading}
                        />
                      }
                    />
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="column"
                    flex={1}
                    gap={1}
                    marginTop={-2}
                  >
                    <Typography variant="p" component="p">
                      Font Size
                    </Typography>
                    <Select
                      fullWidth
                      variant="filled"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={fontSize}
                      label="Font Size"
                      onChange={(e) => setFontSize(e.target.value)}
                      disabled={loading}
                    >
                      <MenuItem value={"Normal"}>Normal</MenuItem>
                      <MenuItem value={"Medium"}>Medium</MenuItem>
                      <MenuItem value={"Large"}>Large</MenuItem>
                    </Select>
                  </Box>
                </Box>
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                sx={{ width: "100%" }}
                marginTop={2}
              >
                <Typography
                  id="keep-mounted-modal-title"
                  variant="h3"
                  component="h2"
                >
                  Attendant Application Settings
                </Typography>
              </Box>
              <Box
                sx={{ gridColumn: "span 4" }}
                marginTop={2}
                display="flex"
                flexDirection={isNonMobile ? "row" : "column"}
                justifyContent="space-between"
              >
                <FormControlLabel
                  label="Reporting"
                  control={
                    <Checkbox
                      checked={reporting}
                      onChange={(e) => setReporting(e.target.checked)}
                      color="success"
                      disabled={loading}
                    />
                  }
                />
                <FormControlLabel
                  label="States Option"
                  control={
                    <Checkbox
                      checked={statesOption}
                      onChange={(e) => setStatesOption(e.target.checked)}
                      color="success"
                      disabled={loading}
                    />
                  }
                />
                <FormControlLabel
                  label="Display Mobile"
                  control={
                    <Checkbox
                      checked={displayMobile}
                      onChange={(e) => setDisplayMobile(e.target.checked)}
                      color="success"
                      disabled={loading}
                    />
                  }
                />
                <FormControlLabel
                  label="Auto Print"
                  control={
                    <Checkbox
                      checked={autoPrint}
                      onChange={(e) => setAutoPrint(e.target.checked)}
                      color="success"
                      disabled={loading}
                    />
                  }
                />
                <FormControlLabel
                  label="Auto Back"
                  control={
                    <Checkbox
                      checked={autoBack}
                      onChange={(e) => setAutoBack(e.target.checked)}
                      color="success"
                      disabled={loading}
                    />
                  }
                />
                <FormControlLabel
                  label="Auto Checkout Vehicles (at 5 AM)"
                  control={
                    <Checkbox
                      checked={resetCheckins}
                      onChange={(e) => setResetCheckins(e.target.checked)}
                      color="success"
                      disabled={loading}
                    />
                  }
                />
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                sx={{ width: "100%" }}
                marginTop={4}
              >
                <Typography
                  id="keep-mounted-modal-title"
                  variant="h3"
                  component="h2"
                >
                  Pass
                </Typography>
              </Box>
              <Box
                sx={{ gridColumn: "span 4" }}
                marginTop={2}
                display="flex"
                flexDirection={isNonMobile ? "row" : "column"}
                justifyContent="space-between"
              >
                <FormControlLabel
                  label="Pass"
                  control={
                    <Checkbox
                      checked={pass}
                      onChange={(e) => setPass(e.target.checked)}
                      color="success"
                      disabled={loading}
                    />
                  }
                />
                <FormControlLabel
                  label="Custom Pass"
                  control={
                    <Checkbox
                      checked={customPass}
                      onChange={(e) => setCustomPass(e.target.checked)}
                      color="success"
                      disabled={loading || !pass}
                    />
                  }
                />
                <FormControlLabel
                  label="Daily Day Pass"
                  control={
                    <Checkbox
                      checked={dailyDayPass}
                      onChange={(e) => setDailyDayPass(e.target.checked)}
                      color="success"
                      disabled={loading || !pass}
                    />
                  }
                />
                <FormControlLabel
                  label="Daily Night Pass"
                  control={
                    <Checkbox
                      checked={dailyNightPass}
                      onChange={(e) => setDailyNightPass(e.target.checked)}
                      color="success"
                      disabled={loading || !pass}
                    />
                  }
                />
              </Box>
              <Box
                sx={{ gridColumn: "span 4" }}
                marginTop={2}
                display="flex"
                flexDirection={isNonMobile ? "row" : "column"}
                justifyContent="space-between"
              >
                <FormControlLabel
                  label="Weekly Day Pass"
                  control={
                    <Checkbox
                      checked={weeklyDayPass}
                      onChange={(e) => setWeeklyDayPass(e.target.checked)}
                      color="success"
                      disabled={loading || !pass}
                    />
                  }
                />
                <FormControlLabel
                  label="Weekly Night Pass"
                  control={
                    <Checkbox
                      checked={weeklyNightPass}
                      onChange={(e) => setWeeklyNightPass(e.target.checked)}
                      color="success"
                      disabled={loading || !pass}
                    />
                  }
                />
                <FormControlLabel
                  label="Monthly Day Pass"
                  control={
                    <Checkbox
                      checked={monthlyDayPass}
                      onChange={(e) => setMonthlyDayPass(e.target.checked)}
                      color="success"
                      disabled={loading || !pass}
                    />
                  }
                />
                <FormControlLabel
                  label="Monthly Night Pass"
                  control={
                    <Checkbox
                      checked={monthlyNightPass}
                      onChange={(e) => setMonthlyNightPass(e.target.checked)}
                      color="success"
                      disabled={loading || !pass}
                    />
                  }
                />
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                sx={{ width: "100%" }}
                marginTop={4}
              >
                <Typography
                  id="keep-mounted-modal-title"
                  variant="h3"
                  component="h2"
                >
                  Night Charge
                </Typography>
              </Box>
              <Box
                display="flex"
                flexDirection={isNonMobile ? "row" : "column"}
                gridColumn="span 4"
                alignItems={"center"}
                gap={4}
                marginTop={isNonMobile ? 2 : 1}
              >
                <Box
                  display="flex"
                  flexDirection="row"
                  flex={1}
                  gap={1}
                  justifyContent="space-between"
                >
                  <FormControlLabel
                    label="Night Charge"
                    control={
                      <Checkbox
                        checked={nightCharge}
                        onChange={(e) => setNightCharge(e.target.checked)}
                        color="success"
                        disabled={loading}
                      />
                    }
                  />
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant="p" component="p">
                    Start Time
                  </Typography>
                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Start Time"
                    onChange={(e) => setNightChargeStartTime(e.target.value)}
                    value={nightChargeStartTime}
                    name="nightChargeStartTime"
                    disabled={loading || !nightCharge}
                  />
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant="p" component="p">
                    End Time
                  </Typography>
                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="End Time"
                    onChange={(e) => setNightChargeEndTime(e.target.value)}
                    value={nightChargeEndTime}
                    name="nightChargeEndTime"
                    disabled={loading || !nightCharge}
                  />
                </Box>
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                sx={{ width: "100%" }}
                marginTop={4}
              >
                <Typography
                  id="keep-mounted-modal-title"
                  variant="h3"
                  component="h2"
                >
                  Report Adjustment
                </Typography>
              </Box>
              <Box
                display="flex"
                flexDirection={isNonMobile ? "row" : "column"}
                gridColumn="span 4"
                alignItems={"center"}
                gap={4}
                marginTop={isNonMobile ? 2 : 1}
              >
                <Box
                  display="flex"
                  flexDirection="row"
                  flex={1}
                  gap={1}
                  justifyContent="space-between"
                >
                  <FormControlLabel
                    label="Adjustment"
                    control={
                      <Checkbox
                        checked={reportAdjustment}
                        onChange={(e) => setReportAdjustment(e.target.checked)}
                        color="success"
                        disabled={loading}
                      />
                    }
                  />
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  flex={1}
                  gap={1}
                  marginTop={-2}
                >
                  <Typography variant="p" component="p">
                    Percentage
                  </Typography>
                  <TextField
                    fullWidth
                    variant="filled"
                    type="number"
                    label="Percentage"
                    onChange={(e) => setPercentage(e.target.value)}
                    value={percentage}
                    name="percentage"
                    disabled={loading || !reportAdjustment}
                  />
                </Box>
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                sx={{ width: "100%" }}
                marginTop={4}
              >
                <Typography
                  id="keep-mounted-modal-title"
                  variant="h3"
                  component="h2"
                >
                  Other Settings
                </Typography>
              </Box>
              <Box
                sx={{ gridColumn: "span 4" }}
                marginTop={2}
                display="flex"
                flexDirection={isNonMobile ? "row" : "column"}
                justifyContent="space-between"
              >
                <FormControlLabel
                  label="Prepaid"
                  control={
                    <Checkbox
                      checked={prepaid}
                      onChange={(e) => setPrepaid(e.target.checked)}
                      color="success"
                      disabled={loading}
                    />
                  }
                />
                <FormControlLabel
                  label="Online Payments"
                  control={
                    <Checkbox
                      checked={onlinePayments}
                      onChange={(e) => setOnlinePayments(e.target.checked)}
                      color="success"
                      disabled={loading}
                    />
                  }
                />
                <FormControlLabel
                  label="Holiday Mode"
                  control={
                    <Checkbox
                      checked={holidayMode}
                      onChange={(e) => setHolidayMode(e.target.checked)}
                      color="success"
                      disabled={loading}
                    />
                  }
                />
                <FormControlLabel
                  label="OCR"
                  control={
                    <Checkbox
                      checked={ocr}
                      onChange={(e) => setOcr(e.target.checked)}
                      color="success"
                      disabled={loading}
                    />
                  }
                />
                <FormControlLabel
                  label="Advance"
                  control={
                    <Checkbox
                      checked={advance}
                      onChange={(e) => setAdvance(e.target.checked)}
                      color="success"
                      disabled={loading}
                    />
                  }
                />
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                sx={{ width: "100%" }}
                marginTop={4}
              >
                <Typography
                  id="keep-mounted-modal-title"
                  variant="h3"
                  component="h2"
                >
                  Vehicles
                </Typography>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                gridColumn="span 4"
                gap={1}
                marginTop={2}
              >
                <Typography variant="p" component="p">
                  Vehicles
                </Typography>
                <Select
                  variant="filled"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={vehicles}
                  label="Vehicles"
                  multiple
                  onChange={(e) => setVehicles(e.target.value)}
                  sx={{ gridColumn: "span 4" }}
                  disabled={loading}
                >
                  {VEHICLES.map((item) => (
                    <MenuItem
                      key={item}
                      value={item.toUpperCase()}
                      selected={vehicles.includes(item.toString())}
                    >
                      {item.toUpperCase()}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                sx={{ width: "100%" }}
                marginTop={4}
              >
                <Typography
                  id="keep-mounted-modal-title"
                  variant="h3"
                  component="h2"
                >
                  Rate
                </Typography>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                gridColumn="span 4"
                gap={1}
                marginTop={2}
              >
                <Typography variant="p" component="p">
                  Rate
                </Typography>
                <Select
                  variant="filled"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={rates}
                  label="Rates"
                  onChange={(e) => setRates(e.target.value)}
                  sx={{ gridColumn: "span 4" }}
                  disabled={loading}
                >
                  {allRates.map((item) => (
                    <MenuItem
                      key={item}
                      value={item._id}
                      selected={rates === item._id.toString()}
                    >
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            </>
          )}
        </Box>
      )}
    </Box>
  );
};

export default ParkingById;
