import {
  DELETE_CHECKINS_FAIL,
  DELETE_CHECKINS_REQUEST,
  DELETE_CHECKINS_RESET,
  DELETE_CHECKINS_SUCCESS,
  DELETE_CHECKIN_FAIL,
  DELETE_CHECKIN_REQUEST,
  DELETE_CHECKIN_RESET,
  DELETE_CHECKIN_SUCCESS,
  DOWNLOAD_CHECKINS_FAIL,
  DOWNLOAD_CHECKINS_REQUEST,
  DOWNLOAD_CHECKINS_RESET,
  DOWNLOAD_CHECKINS_SUCCESS,
  FOC_CHECKIN_FAIL,
  FOC_CHECKIN_REQUEST,
  FOC_CHECKIN_RESET,
  FOC_CHECKIN_SUCCESS,
  GET_CHECKINS_FAIL,
  GET_CHECKINS_REQUEST,
  GET_CHECKINS_RESET,
  GET_CHECKINS_SUCCESS,
} from '../constants/checkinConstants'

export const getCheckinsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_CHECKINS_REQUEST:
      return { loadingGetCheckins: true }
    case GET_CHECKINS_SUCCESS:
      return { loadingGetCheckins: false, getCheckinsData: action.payload }
    case GET_CHECKINS_FAIL:
      return { loadingGetCheckins: false, errorGetCheckins: action.payload }
    case GET_CHECKINS_RESET:
      return {}
    default:
      return state
  }
}

export const focCheckinReducer = (state = {}, action) => {
  switch (action.type) {
    case FOC_CHECKIN_REQUEST:
      return { loadingFocCheckin: true }
    case FOC_CHECKIN_SUCCESS:
      return { loadingFocCheckin: false, focCheckinData: action.payload }
    case FOC_CHECKIN_FAIL:
      return { loadingFocCheckin: false, errorFocCheckin: action.payload }
    case FOC_CHECKIN_RESET:
      return {}
    default:
      return state
  }
}

export const deleteCheckinReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_CHECKIN_REQUEST:
      return { loadingDeleteCheckin: true }
    case DELETE_CHECKIN_SUCCESS:
      return { loadingDeleteCheckin: false, deleteCheckinData: action.payload }
    case DELETE_CHECKIN_FAIL:
      return { loadingDeleteCheckin: false, errorDeleteCheckin: action.payload }
    case DELETE_CHECKIN_RESET:
      return {}
    default:
      return state
  }
}

export const downloadCheckinsReducer = (state = {}, action) => {
  switch (action.type) {
    case DOWNLOAD_CHECKINS_REQUEST:
      return { loadingDownloadCheckins: true }
    case DOWNLOAD_CHECKINS_SUCCESS:
      return {
        loadingDownloadCheckins: false,
        downloadCheckinsData: action.payload,
      }
    case DOWNLOAD_CHECKINS_FAIL:
      return {
        loadingDownloadCheckins: false,
        errorDownloadCheckins: action.payload,
      }
    case DOWNLOAD_CHECKINS_RESET:
      return {}
    default:
      return state
  }
}

export const deleteCheckinsReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_CHECKINS_REQUEST:
      return { loadingDeleteCheckins: true }
    case DELETE_CHECKINS_SUCCESS:
      return {
        loadingDeleteCheckins: false,
        deleteCheckinsData: action.payload,
      }
    case DELETE_CHECKINS_FAIL:
      return {
        loadingDeleteCheckins: false,
        errorDeleteCheckins: action.payload,
      }
    case DELETE_CHECKINS_RESET:
      return {}
    default:
      return state
  }
}
